import React from 'react';
import { useParams } from 'react-router-dom';
import TaxiSelectAirportPage from '../../Components/DetailPage/DetailTable/TaxiSelectAirport';

const SelectAirportInTaxiPage = () => {
  const { id } = useParams();

  return <TaxiSelectAirportPage id={id} />;
};

export default SelectAirportInTaxiPage;
