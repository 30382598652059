import React, { useEffect } from 'react';
import AirportTabs from '../Components/DetailPage/Tab/AirportTabs';
import { useNavi } from '../services/hooks/useNavi';

const AirportsPage = () => {
  const { clearNavigate } = useNavi()
  useEffect(() => {
    clearNavigate()
  }, []);

  return (
    <div className={'main-page'}>
          <AirportTabs />
    </div>
  );
};

export default AirportsPage;
