import React from 'react';
import {
  EVENT_AIRPORT,
  EVENT_AIRPORT_MAP,
  EVENT_CITY,
  EVENT_COUNTRY,
  EVENT_CURRENCY,
  EVENT_TAXIS
} from '../../../../constants/const';
import ModalDetail from '../../ModalDetail';
import TaxisDetail from '../../../DetailPage/Detail/TaxisDetail';
import AirportDetail from '../../../DetailPage/Detail/AirportDetail';
import AirportMapDetail from '../../../DetailPage/Detail/AirportMapDetail';
import CitiesDetail from '../../../DetailPage/Detail/CitiesDetail';
import CountryDetail from '../../../DetailPage/Detail/CountryDetail';
import CurrencyDetail from '../../../DetailPage/Detail/CurrencyDetail';

const TableSelectModalEvents = (props) => {
  const getDetail = () => {
    switch (props.event) {
      case EVENT_AIRPORT:
        return <AirportDetail id={props.id} isModal />;
      case EVENT_AIRPORT_MAP:
        return <AirportMapDetail id={props.id} isModal />;
      case EVENT_CITY:
        return <CitiesDetail id={props.id} isModal />;
      case EVENT_COUNTRY:
        return <CountryDetail id={props.id} isModal />;
      case EVENT_CURRENCY:
        return <CurrencyDetail id={props.id} isModal />;
      case EVENT_TAXIS:
        return <TaxisDetail id={props.id} isModal />;
      default:
        break;
    }
  };
  return (
    <ModalDetail
      name={props.name}
      header={props.header}
      className={props.className}
      buttonVariant={props.buttonVariant}>
      {getDetail()}
    </ModalDetail>
  );
};

export default TableSelectModalEvents;
