import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { EVENT_COUNTRY } from '../../../../constants/const';
import { Api } from '../../../../services/Api';

const CountryTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={'Country'}
        event={EVENT_COUNTRY}
        request={Api.getCountryPage}
        isDetail
      ></PaginationPage>
    </div>
  );
};

export default CountryTabs;
