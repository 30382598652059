import React, { useEffect } from 'react';
import { useNavi } from '../services/hooks/useNavi';
import CountryTabs from '../Components/DetailPage/Tab/CountryTabs';

const CountryPage = () => {
  const { clearNavigate } = useNavi()
  useEffect(() => {
    clearNavigate()
  }, []);

  return (
    <div className={'main-page'}>
          <CountryTabs />
    </div>
  );
};

export default CountryPage;
