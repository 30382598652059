import React from 'react';
import { Button, Card } from 'react-bootstrap';
import ModalChose from '../ModalChoice';
import { COMPONENT_VARIANT } from '../../../constants/const';

const CardChoice = (props) => {
  return props.isDisable ? (
    <div>Create a public transport before adding an airport</div>
  ) : (
    <Card style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
      <Card.Body>
        <Card.Title>Not added</Card.Title>
        <Card.Text>-</Card.Text>
        {props.isNavigate ? (
          <Button
            variant={COMPONENT_VARIANT.primary}
            onClick={() => {
              props.onNavigate();
            }}>
            {props.name}
          </Button>
        ) : (
          <ModalChose
            name={props.name}
            event={props.event}
            request={props.request}
            setValue={props.setValue}
            getFieldInfo={props.getFieldInfo}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default CardChoice;
