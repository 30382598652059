import React from 'react';
import { useParams } from 'react-router-dom';
import MarketingDetail from '../../Components/DetailPage/Detail/MarketingDetail';

const DetailMarketingPage = () => {
  const { id } = useParams();

  return <MarketingDetail detailId={id} />;
};

export default DetailMarketingPage;
