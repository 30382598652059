import React, { useEffect, useState } from 'react';
import {
  COMPONENT_VARIANT,
  DESCRIPTION_PAGE,
  EVENT_MARKETING,
  EVENT_PUSH,
  EVENT_REMINDERS,
  TABS_PUSH_EVENT
} from '../../constants/const';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Create from '../../assets/image/Create.svg';

import './style.scss';
import Button from 'react-bootstrap/Button';
import Loader from '../Loader';
import NavBar from '../NavBar';
import { Q_PARAM_SEARCH } from '../../constants/searchElement';
import { Api } from '../../services/Api';
import BlockDetail from '../DetailComponent/BlockDetail';
import classNames from 'classnames';
import { useNavi } from '../../services/hooks/useNavi';

import { useDispatch, useSelector } from 'react-redux';
import { clearState } from '../../services/storage';

const PushEvents = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [pageSearch, setPageSearch] = useState(queryParams.get(Q_PARAM_SEARCH.search));
  const [tabActiveElement, setTabActiveElement] = useState(TABS_PUSH_EVENT.FlightStatus);
  const { pushRoute, pushPatch } = useNavi();

  const [pushEvent, setPushEvent] = useState(null);
  const [reminders, setReminders] = useState(null);
  const [marketing, setMarketing] = useState(null);

  const storageState = useSelector((state) => state);
  const language = useSelector((state) => state.app.language);
  const dispatch = useDispatch();

  const clearSearchParams = () => {
    setPageSearch('');
    getData(null);
  };

  const getPushEvent = () => {
    Api.getPushEvent().then((res) => {
      setPushEvent(res);
    });
  };
  const getReminder = () => {
    Api.getReminder().then((res) => {
      const massReminder = [];
      res.map((value) => {
        const findReminder = massReminder.find((val) => val.event === value.duration);
        if (!findReminder) {
          const findLanguage = res.filter((val) => val.duration === value.duration);
          const findLanguageRu = res.find(
            (val) => val.duration === value.duration && val.language === 'ru'
          );
          const structReminder = {
            id: value.id,
            event: value.duration,
            nameRU: findLanguageRu.title,
            count: findLanguage.length + '/' + language.length
          };
          massReminder.push(structReminder);
        }
      });
      setReminders(massReminder);
    });
  };

  const getMarketing = () => {
    Api.getMarketingPush().then((res) => {
      const massMarketing = [];
      res.map((value) => {
        const findMarketing = massMarketing.find((val) => val.event === value.event);
        if (!findMarketing) {
          const findLanguage = res.filter((val) => val.event === value.event);
          const findLanguageRu = res.find(
            (val) => val.event === value.event && val.language === 'ru'
          );
          const structMarketing = {
            id: value.id,
            event: value.event,
            nameRU: findLanguageRu.title,
            count: findLanguage.length + '/' + language.length
          };
          massMarketing.push(structMarketing);
        }
      });
      setMarketing(massMarketing);
    });
  };

  const getData = (search) => {
    if (tabActiveElement === TABS_PUSH_EVENT.FlightStatus) getPushEvent();
    else if (tabActiveElement === TABS_PUSH_EVENT.Reminders) getReminder();
    else if (tabActiveElement === TABS_PUSH_EVENT.Marketing) getMarketing();
    return search;
  };

  useEffect(() => {
    if (language) {
      if (!pushEvent) getPushEvent();
      if (!reminders) getReminder();
      if (!marketing) getMarketing();
    }
  }, [language]);

  const getPushEventTabs = (tabElement, routeEvent) => {
    return tabElement?.map((value, index) => {
      return (
        <div
          key={index}
          className={classNames({
            'table-items-context': true,
            'table-color-gray': index % 2 == 0
          })}>
          <div className="table-items">
            <Button
              className="table-items-button"
              variant={COMPONENT_VARIANT.link}
              onClick={() => {                
                pushPatch('/' + routeEvent, null, routeEvent)
                pushRoute('/' + routeEvent + '/' + value.id, value.id, value.event);
                dispatch(clearState(routeEvent, value.id, storageState));
              }}>
              {value.event}
            </Button>
          </div>
          <div className="table-items">{value.nameRU}</div>
          <div className="table-items">{value.count}</div>
        </div>
      );
    });
  };
  return (
    <Loader
      loading={!pushEvent || !reminders || !marketing || !language}
      variant={COMPONENT_VARIANT.dark}
      withMargin={100}>
      <div className="nav-bar-context">
        <NavBar
          name={DESCRIPTION_PAGE.pushEvents}
          event={EVENT_PUSH}
          setPageSearch={setPageSearch}
          searchPage={pageSearch}
          clearSearchParams={clearSearchParams}
          getData={getData}
          isDisabledCreate
        />
      </div>
      <div className="push-event-page-context">
        <BlockDetail>
          <div className="push-event-page">
            <Tabs
              defaultActiveKey={TABS_PUSH_EVENT.FlightStatus}
              transition={false}
              id=""
              className="mb-3">
              <Tab
                eventKey={TABS_PUSH_EVENT.FlightStatus}
                title="Flight status"
                onClick={() => {
                  setTabActiveElement(TABS_PUSH_EVENT.FlightStatus);
                }}>
                <div className="table-push-event">
                  <div className="table-push-thead">
                    <div className="table-push-thead-header">Event</div>
                    <div className="table-push-thead-header">NameRU</div>
                    <div className="table-push-thead-header">Languages</div>
                  </div>
                  <div className="table-push-tbody">{getPushEventTabs(pushEvent, EVENT_PUSH)}</div>
                </div>
              </Tab>
              <Tab
                eventKey={TABS_PUSH_EVENT.Reminders}
                title="Reminders"
                onClick={() => {
                  setTabActiveElement(TABS_PUSH_EVENT.Reminders);
                }}>
                <div>
                  <Button
                    className="add-button"
                    variant={'outline-' + COMPONENT_VARIANT.primary}
                    onClick={() => {
                      pushPatch('/' + EVENT_REMINDERS, null, EVENT_REMINDERS)
                      pushRoute('/' + EVENT_REMINDERS + '/new', 'new', 'new');
                    }}>
                    Create new reminder
                    <img src={Create} className="add-button-image" alt={'add'}></img>
                  </Button>
                  <div className="table-push-event">
                    <div className="table-push-thead">
                      <div className="table-push-thead-header">Title</div>
                      <div className="table-push-thead-header">Text</div>
                      <div className="table-push-thead-header">Language</div>
                    </div>
                    <div className="table-push-tbody">
                      {getPushEventTabs(reminders, EVENT_REMINDERS)}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey={TABS_PUSH_EVENT.Marketing}
                title="Marketing"
                onClick={() => {
                  setTabActiveElement(TABS_PUSH_EVENT.Marketing);
                }}>
                <div>
                  <Button
                    className="add-button"
                    variant={'outline-' + COMPONENT_VARIANT.primary}
                    onClick={() => {
                      pushPatch('/' + EVENT_MARKETING, null, EVENT_MARKETING)
                      pushRoute('/' + EVENT_MARKETING + '/new', 'new', 'new');
                    }}>
                    Create new marketing
                    <img src={Create} className="add-button-image" alt={'add'}></img>
                  </Button>
                  <div className="table-push-event">
                    <div className="table-push-thead">
                      <div className="table-push-thead-header">Event</div>
                      <div className="table-push-thead-header">NameRU</div>
                      <div className="table-push-thead-header">Languages</div>
                    </div>
                    <div className="table-push-tbody">
                      {getPushEventTabs(marketing, EVENT_MARKETING)}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </BlockDetail>
      </div>
    </Loader>
  );
};

export default PushEvents;
