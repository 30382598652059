import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { EVENT_PUBLIC_TRANSPORT } from '../../../../constants/const';
import { Api } from '../../../../services/Api';

const PublicTransportTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={'Public transport'}
        event={EVENT_PUBLIC_TRANSPORT}
        request={Api.getPublicTransportPage}
        isDetail></PaginationPage>
    </div>
  );
};

export default PublicTransportTabs;
