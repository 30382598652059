import { PROTOCOL } from '../constants/const';

export const separateUrl = (url) => {
  let protocol = PROTOCOL.HTTPS;

  if (url?.indexOf(PROTOCOL.HTTP) > -1) protocol = PROTOCOL.HTTP;

  let link = url?.replace(PROTOCOL.HTTPS, '').replace(PROTOCOL.HTTP, '');
  while (link?.indexOf(PROTOCOL.HTTP) > -1 || link?.indexOf(PROTOCOL.HTTPS) > -1) {
    link = link?.replace(PROTOCOL.HTTPS, '').replace(PROTOCOL.HTTP, '');
  }

  return {
    protocol: protocol,
    url: link
  };
};

export const replaceUrl = (url) => {
  const protocol = url?.protocol;
  if (!url.url || url.url === '') return '';

  let returnUrl = url.url.replace(PROTOCOL.HTTPS, '').replace(PROTOCOL.HTTP, '');
  while (returnUrl.indexOf(PROTOCOL.HTTP) > -1 || returnUrl.indexOf(PROTOCOL.HTTPS) > -1) {
    returnUrl = returnUrl.replace(PROTOCOL.HTTPS, '').replace(PROTOCOL.HTTP, '');
  }
  return protocol + returnUrl;
};
