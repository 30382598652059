import React from 'react';
import { useParams } from 'react-router-dom';
import AirlinesDetail from '../../Components/DetailPage/Detail/AirlinesDetail';

const DetailAirlinesPage = () => {
  const { id } = useParams();

  return <AirlinesDetail id={id} />;
};

export default DetailAirlinesPage;
