import { replaceUrl } from './separateUrl';

function removeEmpty(obj) {
  //remove all empty fields from object
  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}
export const MultipartWrapper = {
  Airport(data) {
    const formData = new FormData();
    formData.append('iataCode', data.iataCode);
    console.log(formData);
    formData.append('lat', data.lat ?? 20);
    formData.append('lon', data.lon ?? 20);
    if (data.phone) formData.append('phone', data.phone);
    if (data.timezone) formData.append('timezone', data.timezone);
    if (data.city) formData.append('city', data.city?.id);
    formData.append('mapUrl', replaceUrl(data.mapUrl));
    formData.append('website', replaceUrl(data.website));
    if (data.country) formData.append('country', data.country?.id);
    formData.append('train', replaceUrl(data.train));
    formData.append('metro', replaceUrl(data.metro));
    formData.append('bus', replaceUrl(data.bus));
    formData.append('shuttle', replaceUrl(data.shuttle));
    formData.append('parking', replaceUrl(data.parking));
    formData.append('tram', replaceUrl(data.tram));
    formData.append('other', replaceUrl(data.other));
    if (data.search_field) formData.append('search_field', data.search_field);
    if (data.popularity) formData.append('popularity', data.popularity);
    formData.append('instagram', replaceUrl(data.instagram));
    formData.append('twitter', replaceUrl(data.twitter));
    formData.append('facebook', replaceUrl(data.facebook));
    formData.append('otherNames', JSON.stringify(removeEmpty(data.otherNames)));
    formData.append('description', JSON.stringify(removeEmpty(data.description)));

    if (data.maps?.length === 0 && data.id) {
      formData.append('maps', '');
    }
    if (data.maps !== null && data.maps.length > 0) {
      data.maps.map((i) => {
        formData.append('maps', i.id);
      });
    }

    if (data.taxis?.length === 0 && data.id) {
      formData.append('taxis', '');
    }
    if (data.taxis !== null) {
      data.taxis.map((i) => {
        formData.append('taxis', i.id);
      });
    }
    if (data.descriptionImageFile) {
      formData.append('descriptionImageUrl', data.descriptionImageFile);
    }
    return formData;
  },

  AirportMap(data) {
    const formData = new FormData();
    formData.append('name', data.name);
    //formData.append('mapType', data.mapType);
    formData.append('category', data.category);
    formData.append('ordering', data.ordering);
    if (data.imageFile) formData.append('image', data.imageFile);
    return formData;
  },

  Airlines(data) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('iataCode', data.iataCode);
    formData.append('icaoCode', data.icaoCode);

    formData.append('facebook', replaceUrl(data.facebook));
    formData.append('instagram', replaceUrl(data.instagram));
    formData.append('linkedin', replaceUrl(data.linkedin));
    formData.append('twitter', replaceUrl(data.twitter));
    formData.append('website', replaceUrl(data.website));
    formData.append('checkIn', replaceUrl(data.checkIn));
    if (data.imageFile) formData.append('image', data.imageFile);
    return formData;
  },

  City(data) {
    const formData = new FormData();
    formData.append('cityCode', data.cityCode);
    formData.append('timezone', data.timezone);
    formData.append('search_field', data.search_field);
    formData.append('country', data.country?.id);
    formData.append('otherNames', JSON.stringify(removeEmpty(data.otherNames)));
    //if (data.headerPhotoFile) formData.append('headerPhoto', data.headerPhotoFile);
    //else
    formData.append('headerPhoto', data.headerPhoto);
    return formData;
  },

  Country(data) {
    const formData = new FormData();
    formData.append('countryCode', data.countryCode);
    if (data.currency) formData.append('currency', data.currency);
    formData.append('flag', data.flag);
    formData.append('search_field', data.search_field);
    formData.append('otherNames', JSON.stringify(removeEmpty(data.otherNames)));
    if (data.currencyInfo) formData.append('currencyInfo', data.currencyInfo);
    return formData;
  },

  Currency(data) {
    const formData = new FormData();
    formData.append('price', data.price);
    formData.append('currency', data.currency);
    formData.append('symbol', data.symbol);
    return formData;
  },

  Taxis(data) {
    const formData = new FormData();
    if (data.id) formData.append('id', data.id);
    if (data.order) formData.append('order', data.order);
    if (data.title) formData.append('title', data.title);
    if (data.appStoreUrl?.url)
      formData.append('appStoreUrl', data.appStoreUrl.protocol + data.appStoreUrl.url);
    if (data.googleStoreUrl?.url)
      formData.append('googleStoreUrl', data.googleStoreUrl.protocol + data.googleStoreUrl.url);
    if (data.webUrl?.url) formData.append('webUrl', data.webUrl.protocol + data.webUrl.url);
    if (data.squareImageFile) formData.append('squareImage', data.squareImageFile);
    formData.append('urlSchema', data.urlSchema);
    return formData;
  },

  Rental(data) {
    const formData = new FormData();
    formData.append('order', data.order ?? 0);
    formData.append('title', data.title);
    formData.append('urlSchema', data.urlSchema);
    if (data.squareImageFile) formData.append('squareImage', data.squareImageFile);
    if (data.appStoreUrl?.url)
      formData.append('appStoreUrl', data.appStoreUrl.protocol + data.appStoreUrl.url);
    if (data.googleStoreUrl?.url)
      formData.append('googleStoreUrl', data.googleStoreUrl.protocol + data.googleStoreUrl.url);
    if (data.webUrl?.url) formData.append('webUrl', data.webUrl.protocol + data.webUrl.url);
    return formData;
  },

  PublicTransport(data) {
    const formData = new FormData();
    if (data.airport) formData.append('airport', data.airport.id);
    if (data.buyTicketUrl.url)
      formData.append('buyTicketUrl', data.buyTicketUrl.protocol + data.buyTicketUrl.url);
    if (data.tags && typeof data.tags === 'object')
      formData.append('tags', JSON.stringify(data.tags));
    if (data.priceIsFixed === null) formData.append('priceIsFixed', false);
    else formData.append('priceIsFixed', data.priceIsFixed);
    if (data.color) formData.append('color', data.color);
    if (data.descriptions)
      formData.append('descriptions', JSON.stringify(removeEmpty(data.descriptions)));
    if (data.priceValue) formData.append('priceValue', data.priceValue);
    if (data.frequencyMaximum && data.frequencyMaximum !== '')
      formData.append('frequencyMaximum', data.frequencyMaximum);
    if (data.frequencyMaximum === '') formData.append('frequencyMaximum', '');
    if (data.frequencyMinimum) formData.append('frequencyMinimum', data.frequencyMinimum);
    if (data.isDeleteImage) formData.append('imagePath', "");
    else if (data.imagePathFile) formData.append('imagePath', data.imagePathFile);
    if (data.journeyTimeMaximum && data.journeyTimeMaximum !== '')
      formData.append('journeyTimeMaximum', data.journeyTimeMaximum);
    if (data.journeyTimeMaximum === '') formData.append('journeyTimeMaximum', '');
    if (data.journeyTimeMinimum) formData.append('journeyTimeMinimum', data.journeyTimeMinimum);
    if (data.names) formData.append('names', JSON.stringify(removeEmpty(data.names)));
    if (data.ordering) formData.append('ordering', data.ordering);
    if (data.subNames) formData.append('subNames', JSON.stringify(removeEmpty(data.subNames)));
    if (data.type) formData.append('type', data.type);
    return formData;
    /*
    const formData = {}
    if (data.airport) formData.airport = data.airport.id;
    if (data.buyTicketUrl.url)
      formData.buyTicketUrl = data.buyTicketUrl.protocol + data.buyTicketUrl.url;
    if (data.tags && typeof data.tags === 'object' && data.tags.length > 0)
      formData.tags = data.tags;
    if (data.priceIsFixed === null) formData.priceIsFixed = false;
    else formData.priceIsFixed = data.priceIsFixed
    if (data.color) formData.color = data.color
    if (data.descriptions) formData.descriptions = data.descriptions
    if (data.priceValue) formData.priceValue = data.priceValue
    if (data.frequencyMaximum) formData.frequencyMaximum = data.frequencyMaximum
    if (data.frequencyMinimum) formData.frequencyMinimum = data.frequencyMinimum
    if (data.imagePath) formData.imagePath = data.imagePath
    if (data.journeyTimeMaximum) formData.journeyTimeMaximum = data.journeyTimeMaximum
    if (data.journeyTimeMinimum) formData.journeyTimeMinimum = data.journeyTimeMinimum
    if (data.names) formData.names = data.names
    if (data.ordering) formData.ordering = data.ordering
    if (data.subNames) formData.subNames = data.subNames
    if (data.type) formData.type = data.type
    return formData;
    */
    //formData.names = JSON.stringify(removeEmpty(data.names));
    //formData.subNames = JSON.stringify(removeEmpty(data.subNames));
    //formData.descriptions = JSON.stringify(removeEmpty(data.descriptions));

    /*if (data.airport) formData.airport = data.airport.id;
    if (data.buyTicketUrl.url)
      formData.buyTicketUrl = data.buyTicketUrl.protocol + data.buyTicketUrl.url;
    else formData.buyTicketUrl = null;
    if (data.tags && typeof data.tags === 'object' && data.tags.length > 0)
      formData.tags = data.tags;
    else formData.tags = null;
    if (data.priceIsFixed === null) formData.priceIsFixed = false;
    return formData;*/

    /*const formData = new FormData();
    if (data.id) formData.append('id', data.id);
    if (data.buyTicketUrl?.url) formData.append('buyTicketUrl', data.buyTicketUrl.protocol + data.buyTicketUrl.url);
    if (data.airport) formData.append('airport', data.airport.id);
    if (data.color) formData.append('color', data.color);
    if (data.frequencyMaximum) formData.append('frequencyMaximum', data.frequencyMaximum);
    formData.append('frequencyMinimum', data.frequencyMinimum);
    if (data.imagePathFile) formData.append('imagePathFile', data.imagePathFile);
    if (data.journeyTimeMaximum) formData.append('journeyTimeMaximum', data.journeyTimeMaximum);
    formData.append('journeyTimeMinimum', data.journeyTimeMinimum);
    formData.append('ordering', data.ordering);
    if (data.priceValue) formData.append('priceValue', data.priceValue);
    if (data.type) formData.append('type', data.type);
    formData.append('names', JSON.stringify(removeEmpty(data.names)));
    formData.append('subNames', JSON.stringify(removeEmpty(data.subNames)));
    formData.append('descriptions', JSON.stringify(removeEmpty(data.descriptions)));
    return formData;*/
  },

  PushEvent(data) {
    const formData = new FormData();
    formData.append('event', data.event);
    formData.append('nameRU', data.nameRU);
    const localization = [];
    data.localization.map((value) => {
      localization.push({ id: value.id, text: value.text });
    });
    formData.append('localizations', JSON.stringify(localization));
    return formData;
  },

  Marketing(data) {
    const formData = new FormData();
    formData.append('event', data.event);
    formData.append('language', data.language);
    formData.append('text', data.text);
    formData.append('title', data.title);
    formData.append('type', data.type);
    formData.append('url', data.url);
    return formData;
  },

  Reminders(data) {
    const formData = new FormData();
    formData.append('duration', data.duration);
    formData.append('language', data.language);
    formData.append('text', data.text);
    formData.append('title', data.title);
    return formData;
  }
};
