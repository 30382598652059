const Upload = () => {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.39966 9.39918C1.53227 9.39918 1.65944 9.45186 1.75321 9.54563C1.84698 9.6394 1.89966 9.76658 1.89966 9.89918V12.3992C1.89966 12.6644 2.00502 12.9188 2.19255 13.1063C2.38009 13.2938 2.63444 13.3992 2.89966 13.3992H14.8997C15.1649 13.3992 15.4192 13.2938 15.6068 13.1063C15.7943 12.9188 15.8997 12.6644 15.8997 12.3992V9.89918C15.8997 9.76658 15.9523 9.6394 16.0461 9.54563C16.1399 9.45186 16.267 9.39918 16.3997 9.39918C16.5323 9.39918 16.6594 9.45186 16.7532 9.54563C16.847 9.6394 16.8997 9.76658 16.8997 9.89918V12.3992C16.8997 12.9296 16.6889 13.4383 16.3139 13.8134C15.9388 14.1885 15.4301 14.3992 14.8997 14.3992H2.89966C2.36923 14.3992 1.86052 14.1885 1.48544 13.8134C1.11037 13.4383 0.899658 12.9296 0.899658 12.3992V9.89918C0.899658 9.76658 0.952337 9.6394 1.0461 9.54563C1.13987 9.45186 1.26705 9.39918 1.39966 9.39918Z"
        fill="white"
      />
      <path
        d="M8.54566 0.645185C8.5921 0.598622 8.64728 0.561679 8.70802 0.536472C8.76877 0.511266 8.83389 0.498291 8.89966 0.498291C8.96543 0.498291 9.03055 0.511266 9.09129 0.536472C9.15204 0.561679 9.20721 0.598622 9.25366 0.645185L12.2537 3.64518C12.3475 3.73907 12.4003 3.86641 12.4003 3.99918C12.4003 4.13196 12.3475 4.2593 12.2537 4.35318C12.1598 4.44707 12.0324 4.49982 11.8997 4.49982C11.7669 4.49982 11.6395 4.44707 11.5457 4.35318L9.39966 2.20618V10.9992C9.39966 11.1318 9.34698 11.259 9.25321 11.3527C9.15944 11.4465 9.03227 11.4992 8.89966 11.4992C8.76705 11.4992 8.63987 11.4465 8.5461 11.3527C8.45234 11.259 8.39966 11.1318 8.39966 10.9992V2.20618L6.25366 4.35318C6.20717 4.39967 6.15198 4.43655 6.09124 4.46171C6.0305 4.48687 5.9654 4.49982 5.89966 4.49982C5.83391 4.49982 5.76881 4.48687 5.70807 4.46171C5.64734 4.43655 5.59215 4.39967 5.54566 4.35318C5.49917 4.3067 5.46229 4.25151 5.43713 4.19077C5.41198 4.13003 5.39903 4.06493 5.39903 3.99918C5.39903 3.93344 5.41198 3.86834 5.43713 3.8076C5.46229 3.74686 5.49917 3.69167 5.54566 3.64518L8.54566 0.645185Z"
        fill="white"
      />
    </svg>
  );
};

export default Upload;
