import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './style.scss';
import { COMPONENT_VARIANT } from '../../constants/const';

const ModalConfirm = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="modal-confirm">
      <Button
        className={props.className}
        variant={props.isOutline ? 'outline-' + props.variant : props.variant}
        onClick={handleShow}
        disabled={props.isDisabled}>
        {props.name}
      </Button>
      <Modal show={show} onHide={handleClose} className="modal-window-confirm">
        <Modal.Header className="modal-head" closeButton>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="confirm-text">{props.children}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="footer-button"
            variant={COMPONENT_VARIANT.primary}
            onClick={() => {
              handleClose();
              props.onClick();
            }}>
            Confirm
          </Button>
          <Button
            className="footer-button"
            variant={COMPONENT_VARIANT.secondary}
            onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
