const Weather = () => {
  return (
    <svg
      width="14px"
      height="14px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      role="img">
      <path
        d="M55.13,261.431c4.61-4.717,9.607-9.049,14.931-12.963C54.27,232.775,44.49,211.051,44.49,187.03
        	c0-47.838,38.788-86.626,86.634-86.626c32.808,0,61.349,18.246,76.05,45.147c11.372-7.762,23.874-13.947,37.213-18.296l0.64-1.788
          c0.476-1.32,0.344-2.789-0.353-4.003c-0.706-1.215-1.911-2.068-3.29-2.314l-32.66-5.866c-1.961-0.353-3.503-1.887-3.856-3.855
          l-5.866-32.66c-0.246-1.378-1.091-2.592-2.314-3.29c-1.214-0.706-2.675-0.845-4.004-0.361l-31.224,11.214
          c-1.87,0.682-3.97,0.124-5.258-1.402l-21.437-25.326c-0.902-1.074-2.248-1.69-3.643-1.69c-1.411,0-2.74,0.616-3.651,1.69
		L106.036,82.93c-1.288,1.526-3.388,2.084-5.268,1.419L69.553,73.127c-1.321-0.476-2.789-0.346-4.012,0.353
		c-1.215,0.705-2.051,1.911-2.306,3.29l-5.865,32.66c-0.362,1.968-1.896,3.502-3.856,3.855l-32.66,5.858
		c-1.386,0.254-2.584,1.1-3.29,2.314c-0.706,1.222-0.836,2.682-0.36,4.011l11.223,31.224c0.673,1.879,0.115,3.971-1.411,5.267
		L1.69,183.387C0.615,184.29,0,185.635,0,187.03c0,1.41,0.615,2.739,1.69,3.65l25.325,21.437c1.526,1.288,2.084,3.388,1.411,5.266
		l-11.223,31.217c-0.476,1.329-0.345,2.789,0.36,4.012c0.706,1.214,1.904,2.05,3.29,2.305l32.66,5.858
		C54.105,260.882,54.638,261.128,55.13,261.431z"
        fill="#0D6EFD"
      />
      <path
        d="M483.623,290.695c-14.767-14.784-34.416-24.743-56.246-27.508c-1.904-34.17-16.4-65.074-39.1-87.749
		c-24.35-24.374-58.126-39.486-95.306-39.477c-34.826-0.008-66.682,13.257-90.571,34.965
		c-20.551,18.647-35.277,43.636-41.233,71.841c-5.776-0.959-11.691-1.501-17.729-1.501c-29.624-0.008-56.549,12.035-75.952,31.462
		c-19.418,19.394-31.462,46.328-31.462,75.944c0,29.624,12.044,56.558,31.462,75.952c19.403,19.418,46.328,31.462,75.952,31.462
		H415.12c26.72,0,51.012-10.862,68.503-28.377c17.515-17.491,28.386-41.791,28.377-68.503
		C512.008,332.494,501.138,308.185,483.623,290.695z M465.754,409.832c-13.003,12.979-30.822,20.969-50.634,20.978H143.438
		c-22.717-0.008-43.177-9.172-58.076-24.062c-14.89-14.899-24.054-35.352-24.062-58.076c0.008-22.717,9.172-43.178,24.062-58.076
		c14.899-14.882,35.359-24.054,58.076-24.063c8.483,0,16.621,1.28,24.292,3.66l14.734,4.553l1.575-15.342
		c2.823-27.566,15.884-52.087,35.343-69.766c19.484-17.663,45.22-28.402,73.589-28.402c30.272,0,57.575,12.232,77.437,32.078
		c19.846,19.854,32.07,47.156,32.078,77.437c0,1.066-0.041,2.314-0.099,3.774l-0.484,13.594l13.422-0.518
		c19.73,0.066,37.475,8.04,50.43,20.97c12.978,13.004,20.961,30.822,20.978,50.635C486.715,379.019,478.733,396.838,465.754,409.832
		z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default Weather;
