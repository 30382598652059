import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import { SORT_FIELD } from '../../../../constants/searchElement';
import Arrow from '../../../svg/Arrow';
import './style.scss';
import { COMPONENT_VARIANT } from '../../../../constants/const';

const SortNawBar = (props) => {
  const sortField = SORT_FIELD[props.event];
  const [activeField, setActiveField] = useState(props.activeValue);

  const onClickButton = (field) => {
    setActiveField(field);
    props.setValue(field);
  };
  const getNameSort = (field) => {
    if (!field) return 'not select';
    if (field.at(0) === '-')
      return (
        <div className="sort-description reverse">
          {field.slice(1, field.length)} <Arrow />
        </div>
      );
    else
      return (
        <div className="sort-description">
          {field} <Arrow />
        </div>
      );
  };

  return (
    sortField && (
      <div className="sort-content">
        <Dropdown>
          <Dropdown.Toggle
            variant={COMPONENT_VARIANT.link}
            id={'dropdown-sort-' + props.event}
            className={classNames({
              'sort-dropdown': true,
              'active-sort': !!activeField
            })}>
            Sort: {getNameSort(activeField)}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {sortField.map((field, index) => {
              return (
                <div key={index} className="dropdown-menu-context">
                  <Dropdown.Item
                    className="dropdown-menu-button"
                    disabled={field === activeField}
                    onClick={() => {
                      onClickButton(field);
                    }}>
                    <div
                      className={classNames({
                        'sort-description': true,
                        'sort-disabled': field === activeField
                      })}>
                      {field} <Arrow />
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-menu-button"
                    disabled={'-' + field === activeField}
                    onClick={() => {
                      onClickButton('-' + field);
                    }}>
                    <div
                      className={classNames({
                        'sort-description': true,
                        reverse: true,
                        'sort-disabled': '-' + field === activeField
                      })}>
                      {field} <Arrow />
                    </div>
                  </Dropdown.Item>
                </div>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          variant={COMPONENT_VARIANT.link}
          className={classNames({ 'filter-clear-button': true, 'active-clear': !!activeField })}
          disabled={!activeField}
          onClick={() => {
            onClickButton(null);
          }}>
          X
        </Button>
      </div>
    )
  );
};

export default SortNawBar;
