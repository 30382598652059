import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './style.scss';

const PaginationTable = (props) => {
  const activePage = props.activePage;
  const setActivePage = props.setActivePage;
  const [maxPage] = useState(10);

  const getPaginationItem = (number) => {
    const isActive = number === activePage;
    return (
      <Pagination.Item
        key={number}
        active={isActive}
        className="pagination-item"
        onClick={() => {
          onClickPagination(number);
        }}
      >
        {number}
      </Pagination.Item>
    );
  };

  const onClickPagination = (page) => {
    setActivePage(page);
    props.request(page);
  };

  const getPage = () => {
    const items = [];
    const max_length = Math.ceil(props.allItemCount / props.itemPage);

    if (max_length <= maxPage) {
      for (let number = 1; number <= max_length; number++) items.push(getPaginationItem(number));
    } else {
      items.push(
        <Pagination.First
          className="pagination-item-navigate"
          disabled={activePage === 1}
          onClick={() => onClickPagination(1)}
        />
      );
      items.push(
        <Pagination.Prev
          className="pagination-item-navigate"
          disabled={activePage === 1}
          onClick={() => onClickPagination(activePage - 1)}
        />
      );
      const deltaMaxPage = Math.round(maxPage / 2);
      if (activePage <= deltaMaxPage)
        for (let number = 1; number <= maxPage; number++) {
          items.push(getPaginationItem(number));
        }
      else if (activePage >= max_length - deltaMaxPage) {
        for (let number = max_length - maxPage; number <= max_length; number++) {
          items.push(getPaginationItem(number));
        }
      } else {
        for (
          let number = activePage - deltaMaxPage;
          number <= activePage + deltaMaxPage - 1;
          number++
        ) {
          items.push(getPaginationItem(number));
        }
      }
      items.push(
        <Pagination.Next
          className="pagination-item-navigate"
          disabled={activePage === max_length}
          onClick={() => onClickPagination(activePage + 1)}
        />
      );
      items.push(
        <Pagination.Last
          className="pagination-item-navigate"
          disabled={props.activePage === max_length}
          onClick={() => onClickPagination(max_length)}
        />
      );
    }
    return items;
  };

  return (
    <div>
      <Pagination>
        {getPage().map((item, index) => {
          return (
            <div key={index} className="pagination-item">
              {item}
            </div>
          );
        })}
      </Pagination>
    </div>
  );
};

export default PaginationTable;
