const Checked = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_88_5792)">
        <path
          d="M5.33764 2.08927C4.38523 2.35175 3.43929 2.63717 2.50064 2.94527C2.41493 2.97303 2.33874 3.02431 2.28075 3.09326C2.22276 3.16221 2.1853 3.24607 2.17264 3.33527C1.61864 7.49227 2.89864 10.5253 4.42564 12.5233C5.07215 13.3777 5.84305 14.1303 6.71264 14.7563C7.05864 15.0003 7.36464 15.1763 7.60564 15.2893C7.72564 15.3463 7.82364 15.3843 7.89864 15.4073C7.93144 15.4188 7.96526 15.4271 7.99964 15.4323C8.03361 15.4267 8.06706 15.4184 8.09964 15.4073C8.17564 15.3843 8.27364 15.3463 8.39364 15.2893C8.63364 15.1763 8.94064 14.9993 9.28664 14.7563C10.1562 14.1303 10.9271 13.3776 11.5736 12.5233C13.1006 10.5263 14.3806 7.49227 13.8266 3.33527C13.8141 3.24602 13.7767 3.16212 13.7187 3.09315C13.6607 3.02418 13.5844 2.97293 13.4986 2.94527C12.8476 2.73227 11.7486 2.38527 10.6616 2.09027C9.55164 1.78927 8.53064 1.56627 7.99964 1.56627C7.46964 1.56627 6.44764 1.78827 5.33764 2.08927ZM5.07164 1.05927C6.15664 0.764268 7.30964 0.499268 7.99964 0.499268C8.68964 0.499268 9.84264 0.764268 10.9276 1.05927C12.0376 1.35927 13.1566 1.71427 13.8146 1.92927C14.0897 2.02012 14.3336 2.18673 14.5183 2.40997C14.703 2.6332 14.821 2.90401 14.8586 3.19127C15.4546 7.66827 14.0716 10.9863 12.3936 13.1813C11.6821 14.1202 10.8336 14.9471 9.87664 15.6343C9.54573 15.872 9.19512 16.0811 8.82864 16.2593C8.54864 16.3913 8.24764 16.4993 7.99964 16.4993C7.75164 16.4993 7.45164 16.3913 7.17064 16.2593C6.80415 16.0811 6.45355 15.8721 6.12264 15.6343C5.16567 14.9471 4.31722 14.1202 3.60564 13.1813C1.92764 10.9863 0.544639 7.66827 1.14064 3.19127C1.17833 2.90401 1.29628 2.6332 1.48096 2.40997C1.66563 2.18673 1.90953 2.02012 2.18464 1.92927C3.13983 1.61608 4.10244 1.32599 5.07164 1.05927Z"
          fill="#146C43"
        />
        <path
          d="M10.8536 5.64527C10.9002 5.69171 10.9371 5.74689 10.9624 5.80763C10.9876 5.86838 11.0005 5.9335 11.0005 5.99927C11.0005 6.06503 10.9876 6.13016 10.9624 6.1909C10.9371 6.25165 10.9002 6.30682 10.8536 6.35327L7.85364 9.35327C7.80719 9.39983 7.75202 9.43677 7.69127 9.46198C7.63053 9.48719 7.56541 9.50016 7.49964 9.50016C7.43387 9.50016 7.36875 9.48719 7.30801 9.46198C7.24726 9.43677 7.19208 9.39983 7.14564 9.35327L5.64564 7.85327C5.59915 7.80678 5.56227 7.75159 5.53712 7.69085C5.51196 7.63011 5.49901 7.56501 5.49901 7.49927C5.49901 7.43352 5.51196 7.36842 5.53712 7.30768C5.56227 7.24694 5.59915 7.19176 5.64564 7.14527C5.69213 7.09878 5.74732 7.0619 5.80806 7.03674C5.86879 7.01159 5.9339 6.99864 5.99964 6.99864C6.06538 6.99864 6.13048 7.01159 6.19122 7.03674C6.25196 7.0619 6.30715 7.09878 6.35364 7.14527L7.49964 8.29227L10.1456 5.64527C10.1921 5.5987 10.2473 5.56176 10.308 5.53655C10.3688 5.51135 10.4339 5.49837 10.4996 5.49837C10.5654 5.49837 10.6305 5.51135 10.6913 5.53655C10.752 5.56176 10.8072 5.5987 10.8536 5.64527Z"
          fill="#146C43"
        />
      </g>
      <defs>
        <clipPath id="clip0_88_5792">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Checked;
