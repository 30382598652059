const Localization = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16626 6.9991C1.16626 3.7791 3.77376 1.16577 6.99376 1.16577C10.2196 1.16577 12.8329 3.7791 12.8329 6.9991C12.8329 10.2191 10.2196 12.8324 6.99376 12.8324C3.77376 12.8324 1.16626 10.2191 1.16626 6.9991ZM9.31543 4.66577H11.0363C10.4763 3.70327 9.58376 2.9566 8.51043 2.5891C8.86043 3.2366 9.12876 3.9366 9.31543 4.66577ZM6.99959 2.35577C7.48376 3.05577 7.86293 3.8316 8.11376 4.66577H5.88543C6.13626 3.8316 6.51543 3.05577 6.99959 2.35577ZM2.33293 6.9991C2.33293 7.40161 2.39126 7.79244 2.48459 8.16577H4.45626C4.40959 7.78077 4.37459 7.39577 4.37459 6.9991C4.37459 6.60244 4.40959 6.21744 4.45626 5.83244H2.48459C2.39126 6.20577 2.33293 6.5966 2.33293 6.9991ZM2.96293 9.33244H4.68376C4.87043 10.0616 5.13876 10.7616 5.48876 11.4091C4.41543 11.0416 3.52293 10.3008 2.96293 9.33244ZM2.96293 4.66577H4.68376C4.87043 3.9366 5.13876 3.2366 5.48876 2.5891C4.41543 2.9566 3.52293 3.69744 2.96293 4.66577ZM6.99959 11.6424C6.51543 10.9424 6.13626 10.1666 5.88543 9.33244H8.11376C7.86293 10.1666 7.48376 10.9424 6.99959 11.6424ZM5.54126 6.9991C5.54126 7.39577 5.58209 7.78077 5.63459 8.16577H8.36459C8.41709 7.78077 8.45793 7.39577 8.45793 6.9991C8.45793 6.60244 8.41709 6.2116 8.36459 5.83244H5.63459C5.58209 6.2116 5.54126 6.60244 5.54126 6.9991ZM8.51043 11.4091C8.86043 10.7616 9.12876 10.0616 9.31543 9.33244H11.0363C10.4763 10.2949 9.58376 11.0416 8.51043 11.4091ZM9.62459 6.9991C9.62459 7.39577 9.58959 7.78077 9.54293 8.16577H11.5146C11.6079 7.79244 11.6663 7.40161 11.6663 6.9991C11.6663 6.5966 11.6079 6.20577 11.5146 5.83244H9.54293C9.58959 6.21744 9.62459 6.60244 9.62459 6.9991Z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default Localization;
