import React from 'react';
import './style.scss';
import classNames from 'classnames';
import GoogleMaps from '../../../../GoogleMaps';

const LocationDetail = (props) => {
  return (
    <div
      className={classNames({
        'airport-id-detail': true,
        'incorrect-border': props.validate
      })}>
      <div className="lat-lon-context">
        <div className="airport-id-context">
          <div className="name">Lat</div>
          <div className="description">{props.lat}</div>
        </div>
        <div className="airport-id-context">
          <div className="name">Lon</div>
          <div className="description">{props.lon}</div>
        </div>
        <GoogleMaps
          name={props.name}
          lat={props.lat}
          lon={props.lon}
          setLat={props.setLat}
          setLon={props.setLon}
        />
      </div>
      <div className="id-context">
        <div className="airport-id-context right">
          <div className="name">id</div>
          <div className="description">{props.id}</div>
        </div>
        <div className="airport-id-context right">
          <div className="name">Timezone</div>
          <div className="description timezone">{props.timezone}</div>
        </div>
      </div>
    </div>
  );
};

export default LocationDetail;
