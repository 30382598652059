import React from 'react';
import './style.scss';
import classNames from 'classnames';

const InputCard = (props) => {
  return (
    <div>
      {props.isMultiline ? (
        <textarea
          className={'input textarea'}
          onChange={props.onChange}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.isDisabled}></textarea>
      ) : (
        <input
          className={classNames({
            'input text': true,
            website: props.isWebsite || props.isSubtitle,
            'incorrect-border': props.validate
          })}
          type={props.isNumber ? 'number' : 'text'}
          disabled={props.isDisabled}
          value={props.value ?? ''}
          onChange={props.onChange}
          onBlur={props.onBlur}
          placeholder={props.placeholder}></input>
      )}
    </div>
  );
};

export default InputCard;
