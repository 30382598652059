const Push = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00244 18.001C8.59918 18.001 9.17147 17.7639 9.59343 17.342C10.0154 16.92 10.2524 16.3477 10.2524 15.751H5.75244C5.75244 16.3477 5.98949 16.92 6.41145 17.342C6.83341 17.7639 7.4057 18.001 8.00244 18.001ZM8.00244 2.15873L7.10582 2.33986C6.08877 2.54708 5.17456 3.09935 4.51792 3.90318C3.86129 4.70702 3.50255 5.71304 3.50244 6.75098C3.50244 7.45748 3.35169 9.22261 2.98607 10.9607C2.80607 11.8236 2.56307 12.7225 2.24019 13.501H13.7647C13.4418 12.7225 13.1999 11.8247 13.0188 10.9607C12.6532 9.22261 12.5024 7.45748 12.5024 6.75098C12.5021 5.71324 12.1432 4.70748 11.4866 3.90387C10.83 3.10027 9.91592 2.54817 8.89907 2.34098L8.00244 2.15761V2.15873ZM14.9999 13.501C15.2508 14.0039 15.5411 14.4021 15.8774 14.626H0.127441C0.463816 14.4021 0.754066 14.0039 1.00494 13.501C2.01744 11.476 2.37744 7.74098 2.37744 6.75098C2.37744 4.02848 4.31244 1.75598 6.88307 1.23736C6.86736 1.08093 6.88462 0.922951 6.93373 0.773604C6.98283 0.624257 7.0627 0.48686 7.16817 0.370275C7.27364 0.253689 7.40237 0.160503 7.54607 0.0967269C7.68976 0.0329507 7.84523 0 8.00244 0C8.15965 0 8.31512 0.0329507 8.45882 0.0967269C8.60251 0.160503 8.73125 0.253689 8.83672 0.370275C8.94219 0.48686 9.02205 0.624257 9.07115 0.773604C9.12026 0.922951 9.13752 1.08093 9.12182 1.23736C10.3934 1.496 11.5365 2.18614 12.3576 3.19092C13.1787 4.19571 13.6273 5.45338 13.6274 6.75098C13.6274 7.74098 13.9874 11.476 14.9999 13.501Z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default Push;
