import React from 'react';
import { useParams } from 'react-router-dom';
import CurrencyDetail from '../../Components/DetailPage/Detail/CurrencyDetail';

const DetailCurrencyPage = () => {
  const { id } = useParams();

  return <CurrencyDetail detailId={id} />;
};

export default DetailCurrencyPage;
