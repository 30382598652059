import React, { useEffect } from 'react';
import { useNavi } from '../services/hooks/useNavi';
import PublicTransportTabs from '../Components/DetailPage/Tab/PublicTransportTabs';

const PublicTransportPage = () => {
  const { clearNavigate } = useNavi();
  useEffect(() => {
    clearNavigate();
  }, []);

  return (
    <div className={'main-page'}>
      <PublicTransportTabs />
    </div>
  );
};

export default PublicTransportPage;
