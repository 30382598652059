import React, { useState } from 'react';
import './style.scss';
import ColorPicker from '@rc-component/color-picker';
import '@rc-component/color-picker/assets/index.css';
import { Button } from 'react-bootstrap';
import { COLOR_DEFAULT_VALUE, COMPONENT_VARIANT } from '../../../constants/const';

const InputColorPicker = (props) => {
  const [showPicker, setShowPicker] = useState(false);
  const [showSelectColor, setShowSelectColor] = useState(false);
  const [value, setValue] = useState(props.defaultValue);

  const onChange = (value) => {
    setValue(value);
  };

  const onBlur = () => {
    console.log(value);
    props.setField(value);
  };

  const onChangeSelectColor = (element) => {
    console.log(element);
    props.setField(element);
  };

  return (
    <div className="color-picker-detail">
      {props.head && (
        <div className="head-context">
          <div className="head">{props.head}</div>
          {props.isNecessarily && <div className="necessarily">*</div>}
        </div>
      )}
      <div className="color-picker-content">
        <div
          className="color-picker-box-show"
          style={
            props.defaultValue ? { background: props.defaultValue } : { background: 'lightgrey' }
          }
        />
        <input
          className={'input-color-picker'}
          type={'text'}
          disabled={props.isDisabled}
          value={value ?? ''}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={onBlur}
          placeholder={props.placeholder}
        />
      </div>
      <div className="color-select">
        <Button
          variant={COMPONENT_VARIANT.secondary}
          onClick={() => {
            setShowSelectColor(!showSelectColor);
          }}>
          select
        </Button>
        <div
          className="color-select-content"
          style={showSelectColor ? { display: 'block' } : { display: 'none' }}>
          <div className="color-select-header">Select color</div>
          <div className="color-select-modal">
            {COLOR_DEFAULT_VALUE.map((element, index) => {
              return (
                <button
                  key={index}
                  className="color-select-element-button"
                  style={{ background: element }}
                  onClick={() => {
                    onChange(element)
                    onChangeSelectColor(element);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="color-picker-button">
        <Button
          variant={COMPONENT_VARIANT.primary}
          onClick={() => {
            setShowPicker(!showPicker);
          }}>
          {showPicker ? 'Close Picker' : 'Open Picker'}
        </Button>
        <div
          className="color-picker-content"
          style={showPicker ? { display: 'block' } : { display: 'none' }}>
          <ColorPicker
            value={props.defaultValue}
            onChangeComplete={(color) => {
              onChange(color)
              props.setField(color.toHexString());
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InputColorPicker;
