import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './style.scss';

const ModalWindow = (props) => {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);
  return (
    <div className="modal-window-select">
      <Button
        variant={props.buttonVariant ?? 'primary'}
        disabled={props.isDisabled}
        onClick={() => {
          handleShow();
          if (props.isLoading) {
            props.getData(1);
          }
        }}>
        {props.name}
      </Button>
      <Modal show={props.show} onHide={handleClose} className="modal-window">
        <Modal.Header className="modal-head" closeButton>
          {props.header}
        </Modal.Header>
        <Modal.Body className="modal-body">{props.children}</Modal.Body>
        <Modal.Footer>
          <Button className="footer-button" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalWindow;
