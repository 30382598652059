import React, { useState } from 'react';
import './style.scss';
import { COMPONENT_VARIANT, MAPS_TYPE_ORDERING } from '../../../../../constants/const';
import ModalConfirm from '../../../../ModalConfirm';
import Button from 'react-bootstrap/Button';
import ImageLoadDetail from '../../../../ImageComponent';
import { Alert, Modal, Table } from 'react-bootstrap';
import { useNavi } from '../../../../../services/hooks/useNavi';
import { ROUTE_AIRPORT_MAP } from '../../../../../constants/routes';
import Loader from '../../../../Loader';

const CreateMapModal = (props) => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);

  return (
    <div className="modal-context">
      <Button
        className={'delete-button'}
        variant={COMPONENT_VARIANT.primary}
        onClick={() => setShow(true)}>
        Add map
      </Button>
      <Modal show={show} onHide={() => setShow(false)} className="modal-window-confirm">
        <Modal.Header className="modal-head" closeButton>
          <Modal.Title>Add new map</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="confirm-text">
            {error && <Alert variant={'danger'}>Fill all fields!</Alert>}
            <input
              placeholder={'Map name'}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <ImageLoadDetail
              header={'Map photo'}
              defaultImage={null}
              imageDetail={image}
              setImageDetail={(value) => {
                setImage(value);
              }}
              isNecessarily
              isUnsplash
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="footer-button"
            variant={COMPONENT_VARIANT.primary}
            onClick={() => {
              console.log(name, image);
              if (name.trim().length > 0 && image) {
                setShow(false);
                props.addMap(name, image, props.categoryId);
                setImage(null);
                setName('');
                setError(false);
              } else {
                setError(true);
              }
            }}>
            Confirm
          </Button>
          <Button
            className="footer-button"
            variant={COMPONENT_VARIANT.secondary}
            onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const MapCategoryBlock = (props) => {
  const [newCategoryName, setNewCategoryName] = useState('');
  const navi = useNavi();

  const addNewCategory = () => {
    if (newCategoryName.trim().length > 0) {
      props.addCategory(newCategoryName);
      setNewCategoryName('');
    }
  };

  const modalDelete = (id) => (
    <ModalConfirm
      variant={COMPONENT_VARIANT.danger}
      name={'Delete category'}
      header={'Want to delete?'}
      className={'delete-button'}
      onClick={() => {
        props.deleteCategory(id);
      }}>
      Are you sure you want to delete this category?
    </ModalConfirm>
  );

  return (
    <div className="map-category-block">
      <div className={'add-new'}>
        <input
          placeholder={'New Category Name'}
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
        <Button
          style={{ width: '50px', height: '30px' }}
          variant={COMPONENT_VARIANT.primary}
          size="sm"
          onClick={addNewCategory}>
          <Loader loading={props.categoryLoader} small>
            Add
          </Loader>
        </Button>
      </div>

      {props.categories.map((i) => (
        <div key={i.id} className={'category'}>
          <div className={'category-header'}>
            <div>
              Category: <span>{i.name}</span>
            </div>
            <div className={'buttons'}>
              <CreateMapModal categoryId={i.id} addMap={props.addMap} />
              {modalDelete(i.id)}
            </div>
          </div>
          {props.maps.filter((map) => map.category === i.id).length === 0 && <div>No maps</div>}
          <Table striped bordered hover variant="light" className="table">
            <tbody>
              {props.maps
                .filter((map) => map.category === i.id)
                .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
                .map((element, index) => {
                  return (
                    <tr key={index}>
                      <td>{element.name}</td>
                      <td>
                        <Button
                          variant="link"
                          className="button-detail"
                          onClick={() => {
                            navi.pushRoute(
                              ROUTE_AIRPORT_MAP + '/' + element.id,
                              element.id,
                              element.name
                            );
                          }}>
                          Detail
                        </Button>
                      </td>
                      <td>
                        <ModalConfirm
                          variant={COMPONENT_VARIANT.link}
                          name={'Delete'}
                          header={'Want to delete?'}
                          className={'red-button'}
                          onClick={() => {
                            props.deleteMap(element.id);
                          }}>
                          Are you sure you want to delete this map?
                        </ModalConfirm>
                      </td>
                      <td>
                        <div className="button-maps-content">
                          <Button
                            className="button-maps-ordering"
                            variant={COMPONENT_VARIANT.link}
                            disabled={element.ordering === 1}
                            onClick={() => {
                              props.updateOrderingMap(element.id, MAPS_TYPE_ORDERING.up, i.id);
                            }}>
                            ↑
                          </Button>
                          <Button
                            className="button-maps-ordering"
                            variant={COMPONENT_VARIANT.link}
                            disabled={
                              element.ordering ===
                              props.maps.filter((map) => map.category === i.id).length
                            }
                            onClick={() => {
                              props.updateOrderingMap(element.id, MAPS_TYPE_ORDERING.down, i.id);
                            }}>
                            ↓
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default MapCategoryBlock;
