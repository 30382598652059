const TransitionWebsite = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99963 0.00219727C10.1214 0.00219727 12.1562 0.845052 13.6565 2.34534C15.1568 3.84563 15.9996 5.88047 15.9996 8.0022C15.9996 10.1239 15.1568 12.1588 13.6565 13.6591C12.1562 15.1593 10.1214 16.0022 7.99963 16.0022C5.8779 16.0022 3.84307 15.1593 2.34278 13.6591C0.842489 12.1588 -0.000366211 10.1239 -0.000366211 8.0022C-0.000366211 5.88047 0.842489 3.84563 2.34278 2.34534C3.84307 0.845052 5.8779 0.00219727 7.99963 0.00219727ZM4.49963 7.5022C4.36703 7.5022 4.23985 7.55488 4.14608 7.64864C4.05231 7.74241 3.99963 7.86959 3.99963 8.0022C3.99963 8.13481 4.05231 8.26198 4.14608 8.35575C4.23985 8.44952 4.36703 8.5022 4.49963 8.5022H10.2926L8.14563 10.6482C8.09915 10.6947 8.06227 10.7499 8.03711 10.8106C8.01195 10.8714 7.999 10.9365 7.999 11.0022C7.999 11.0679 8.01195 11.133 8.03711 11.1938C8.06227 11.2545 8.09915 11.3097 8.14563 11.3562C8.19212 11.4027 8.24731 11.4396 8.30805 11.4647C8.36879 11.4899 8.43389 11.5028 8.49963 11.5028C8.56538 11.5028 8.63048 11.4899 8.69122 11.4647C8.75196 11.4396 8.80715 11.4027 8.85363 11.3562L11.8536 8.3562C11.9002 8.30975 11.9371 8.25458 11.9623 8.19383C11.9876 8.13309 12.0005 8.06796 12.0005 8.0022C12.0005 7.93643 11.9876 7.87131 11.9623 7.81056C11.9371 7.74982 11.9002 7.69464 11.8536 7.6482L8.85363 4.6482C8.80715 4.60171 8.75196 4.56483 8.69122 4.53967C8.63048 4.51451 8.56538 4.50157 8.49963 4.50157C8.43389 4.50157 8.36879 4.51451 8.30805 4.53967C8.24731 4.56483 8.19212 4.60171 8.14563 4.6482C8.09915 4.69468 8.06227 4.74987 8.03711 4.81061C8.01195 4.87135 7.999 4.93645 7.999 5.0022C7.999 5.06794 8.01195 5.13304 8.03711 5.19378C8.06227 5.25452 8.09915 5.30971 8.14563 5.3562L10.2926 7.5022H4.49963Z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default TransitionWebsite;
