import React from 'react';
import { useParams } from 'react-router-dom';

import TipsDetailNew from '../../Components/DetailPage/Detail/TipsDetailNew';

const DetailTipsPage = () => {
  const { id } = useParams();

  return <TipsDetailNew id={id} />;
};

export default DetailTipsPage;
