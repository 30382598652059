import axios from 'axios';
import { STORAGE_LANGUAGE, STORAGE_TOKEN, STORAGE_USER_NAME } from '../constants/const';
import { baseURL } from './baseApiUrl';

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(STORAGE_TOKEN) ?? ''}`,
    HTTP_ACCEPT_LANGUAGE: 'en-US'
  }
});

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if ([401, 403].includes(error.response.status)) {
      localStorage.clear();
      location.reload();
    }

    return Promise.reject(error);
  }
);

export const downloadFileURl = (url) => {
  return axios.get(url, {
    responseType: 'blob'
  });
};

export const Api = {
  getProfile(username, password) {
    return instance
      .post('admin/login', {
        username: username,
        password: password
      })
      .then((res) => {
        localStorage.setItem(STORAGE_TOKEN, res.data.authToken);
        localStorage.setItem(STORAGE_USER_NAME, res.data.deviceId);
        localStorage.setItem(STORAGE_LANGUAGE, res.data.language);
        instance.defaults.headers.Authorization = `Bearer ${res.data.authToken}`;
        return res.data;
      });
  },

  getLanguages() {
    return instance.get('admin/language').then((res) => res.data);
  },

  getResponsible() {
    return instance.get('admin/list').then((res) => res.data);
  },

  getPushEvent() {
    return instance.get('admin/message/phrase').then((res) => res.data);
  },

  getPushEventId(id) {
    return instance.get('admin/message/phrase/' + id).then((res) => res.data);
  },
  updatePushEventId(id, data) {
    return instance
      .put('admin/message/phrase/' + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  createLanguagePushEventId(id, language, text) {
    return instance
      .post('admin/message/language/work/' + id, { language: language, text: text })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deleteLanguagePushEventId(id, language) {
    return instance
      .delete('admin/message/language/work/' + id, { data: { language: language } })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },

  getReminder() {
    return instance.get('admin/reminder').then((res) => res.data);
  },

  getReminderId(id) {
    return instance.get('admin/reminder/' + id).then((res) => res.data);
  },
  updateReminderId(id, data) {
    return instance
      .put('admin/reminder/' + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  createReminder(data) {
    return instance
      .post('admin/reminder', data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deleteReminder(id) {
    return instance
      .delete('admin/reminder/' + id)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },

  getMarketingPush() {
    return instance.get('admin/marketing/push').then((res) => res.data);
  },

  getMarketingPushId(id) {
    return instance.get('admin/marketing/push/' + id).then((res) => res.data);
  },
  updateMarketingPushId(id, data) {
    return instance
      .put('admin/marketing/push/' + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  createMarketingPushId(data) {
    return instance
      .post('admin/marketing/push', data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deleteMarketingPush(id) {
    return instance
      .delete('admin/marketing/push/' + id)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },

  deletePushEventId(id) {
    return instance.delete('admin/message/phrase/' + id).then((res) => res.data);
  },

  getMapCategories() {
    return instance.get('admin/category_map').then((res) => res.data);
  },

  createMapCategory(name, airportId) {
    return instance
      .post('admin/category_map', { name: name, airport: airportId })
      .then((res) => res.data);
  },

  deleteMapCategory(id) {
    return instance.delete('admin/category_map/' + id).then((res) => res.data);
  },

  getAirportMapPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/airport_map', { params: params }).then((res) => res.data);
  },
  getAirportMapId(id) {
    return instance.get('admin/airport_map/' + id).then((res) => res.data);
  },

  createAirportMap(data) {
    return instance
      .post('admin/airport_map', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },

  updateAirportMap(id, data) {
    return instance
      .put('admin/airport_map/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },

  deleteAirportMap(id) {
    return instance.delete('admin/airport_map/' + id).then((res) => res.data);
  },

  getAirportPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance
      .get('admin/airport', {
        params: params
      })
      .then((res) => res.data);
  },

  getAirportId(id) {
    return instance.get('admin/airport/' + id + '/').then((res) => res.data);
  },
  createAirport(data) {
    return instance
      .post('admin/airport', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateAirport(id, data) {
    return instance
      .put('admin/airport/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateCheckedAirport(id) {
    return instance.get('admin/airport/update/check/' + id).then((res) => res.data);
  },
  deleteAirport(id) {
    return instance.delete('admin/airport/' + id + '/').then((res) => res.data);
  },

  getAirlinesPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance
      .get('admin/airline', {
        params: params
      })
      .then((res) => res.data);
  },

  getAirlinesId(id) {
    return instance.get('admin/airline/' + id).then((res) => res.data);
  },
  createAirlines(data) {
    return instance
      .post('admin/airline', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateAirlines(id, data) {
    return instance
      .put('admin/airline/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateCheckedAirlines(id) {
    return instance.get('admin/airline/update/' + id).then((res) => res.data);
  },
  deleteAirlines(id) {
    return instance.delete('admin/airline/' + id).then((res) => res.data);
  },

  getCityPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/cities', { params: params }).then((res) => res.data);
  },
  getCityId(Id) {
    return instance.get('admin/cities/' + Id).then((res) => res.data);
  },

  createCity(data) {
    return instance
      .post('admin/cities', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },
  updateCity(id, data) {
    return instance
      .put('admin/cities/' + id, data)
      .then((res) => res.data)
      .catch((e) => e);
  },

  loadCityImage(id, image) {
    const data = new FormData();
    data.append('cityImage', image);

    return instance
      .post('admin/cities/image/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },

  deleteCity(id) {
    return instance.delete('admin/cities/' + id).then((res) => res.data);
  },

  getCountryPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/country', { params: params }).then((res) => res.data);
  },
  getCountryId(id) {
    return instance.get('admin/country/' + id).then((res) => res.data);
  },
  createCountry(data) {
    return instance
      .post('admin/country', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },
  updateCountry(id, data) {
    return instance
      .put('admin/country/' + id, data)
      .then((res) => res.data)
      .catch((e) => e);
  },
  deleteCountry(id) {
    return instance.delete('admin/country/' + id).then((res) => res.data);
  },
  getCurrencyPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/currency', { params: params }).then((res) => res.data);
  },
  getCurrencyId(id) {
    return instance.get('admin/currency/' + id).then((res) => res.data);
  },
  createCurrency(data) {
    return instance
      .post('admin/currency', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => res.data);
  },
  updateCurrency(id, data) {
    return instance.put('admin/currency/' + id, { data: data }).then((res) => res.data);
  },
  deleteCurrency(id) {
    return instance.delete('admin/currency/' + id).then((res) => res.data);
  },

  getTaxiPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/taxi', { params: params }).then((res) => res.data);
  },

  getTaxiId(id) {
    return instance.get('admin/taxi/' + id).then((res) => res.data);
  },

  getTaxiAirportId(id) {
    return instance.get('admin/airport/taxis/' + id).then((res) => res.data);
  },

  updateTaxiAirportId(id, data) {
    return instance.post('admin/airport/taxis/' + id, { airports: data }).then((res) => {
      return res.data;
    });
  },

  deleteTaxiAirportId(id, data) {
    return instance
      .delete('admin/airport/taxis/' + id, { data: { airports: data } })
      .then((res) => {
        return res.data;
      });
  },

  createTaxi(data) {
    return instance
      .post('admin/taxi', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },

  updateTaxi(id, data) {
    return instance
      .put('admin/taxi/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },

  deleteTaxi(id) {
    return instance.delete('admin/taxi/' + id).then((res) => res.data);
  },

  getRentalPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance
      .get('admin/rental', {
        params: params
      })
      .then((res) => res.data);
  },

  getRentalId(id) {
    return instance.get('admin/rental/' + id).then((res) => res.data);
  },
  createRental(data) {
    return instance
      .post('admin/rental', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateRental(id, data) {
    return instance
      .put('admin/rental/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deleteRental(id) {
    return instance.delete('admin/rental/' + id).then((res) => res.data);
  },
  getRentalAirportId(id) {
    return instance.get('admin/airport/rentals/' + id).then((res) => res.data);
  },
  updateRentalAirportId(id, data) {
    return instance.post('admin/airport/rentals/' + id, { airports: data }).then((res) => {
      return res.data;
    });
  },
  deleteRentalAirportId(id, data) {
    return instance
      .delete('admin/airport/rentals/' + id, { data: { airports: data } })
      .then((res) => {
        return res.data;
      });
  },

  getWeatherStatePage() {
    return instance.get('admin/weather').then((res) => res.data);
  },
  createWeatherStateLocalization(data) {
    return instance
      .post('admin/weather/description/', data, {
        headers: {
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateWeatherState(id, data) {
    return instance
      .put('admin/weather/description/' + id, data, {
        headers: {
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  getPublicTransportPage(
    page,
    count,
    search = null,
    filter = null,
    sort = null,
    filterBoolean = null
  ) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance
      .get('admin/transports', {
        params: params
      })
      .then((res) => res.data);
  },
  getPublicTransportId(id) {
    return instance.get('admin/transports/' + id).then((res) => res.data);
  },
  createPublicTransport(data) {
    return instance
      .post('admin/transports', data, {
        headers: {
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updatePublicTransport(id, data) {
    return instance
      .put('admin/transports/' + id, data, {
        headers: {
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deletePublicTransport(id) {
    return instance.delete('admin/transports/' + id).then((res) => res.data);
  },
  publicTransportAddAirport(airportId, transportId) {
    return instance
      .post(
        'admin/transport/airport/add',
        { airport: airportId, transport: transportId },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            HTTP_ACCEPT_LANGUAGE: 'en-US'
          }
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  getPublicTransportDescriptionImage() {
    return instance
      .get('admin/transport/image')
      .then((res) => {
        return res.data;
      })
      .catch((res) => {
        console.log(res);
      });
  },
  loadPublicTransportDescriptionImage(image) {
    const data = new FormData();
    data.append('imagePath', image);

    return instance
      .post('admin/transport/image', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },
  getAllTips() {
    return instance
      .get('tips')
      .then((res) => {
        return res.data;
      })
      .catch((res) => {
        console.log(res);
      });
  },
  getTipsId(id) {
    return instance.get('tips/' + id).then((res) => res.data);
  },
  approveTipsId(id, status) {
    return instance.post('tips/' + id + '/' + status).then((res) => res.data);
  },
  deleteTips(id) {
    return instance.delete('tips/' + id).then((res) => res.data);
  },
  
  createTips(airportID, data) {
    return instance
      .post('tip/admin/'+airportID, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          HTTP_ACCEPT_LANGUAGE: 'en-US'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
};
