import Authorization from '../Containers/Authorization';
import {
  EVENT_AIRLINES,
  EVENT_AIRPORT,
  EVENT_AIRPORT_MAP,
  EVENT_CITY,
  EVENT_COUNTRY,
  EVENT_CURRENCY,
  EVENT_MARKETING,
  EVENT_PUBLIC_TRANSPORT,
  EVENT_PUSH,
  EVENT_REMINDERS,
  EVENT_RENTAL,
  EVENT_TAXIS,
  EVENT_TIPS,
  EVENT_WEATHER_STATE,
  TABLE_EVENT_AIRPORTS,
  TABLE_EVENT_CITY,
  TABLE_EVENT_COUNTRY,
  TABLE_EVENT_TAXI
} from './const';
import AirportsPage from '../Containers/AirportsPage';
import AirportsMapsPage from '../Containers/AirportsMapsPage';
import TaxisPage from '../Containers/TaxisPage';
import DetailAirportPage from '../Containers/Details/DetailAirportPage';
import DetailAirportMapPage from '../Containers/Details/DetailAirportMapPage';
import DetailCityPage from '../Containers/Details/DetailCityPage';
import DetailCountryPage from '../Containers/Details/DetailCountryPage';
import DetailTaxiPage from '../Containers/Details/DetailTaxiPage';
import SelectAirportInTaxiPage from '../Containers/TableDetails/SelectAirportInTaxiPage';
import SelectTaxiInAirportPage from '../Containers/TableDetails/SelectTaxiInAirportPage';
import SelectCityInAirportPage from '../Containers/TableDetails/SelectCityInAirportPage';
import SelectCountryInAirportPage from '../Containers/TableDetails/SelectCountryInAirportPage';
import PushEventsPage from '../Containers/PushEventPage';
import DetailPushEventPage from '../Containers/Details/DetailPushEventPage';
import DetailCurrencyPage from '../Containers/Details/DetailCurrencyPage';
import DetailMarketingPage from '../Containers/Details/DetailMarketingPage';
import DetailRemindersPage from '../Containers/Details/DetailRemindersPage';
import AirlinesPage from '../Containers/AirlinesPage';
import DetailAirlinesPage from '../Containers/Details/DetailAirlinesPage';
import RentalPage from '../Containers/RentalPage';
import DetailRentalPage from '../Containers/Details/DetailRentalPage';
import SelectAirportInRentalPage from '../Containers/TableDetails/SelectAirportInRentalPage';
import CountryPage from '../Containers/CountryPage';
import WeatherStatePage from '../Containers/WeatherStatePage';
import CitiesPage from '../Containers/CitiesPage';
import PublicTransportPage from '../Containers/PublicTransportPage';
import DetailPublicTransportPage from '../Containers/Details/DetailPublicTransportPage';
import SelectAirportInPublicTransportPage from '../Containers/TableDetails/SelectAirportInPublicTransportPage';
import TipsPage from '../Containers/TipsPage';
import DetailTipsPage from '../Containers/Details/DetailTipsPage';

export const ROUTE_AUTHORIZE = '/authorization';

export const ROUTE_AIRPORT_MAP = '/' + EVENT_AIRPORT_MAP;
export const ROUTE_AIRPORT = '/' + EVENT_AIRPORT;
export const ROUTE_AIRLINES = '/' + EVENT_AIRLINES;
export const ROUTE_CITY = '/' + EVENT_CITY;
export const ROUTE_COUNTRY = '/' + EVENT_COUNTRY;
export const ROUTE_CURRENCY = '/' + EVENT_CURRENCY;
export const ROUTE_TAXIS = '/' + EVENT_TAXIS;
export const ROUTE_PUSH = '/' + EVENT_PUSH;
export const ROUTE_REMINDERS = '/' + EVENT_REMINDERS;
export const ROUTE_MARKETING = '/' + EVENT_MARKETING;
export const ROUTE_RENTAL = '/' + EVENT_RENTAL;
export const ROUTE_WEATHER_STATE = '/' + EVENT_WEATHER_STATE;
export const ROUTE_PUBLIC_TRANSPORT = '/' + EVENT_PUBLIC_TRANSPORT;
export const ROUTE_TIPS = '/' + EVENT_TIPS;

export const ROUTE_TAXIS_ADD_AIRPORT = ROUTE_TAXIS + '/' + TABLE_EVENT_AIRPORTS;
export const ROUTE_AIRPORT_ADD_TAXI = ROUTE_AIRPORT + '/' + TABLE_EVENT_TAXI;
export const ROUTE_AIRPORT_ADD_CITY = ROUTE_AIRPORT + '/' + TABLE_EVENT_CITY;
export const ROUTE_AIRPORT_ADD_COUNTRY = ROUTE_AIRPORT + '/' + TABLE_EVENT_COUNTRY;
export const ROUTE_RENTAL_ADD_AIRPORT = ROUTE_RENTAL + '/' + TABLE_EVENT_AIRPORTS;
export const ROUTE_PUBLIC_TRANSPORT_ADD_AIRPORT = ROUTE_PUBLIC_TRANSPORT + '/' + TABLE_EVENT_AIRPORTS;

export const routes = [
  { path: ROUTE_AUTHORIZE, container: <Authorization /> },
  { path: ROUTE_AIRPORT, container: <AirportsPage /> },
  { path: ROUTE_AIRPORT_MAP, container: <AirportsMapsPage /> },
  { path: ROUTE_AIRLINES, container: <AirlinesPage /> },
  { path: ROUTE_COUNTRY, container: <CountryPage /> },
  { path: ROUTE_TAXIS, container: <TaxisPage /> },
  { path: ROUTE_RENTAL, container: <RentalPage /> },
  { path: ROUTE_PUSH, container: <PushEventsPage /> },
  { path: ROUTE_REMINDERS, container: <PushEventsPage /> },
  { path: ROUTE_MARKETING, container: <PushEventsPage /> },
  { path: ROUTE_WEATHER_STATE, container: <WeatherStatePage /> },
  { path: ROUTE_CITY, container: <CitiesPage /> },
  { path: ROUTE_PUBLIC_TRANSPORT, container: <PublicTransportPage /> },
  { path: ROUTE_TIPS, container: <TipsPage /> },

  { path: ROUTE_AIRPORT + '/:id', container: <DetailAirportPage /> },
  { path: ROUTE_AIRPORT_MAP + '/:id', container: <DetailAirportMapPage /> },
  { path: ROUTE_AIRLINES + '/:id', container: <DetailAirlinesPage /> },
  { path: ROUTE_CITY + '/:id', container: <DetailCityPage /> },
  { path: ROUTE_COUNTRY + '/:id', container: <DetailCountryPage /> },
  { path: ROUTE_CURRENCY + '/:id', container: <DetailCurrencyPage /> },
  { path: ROUTE_TAXIS + '/:id', container: <DetailTaxiPage /> },
  { path: ROUTE_RENTAL + '/:id', container: <DetailRentalPage /> },
  { path: ROUTE_PUSH + '/:id', container: <DetailPushEventPage /> },
  { path: ROUTE_REMINDERS + '/:id', container: <DetailRemindersPage /> },
  { path: ROUTE_MARKETING + '/:id', container: <DetailMarketingPage /> },
  { path: ROUTE_PUBLIC_TRANSPORT + '/:id', container: <DetailPublicTransportPage /> },
  { path: ROUTE_TIPS + '/:id', container: <DetailTipsPage /> },

  { path: ROUTE_AIRPORT_ADD_TAXI + '/:id', container: <SelectTaxiInAirportPage /> },
  { path: ROUTE_AIRPORT_ADD_CITY + '/:id', container: <SelectCityInAirportPage /> },
  { path: ROUTE_AIRPORT_ADD_COUNTRY + '/:id', container: <SelectCountryInAirportPage /> },
  { path: ROUTE_TAXIS_ADD_AIRPORT + '/:id', container: <SelectAirportInTaxiPage /> },
  { path: ROUTE_RENTAL_ADD_AIRPORT + '/:id', container: <SelectAirportInRentalPage /> },
  { path: ROUTE_PUBLIC_TRANSPORT_ADD_AIRPORT + '/:id', container: <SelectAirportInPublicTransportPage /> }
  

  /*{ path: ROUTE_AIRPORT_MAP + '/:id', container: <DetailPage /> },
  { path: ROUTE_AIRPORT + '/:id', container: <DetailPage /> },
  { path: ROUTE_CITY + '/:id', container: <DetailPage /> },
  { path: ROUTE_COUNTRY + '/:id', container: <DetailPage /> },
  { path: ROUTE_CURRENCY + '/:id', container: <DetailPage /> },
  { path: ROUTE_TAXIS + '/:id', container: <DetailPage /> },

  { path: ROUTE_AIRPORT_MAP + '/new', container: <DetailPage /> },
  { path: ROUTE_AIRPORT + '/new', container: <DetailPage /> },
  { path: ROUTE_CITY + '/new', container: <DetailPage /> },
  { path: ROUTE_COUNTRY + '/new', container: <DetailPage /> },
  { path: ROUTE_CURRENCY + '/new', container: <DetailPage /> },
  { path: ROUTE_TAXIS + '/new', container: <DetailPage /> }
   */
];
