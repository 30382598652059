export const EVENT_AIRPORT_MAP = 'airport_map';
export const EVENT_AIRPORT = 'airports';
export const EVENT_AIRLINES = 'airlines';
export const EVENT_CITY = 'city';
export const EVENT_COUNTRY = 'country';
export const EVENT_CURRENCY = 'currency';
export const EVENT_TAXIS = 'taxis';
export const EVENT_PUSH = 'push_event';
export const EVENT_REMINDERS = 'reminders';
export const EVENT_MARKETING = 'marketing';
export const EVENT_RENTAL = 'rental';
export const EVENT_WEATHER_STATE = 'weather_state';
export const EVENT_PUBLIC_TRANSPORT = 'public_transport';
export const EVENT_TIPS = 'tips';

export const TABLE_EVENT_TAXI = 'add_taxi';
export const TABLE_EVENT_AIRPORTS = 'add_airports';
export const TABLE_EVENT_CITY = 'add_city';
export const TABLE_EVENT_COUNTRY = 'add_country';

export const DIVIDER_ELEMENT = 'divider';

export const DESCRIPTION_PAGE = {
  airport: 'Airport',
  airportMap: 'Airport map',
  airlines: 'Airlines',
  public_transport: 'Public transport',
  city: 'City',
  country: 'Country',
  currency: 'Currency',
  taxis: 'Taxis',
  rental: 'Rental',
  pushEvents: 'Push events',
  weather_state: 'Weather state',
  tips: 'Tips'
};

export const TABS_PUSH_EVENT = {
  FlightStatus: 'FlightStatus',
  Reminders: 'Reminders',
  Marketing: 'Marketing'
};

export const TIPS_STATUS_SHOW = {
  in_review: 'In review',
  approved: 'Approved',
  rejected: 'Rejected'
};

export const TIPS_STATUS = {
  in_review: 'in_review',
  approved: 'approved',
  rejected: 'rejected'
};

export const TIPS_CATEGORY = [
  'transport',
  'food',
  'lounge',
  'luggage',
  'internet',
  'terminal',
  'gate',
  'shop',
  'airline',
  'smoking-area',
  'flight',
  'security',
  'other'
];

export const TIPS_TAB_ELEMENT = {
  Requests: 'Requests',
  AllTips: 'All tips'
};

export const DEFAULT_LOCALIZATION_PUSH_EVENT = [
  { language: 'ja' },
  { language: 'fr' },
  { language: 'es' },
  { language: 'it' },
  { language: 'de' },
  { language: 'en' },
  { language: 'ko' },
  { language: 'ru' }
];

export const FULL_LOCALIZATION_NAME = {
  ja: 'Japanese',
  fr: 'French',
  es: 'Spanish',
  it: 'Italian',
  de: 'German',
  en: 'English',
  ko: 'Korean',
  ru: 'Russian'
};

export const PUBLIC_TRANSPORT_DROPDOWN_ELEMENT = [
  'train',
  'metro',
  'bus',
  'shuttle',
  'parking',
  'tram',
  'other'
];

export const PUBLIC_TRANSPORT_TAG_ELEMENT = [
  'hot',
  'eco',
  'fast',
  'wheelchair',
  'low_cost',
  'expensive',
  'electro',
  'not selected'
];

export const UPDATE_DATA_CHANGE = {
  delete: 'delete',
  update: 'update'
};

export const DETAIL_IDS = {
  NEW: 'new',
  OTHER: 'other'
};
export const COMPONENT_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  info: 'info',
  light: 'light',
  dark: 'dark',
  link: 'link'
};
export const COLOR_DEFAULT_VALUE = [
  '#E00086',
  '#E663C9',
  '#EF4664',
  '#F06D6D',
  '#F65C2B',
  '#FF9500',
  '#75BA1D',
  '#25BF6C',
  '#00C7BE',
  '#35A2F1',
  '#6175FF',
  '#B152DE'
];
export const TYPE_SELECT_DATA = {
  delete: 'Delete',
  select: 'Select'
};

export const LOADER_ANIMATED_VARIANT = {
  grow: 'grow',
  border: 'border'
};
export const MAPS_TYPE_ORDERING = {
  up: 'up',
  down: 'down'
};

export const LANGUAGE_DEFAULT = 'en';

export const STORAGE_LANGUAGE = 'language';
export const STORAGE_USER_NAME = 'user-name';
export const STORAGE_TOKEN = 'token';
export const STORAGE_NAVIGATE_PATCH = 'localPatch';
export const STORAGE_BASE_SEARCH_PARAMS = 'search-';
export const STORAGE_NAVIGATE = 'navigate';

export const PROTOCOL = {
  HTTP: 'http://',
  HTTPS: 'https://'
};
export const BASE_ITEMS_PAGE = 20;

export const DELAY_TIME_RECENTLY = 7;
export const DELAY_TIME_LONG_AGO = 30;

export const GOOGLE_MAPS_KEY = 'AIzaSyAQC0cHMMKJWckCI9x2ctX41jleFe0ia2o';
export const UNSPLASH_API_KEY = 'xGynK0nc2wI3tm55uDhz62dGBxl-CiwMxiGzZ91kWyI';

export const DESKTOP_SCREEN_SIZE = 1160;
export const TABLET_SCREEN_SIZE = 744;
export const SCROLL_SHOW_EDGE = 400;
