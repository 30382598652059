import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAirport, setAirportCity } from '../../../../redux/reducers/airport';
import { Api } from '../../../../services/Api';
import Loader from '../../../Loader';
import { COMPONENT_VARIANT, EVENT_AIRPORT, EVENT_CITY } from '../../../../constants/const';
import TableSelectDetail from '../../../DetailComponent/TableSelectDetail';

const AirportSelectCity = (props) => {
  const stateElement = useSelector((state) => state.airport);
  const [selectedElement, setSelectedElement] = useState(stateElement.city);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!stateElement.id || props.id !== stateElement.id) && props.id !== 'new')
      dispatch(getAirport(parseInt(props.id)));
  }, [props.id]);

  useEffect(() => {
    if (stateElement.city && selectedElement === null) setSelectedElement(stateElement.city);
  }, [stateElement]);

  const saveData = (selectElement) => {
    dispatch(setAirportCity(selectElement));
  };

  const onSelect = (element) => {
    setSelectedElement(element);
  };

  const onDelete = () => {
    setSelectedElement(null);
  };
  return (
    <Loader loading={!stateElement.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <TableSelectDetail
        title={'City'}
        description={'Select city companies to add to this airport and click Save.'}
        titleCard={'otherNames'}
        saveData={saveData}
        request={Api.getCityPage}
        event={EVENT_CITY}
        navigateEvent={EVENT_AIRPORT}
        selectedElement={selectedElement}
        onSelect={onSelect}
        onDelete={onDelete}
        isMultiLanguage
        isOnlySelect
      />
    </Loader>
  );
};

export default AirportSelectCity;
