import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAirport, setAirportTaxis } from '../../../../redux/reducers/airport';
import { Api } from '../../../../services/Api';
import Loader from '../../../Loader';
import { COMPONENT_VARIANT, EVENT_AIRPORT, EVENT_TAXIS } from '../../../../constants/const';
import TableSelectDetail from '../../../DetailComponent/TableSelectDetail';

const AirportSelectTaxi = (props) => {
  const stateElement = useSelector((state) => state.airport);
  const [selectedElement, setSelectedElement] = useState(stateElement.taxis);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!stateElement.id || props.id !== stateElement.id) && props.id !== 'new')
      dispatch(getAirport(parseInt(props.id)));
  }, [props.id]);

  useEffect(() => {
    if (stateElement.taxis.length > 0 && selectedElement.length === 0)
      setSelectedElement(stateElement.taxis);
  }, [stateElement]);

  const saveData = (selectTaxi) => {
    dispatch(setAirportTaxis(selectTaxi));
  };

  const onSelect = (element) => {
    const newElement = [...selectedElement];
    newElement.push(element);
    setSelectedElement(newElement);
  };

  const onDelete = (value) => {
    const newElement = [];
    selectedElement.forEach((element) => {
      if (element.id !== value.id) newElement.push(element);
    });
    setSelectedElement(newElement);
  };
  return (
    <Loader loading={!stateElement.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <TableSelectDetail
        title={'Taxi'}
        description={'Select taxi companies to add to this airport and click Save.'}
        titleCard={'title'}
        saveData={saveData}
        request={Api.getTaxiPage}
        event={EVENT_TAXIS}
        navigateEvent={EVENT_AIRPORT}
        selectedElement={selectedElement}
        onSelect={onSelect}
        onDelete={onDelete}
      />
    </Loader>
  );
};

export default AirportSelectTaxi;
