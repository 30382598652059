import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const DropDownButton = (props) => {
  return (
    <DropdownButton
      className={props.className}
      title={props.activeElement}
      variant={props.variant}
      disabled={props.disabled}
    >
      {props.element.map((value, index) => {
        return (
          <Dropdown.Item
            key={index}
            onClick={() => {
              props.setActiveElement(value);
            }}
          >
            {value}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default DropDownButton;
