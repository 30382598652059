import React from 'react';
import DropDownButton from '../../DropDownButton';
import './style.scss'

const InputDropDown = (props) => {
  return (
    <div className="input-drop-down">
      {props.head && (
        <div className="head-context">
          <div className="head">{props.head}</div>
          {props.isNecessarily && <div className="necessarily">*</div>}
        </div>
      )}
      <div className={'input-lang-wrapper'}>
        <DropDownButton
          className={'drop-down-language'}
          activeElement={props.defaultValue?? "No select"}
          setActiveElement={(element) => {
            props.setField(element);
          }}
          element={props.listValue}
          disabled={props.disabled}
          variant="inline-secondary"/>
      </div>
    </div>
  );
};

export default InputDropDown;
