import { createStore, applyMiddleware, combineReducers } from 'redux';
import app from './reducers/app';
import { logger } from 'redux-logger/src';
import thunk from 'redux-thunk';
import airportMaps from './reducers/airportMaps';
import airport from './reducers/airport';
import city from './reducers/city';
import country from './reducers/country';
import currency from './reducers/currency';
import taxis from './reducers/taxis';
import pushEvent from './reducers/pushEvent';
import airlines from './reducers/airlines';
import rental from './reducers/rental';
import weatherState from './reducers/weatherState';
import publicTransport from './reducers/publicTransport';

let middleware = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
} else {
  middleware = [...middleware];
}

const store = createStore(
  combineReducers({
    app,
    airport,
    airportMaps,
    airlines,
    city,
    country,
    currency,
    taxis,
    rental,
    pushEvent,
    weatherState,
    publicTransport
  }),
  applyMiddleware(...middleware)
);

export default store;
