import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
const ModalDetail = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="modal-context">
      <Button
        variant={props.buttonVariant ?? 'primary'}
        disabled={props.isDisabled}
        className={props.className}
        onClick={() => {
          handleShow();
        }}>
        {props.name}
      </Button>
      <Modal show={show} onHide={handleClose} className="modal-window">
        <Modal.Header className="modal-head" closeButton>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">{props.children}</Modal.Body>
        <Modal.Footer>
          <Button className="footer-button" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalDetail;
