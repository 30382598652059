export const convertDateTimeFormat = (stringDate) => {
  if (stringDate && stringDate !== '') {
    const dateConvert = new Date(stringDate);
    const masStringDate = dateConvert.toUTCString().split(' ');
    const timeDate = masStringDate[4].split(':');
    return masStringDate[1] + ' ' + masStringDate[2] + ', ' + timeDate[0] + ':' + timeDate[1];
  }
  return 'never';
};

export const convertDateTimeFormatYar = (stringDate) => {
  if (stringDate && stringDate !== '') {
    const dateConvert = new Date(stringDate);
    const masStringDate = dateConvert.toUTCString().split(' ');
    return masStringDate[1] + ' ' + masStringDate[2] + ' ' + masStringDate[3];
  }
  return 'never';
};

export const getDelayDay = (stringDate) => {
  if (stringDate && stringDate !== '') {
    const dateConvert = new Date(stringDate);
    const currentTime = Date.now();
    const deltaDay = 1000 * 60 * 60 * 24;
    const timeDelay = currentTime - dateConvert.getTime();
    return Math.floor(timeDelay / deltaDay);
  }
  return 0;
};
