import React from 'react';
import { useParams } from 'react-router-dom';
import PublicTransportSelectAirport from '../../Components/DetailPage/DetailTable/PublicTransportSelectAirport';

const SelectAirportInPublicTransportPage = () => {
  const { id } = useParams();

  return <PublicTransportSelectAirport id={id} />;
};

export default SelectAirportInPublicTransportPage;
