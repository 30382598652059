import React from 'react';
import { useParams } from 'react-router-dom';

import TaxisDetail from '../../Components/DetailPage/Detail/TaxisDetail';

const DetailTaxiPage = () => {
  const { id } = useParams();

  return <TaxisDetail id={id} />;
};

export default DetailTaxiPage;
