import React, { useEffect, useState } from 'react';
import { Api } from '../../../../services/Api';
import { COMPONENT_VARIANT, TIPS_STATUS } from '../../../../constants/const';
import InputDetail from '../../../DetailComponent/InputDetail';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import './style.scss';
import { Button, Modal } from 'react-bootstrap';

const TipsDetailModal = (props) => {
  const [tips, setTips] = useState(null);
  const [response, setResponse] = useState(null);
  const [modalImageDetail, setModalImageDetail] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  useEffect(() => {
    if (!tips)
      Api.getTipsId(parseInt(props.id)).then((res) => {
        setTips(res.payload);
      });
  }, []);

  const approveTips = (status) => {
    Api.approveTipsId(tips.id, status).then(() => {
      setResponse({ id: 'ok' });
    });
  };

  return (
    <Loader
      loading={tips === null}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="tips-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={tips.id} />
          </BlockDetail>
        )}
        <div className="tips-detail-content">
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="tips-detail-data-context">
              <div className="tips-detail-data">
                <InputDetail
                  head="Text"
                  placeholder={'text'}
                  defaultValue={tips?.text}
                  setField={() => {}}
                  isDisabled
                  isMultiline
                  isInput
                />
                <InputDetail
                  head="Category"
                  placeholder={'Category'}
                  defaultValue={tips?.category}
                  setField={() => {}}
                  isDisabled
                  isInput
                />
                <div className="tips-photos">
                  <div className="tips-photos-header">Photos</div>
                  <div className="tips-photos-context">
                    {tips?.photos?.map((element, key) => {
                      return (
                        <img
                          className={'tips-photos-element'}
                          key={key}
                          src={element}
                          alt="img"
                          onClick={() => {
                            setModalImageDetail(element);
                            handleShow();
                          }}
                        />
                      );
                    })}
                  </div>
                  <Modal show={modalShow} onHide={handleClose} className="modal-window">
                    <Modal.Header className="modal-head" closeButton>
                      <Modal.Title>{props.header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                      <img className={'tips-photos-modal'} src={modalImageDetail} alt="img" />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="footer-button" variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </BlockDetail>
          <BlockDetail>
            <div className="tips-linked-header">Linked Airport</div>
            <div className="tips-linked">{tips?.airport}</div>
          </BlockDetail>
          {props.isApproved && (
            <div className="action-button">
              <Button
                className="approved-button"
                variant={COMPONENT_VARIANT.success}
                onClick={() => {
                  approveTips(TIPS_STATUS.approved);
                }}>
                Approve
              </Button>
              <Button
                className="rejected-button"
                variant={COMPONENT_VARIANT.danger}
                onClick={() => {
                  approveTips(TIPS_STATUS.rejected);
                }}>
                Reject
              </Button>
            </div>
          )}
          {props.isToReview && (
            <div className="action-button">
              <Button
                className="review-button"
                variant={COMPONENT_VARIANT.primary}
                onClick={() => {
                  approveTips(TIPS_STATUS.in_review);
                }}>
                In review
              </Button>
            </div>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default TipsDetailModal;
