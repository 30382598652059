import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { FILTER_BOOLEAN } from '../../../../constants/searchElement';
import classNames from 'classnames';
import { COMPONENT_VARIANT } from '../../../../constants/const';
import './style.scss';

const FilterBooleanNawBar = (props) => {
  const filterField = FILTER_BOOLEAN[props.event];
  const activeValue = props.activeValue;
  return (
    filterField && (
      <div className="filter-boolean">
        <Dropdown>
          <Dropdown.Toggle
            variant={COMPONENT_VARIANT.link}
            id={'dropdown-filter-boolean' + props.event}
            className={classNames({ 'filter-button': true, active: !!activeValue })}>
            Filled: {activeValue ?? 'not select'}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {filterField.map((field, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  disabled={field === activeValue}
                  onClick={() => {
                    props.setValue(field);
                  }}>
                  {field}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          variant={COMPONENT_VARIANT.link}
          className={classNames({ 'filter-clear-button': true, 'active-clear': !!activeValue })}
          disabled={!activeValue}
          onClick={() => {
            props.setValue(null);
          }}>
          X
        </Button>
      </div>
    )
  );
};

export default FilterBooleanNawBar;
