import React from 'react';
import { useParams } from 'react-router-dom';

import CountryDetail from '../../Components/DetailPage/Detail/CountryDetail';

const DetailCountryPage = () => {
  const { id } = useParams();

  return <CountryDetail id={id} />;
};

export default DetailCountryPage;
