import React from 'react';
import { useParams } from 'react-router-dom';
import AirportSelectTaxi from '../../Components/DetailPage/DetailTable/AirportSelectTaxi';

const SelectTaxiInAirportPage = () => {
  const { id } = useParams();
  return <AirportSelectTaxi id={id} />;
};

export default SelectTaxiInAirportPage;
