const Unsplash = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6417 10.8431C13.61 9.52181 14.0437 7.88361 13.856 6.25627C13.6683 4.62893 12.8731 3.13246 11.6295 2.06625C10.3858 1.00004 8.78545 0.442727 7.14854 0.505801C5.51162 0.568874 3.95889 1.24769 2.80097 2.40643C1.64305 3.56518 0.965352 5.1184 0.90345 6.75536C0.841548 8.39231 1.40001 9.99228 2.46711 11.2352C3.53421 12.4781 5.03125 13.2722 6.65872 13.4587C8.28619 13.6452 9.92408 13.2104 11.2447 12.2411H11.2437C11.2737 12.2811 11.3057 12.3191 11.3417 12.3561L15.1917 16.2061C15.3792 16.3938 15.6336 16.4992 15.8988 16.4993C16.1641 16.4994 16.4186 16.3941 16.6062 16.2066C16.7938 16.0191 16.8993 15.7647 16.8994 15.4995C16.8995 15.2342 16.7942 14.9798 16.6067 14.7921L12.7567 10.9421C12.7209 10.9059 12.6825 10.8725 12.6417 10.8421V10.8431ZM12.8997 6.99912C12.8997 7.72139 12.7574 8.43659 12.481 9.10388C12.2046 9.77117 11.7995 10.3775 11.2888 10.8882C10.7781 11.3989 10.1718 11.8041 9.50446 12.0805C8.83717 12.3569 8.12197 12.4991 7.3997 12.4991C6.67743 12.4991 5.96223 12.3569 5.29494 12.0805C4.62765 11.8041 4.02134 11.3989 3.51061 10.8882C2.99989 10.3775 2.59477 9.77117 2.31836 9.10388C2.04196 8.43659 1.8997 7.72139 1.8997 6.99912C1.8997 5.54043 2.47916 4.14149 3.51061 3.11004C4.54206 2.07859 5.94101 1.49912 7.3997 1.49912C8.85839 1.49912 10.2573 2.07859 11.2888 3.11004C12.3202 4.14149 12.8997 5.54043 12.8997 6.99912Z"
        fill="#343A40"
      />
    </svg>
  );
};

export default Unsplash;
