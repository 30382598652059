import {
  DESCRIPTION_PAGE,
  EVENT_AIRLINES,
  EVENT_AIRPORT,
  EVENT_AIRPORT_MAP,
  EVENT_CITY,
  EVENT_COUNTRY,
  EVENT_CURRENCY,
  EVENT_PUBLIC_TRANSPORT,
  EVENT_PUSH,
  EVENT_TAXIS,
  STORAGE_BASE_SEARCH_PARAMS,
  STORAGE_NAVIGATE_PATCH,
  STORAGE_TOKEN
} from '../constants/const';
import { clearAirport } from '../redux/reducers/airport';
import { clearAirportMap } from '../redux/reducers/airportMaps';
import { clearTaxi } from '../redux/reducers/taxis';
import { clearCity } from '../redux/reducers/city';
import { clearCountry } from '../redux/reducers/country';
import { clearCurrency } from '../redux/reducers/currency';
import { clearPushEvent } from '../redux/reducers/pushEvent';
import { clearAirlines } from '../redux/reducers/airlines';
import { clearPublicTransport } from '../redux/reducers/publicTransport';

export const clearStorage = () => {
  localStorage.setItem('localPatch', '');
};

export const logOutStorage = () => {
  localStorage.clear();
};

export const clearState = (event, id, stateData) => {
  return (dispatch) => {
    switch (event) {
      case EVENT_AIRPORT:
        if (stateData.airport.id !== id) dispatch(clearAirport());
        break;
      case EVENT_AIRPORT_MAP:
        if (stateData.airportMaps.id !== id) dispatch(clearAirportMap());
        break;
      case EVENT_TAXIS:
        if (stateData.taxis.id !== id) dispatch(clearTaxi());
        break;
      case EVENT_CITY:
        if (stateData.city.id !== id) dispatch(clearCity());
        break;
      case EVENT_COUNTRY:
        if (stateData.country.id !== id) dispatch(clearCountry());
        break;
      case EVENT_CURRENCY:
        if (stateData.currency.id !== id) dispatch(clearCurrency());
        break;
      case EVENT_PUSH:
        if (stateData.currency.id !== id) dispatch(clearPushEvent());
        break;
      case EVENT_AIRLINES:
        if (stateData.currency.id !== id) dispatch(clearAirlines());
        break;
      case EVENT_PUBLIC_TRANSPORT:
        if (stateData.currency.id !== id) dispatch(clearPublicTransport());
        break;
    }
  };
};

export const isAuthorization = () => {
  return !!localStorage.getItem(STORAGE_TOKEN);
};

export const getElementNavigate = () => {
  const currentItems = localStorage.getItem(STORAGE_NAVIGATE_PATCH);
  if (currentItems === '') return null;
  else return JSON.parse(currentItems);
};

export const saveElementNavigate = (value) => {
  const currentItems = localStorage.getItem(STORAGE_NAVIGATE_PATCH);
  if (!currentItems || currentItems === '') {
    const newMassive = [];
    newMassive.push(value);
    localStorage.setItem(STORAGE_NAVIGATE_PATCH, JSON.stringify(newMassive));
  } else {
    const newMassive = JSON.parse(currentItems);
    let isInsert = true;
    newMassive.forEach((element) => {
      if (element.navigate === value.navigate) isInsert = false;
    });
    if (isInsert) newMassive.push(value);
    localStorage.setItem(STORAGE_NAVIGATE_PATCH, JSON.stringify(newMassive));
  }
};

export const getStructNavigate = (name, navigate, title = '') => {
  let elementNavigate = { name: name, title: title, navigate: navigate };
  switch (name) {
    case EVENT_AIRPORT:
      elementNavigate.title = DESCRIPTION_PAGE.airport;
      break;
    case EVENT_AIRPORT_MAP:
      elementNavigate.title = DESCRIPTION_PAGE.airportMap;
      break;
    case EVENT_TAXIS:
      elementNavigate.title = DESCRIPTION_PAGE.taxis;
      break;
    default:
      if (title === '') {
        elementNavigate = {
          name: EVENT_AIRPORT,
          title: DESCRIPTION_PAGE.airport,
          navigate: '/' + EVENT_AIRPORT
        };
      }
      break;
  }
  return elementNavigate;
};

export const saveSearchParams = (event, page, searchData, sort, filter, filterBoolean) => {
  const storagePath = STORAGE_BASE_SEARCH_PARAMS + event;
  const saveStorageValue = {
    page: page,
    searchData: searchData,
    sort: sort,
    filter: filter,
    filterBoolean: filterBoolean
  };
  localStorage.setItem(storagePath, JSON.stringify(saveStorageValue));
};

export const getNavigate = () => {
  const activeNavigation = JSON.parse(localStorage.getItem(STORAGE_NAVIGATE_PATCH));
  const returnNavigate = [];
  activeNavigation.map((value) => {
    const findDuplicate = returnNavigate.find(
      (findValue) => findValue.name === value.name && findValue.title === value.title
    );
    if (!findDuplicate) returnNavigate.push(value);
  });
  localStorage.setItem(STORAGE_NAVIGATE_PATCH, JSON.stringify(returnNavigate));
  return returnNavigate;
};
