import { useNavigate } from 'react-router-dom';
import { STORAGE_NAVIGATE } from '../../constants/const';
import { ROUTE_AIRPORT } from '../../constants/routes';

export function useNavi() {
  const navigate = useNavigate();
  const allRoutes = JSON.parse(localStorage.getItem(STORAGE_NAVIGATE)) || [];

  const pushRoute = (route, id, name, withAlert) => {
    if (withAlert && !confirm('Are you sure you want to leave? Changes will not be saved')) return;
    const path = JSON.parse(localStorage.getItem(STORAGE_NAVIGATE)) || [];
    path.push({ route, id, name });
    localStorage.setItem(STORAGE_NAVIGATE, JSON.stringify(path));
    navigate(route);
  };

  const goBack = (count, withAlert) => {
    if (withAlert && !confirm('Are you sure you want to leave? Changes will not be saved')) return;
    allRoutes.splice(-count, count);
    localStorage.setItem(STORAGE_NAVIGATE, JSON.stringify(allRoutes));

    if (allRoutes.length === 0) {
      navigate(ROUTE_AIRPORT);
    } else {
      navigate(allRoutes.pop().route);
    }
  };

  const pushPatch = (route, id, name) => {
    const path = JSON.parse(localStorage.getItem(STORAGE_NAVIGATE)) || [];
    path.push({ route, id, name });
    localStorage.setItem(STORAGE_NAVIGATE, JSON.stringify(path));
  };

  const setRoute = (newRoute) => {
    localStorage.setItem(STORAGE_NAVIGATE, JSON.stringify(newRoute));
  };
  const clearNavigate = () => {
    localStorage.removeItem(STORAGE_NAVIGATE);
  };

  return { pushRoute, allRoutes, goBack, pushPatch, setRoute, clearNavigate };
}
