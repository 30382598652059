import React, { useEffect } from 'react';
import { useNavi } from '../services/hooks/useNavi';
import CitiesTabs from '../Components/DetailPage/Tab/CitiesTabs';

const CitiesPage = () => {
  const { clearNavigate } = useNavi();
  useEffect(() => {
    clearNavigate();
  }, []);

  return (
    <div className={'main-page'}>
      <CitiesTabs />
    </div>
  );
};

export default CitiesPage;
