export const nameTo2Chars = (userName) => {
  if (userName) {
    const userNameReplace = userName.split(/[.\-=/_ ]/);
    let userIndex = '';
    if (userNameReplace.length >= 2)
      userIndex = userNameReplace[0].substring(0, 1) + userNameReplace[1].substring(0, 1);
    else userIndex = userNameReplace[0].substring(0, 2);
    return userIndex.toUpperCase();
  }
  return 'NN'
};
