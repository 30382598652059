import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import {
  clearCity,
  getCity,
  setCityCode,
  setCityCountry,
  setCityHeaderPhoto,
  setCityHeaderPhotoFile,
  setCityLoaded,
  setCityOtherNames,
  setCitySearchField,
  setCityTimezone
} from '../../../../redux/reducers/city';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import Loader from '../../../Loader';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import { Api } from '../../../../services/Api';
import { COMPONENT_VARIANT, EVENT_CITY, EVENT_COUNTRY } from '../../../../constants/const';
import CardChoice from '../../../DetailComponent/CardChose';
import CardDetail from '../../../DetailComponent/CardDetail';
import InputDetail from '../../../DetailComponent/InputDetail';
import ImageLoadDetail from '../../../ImageComponent';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import { useNavi } from '../../../../services/hooks/useNavi';
import { ROUTE_COUNTRY } from '../../../../constants/routes';


const CitiesDetail = (props) => {
  const city = useSelector((state) => state.city);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pushRoute } = useNavi();
  const [dataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    setDataChanged(false);
    dispatch(setCityLoaded(false));
    if (props.id === 'new') {
      dispatch(clearCity());
      dispatch(setCityLoaded(true));
    }
    if ((!city.id || parseInt(props.id) !== city.id) && props.id !== 'new')
      dispatch(getCity(parseInt(props.id)));
    else {
      dispatch(setCityLoaded(true));
    }
  }, [props.id]);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(city.otherNames.default)) return false;
    return true;
  };

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [validateCode, setValidateCode] = useState(false);
  const validate = () => {
    setValidateCode(false);

    let flag = true;
    //if (!city.cityCode) {
    //  setValidateCode(true);
    //  flag = false;
    //}

    return flag;
  };

  const updateCity = () => {
    let multipart = { ...city };
    const formData = MultipartWrapper.City(multipart);
    if (validate() && !isLoading) {
      setIsLoading(true);
      if (!city.id)
        Api.createCity(formData).then((res) => {
          if (res.id) navigate('/' + EVENT_CITY + '/' + res.id);
          setDataChanged(false);
          setResponse(res);
          setIsLoading(false);
          if (city.headerPhotoFile) {
            Api.loadCityImage(res.id, city.headerPhotoFile).then((res) => {
              console.log(res);
            });
          }
        });
      else {
        Api.updateCity(city.id, formData).then((res) => {
          setDataChanged(false);
          setResponse(res);
          setIsLoading(false);
        });
        if (city.headerPhotoFile) {
          Api.loadCityImage(city.id, city.headerPhotoFile).then((res) => {
            console.log(res);
          });
        }
      }
    }
  };

  //const [value, setValue] = React.useState('**Hello world!!!**');

  return (
    <Loader
      loading={!city.loaded}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="city-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={city.cityCode} />
          </BlockDetail>
        )}
        <div className="city-detail-content">
          <HeaderDetail
            name={city.cityCode}
            isValidate={checkValidate()}
            requestUpdate={updateCity}
            requestDelete={() => {
              Api.deleteCity(city.id);
            }}
            loading={isLoading}
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <ImageLoadDetail
              header={'Cities photo'}
              defaultImage={city.headerPhoto}
              imageDetail={city.headerPhotoFile}
              setImageDetail={(value) => {
                setDataChanged(true);
                if (typeof value === 'object') dispatch(setCityHeaderPhotoFile(value));
                else {
                  dispatch(setCityHeaderPhoto(value));
                  dispatch(setCityHeaderPhotoFile(null));
                }
              }}
              isNecessarily
              isUnsplash
            />
            <InputDetail
              head="Name"
              placeholder={'Moscow'}
              defaultValue={city.otherNames}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setCityOtherNames(value));
              }}
              isNecessarily
              isLanguage
              isInput
            />

            <InputDetail
              head="City code"
              placeholder={'QWE'}
              validate={validateCode}
              defaultValue={city.cityCode}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setCityCode(value));
              }}             
              isInput
            />

            <InputDetail
              head="timezone"
              defaultValue={city.timezone}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setCityTimezone(value));
              }}
              isNecessarily
              isInput
            />

            <InputDetail
              head="Search field"
              defaultValue={city.search_field}
              setField={(value) => {
                dispatch(setCitySearchField(value));
              }}
              isDisabled
              isMultiline
              isInput
            />
          </BlockDetail>
          {city.country ? (
            <CardDetail
              title={city.country.otherNames.default}
              text={city.country.currency}
              currencyId={city.country?.currencyInfo}
              onClickDetail={() => {
                pushRoute(
                  ROUTE_COUNTRY + '/' + city.country.id,
                  city.country.id,
                  city.country.countryCode,
                  dataChanged
                );
              }}
              removeField={() => {
                setDataChanged(true);
                dispatch(setCityCountry(null));
              }}
            />
          ) : (
            <CardChoice
              name={'Add Country'}
              event={EVENT_COUNTRY}
              request={Api.getCountryPage}
              setValue={(value) => {
                setDataChanged(true);
                dispatch(setCityCountry(value));
              }}
            />
          )}

          {/*<div className="container" data-color-mode="light">
            <MDEditor value={value} onChange={setValue} height={600}/>
          </div>*/}
        </div>
      </div>
    </Loader>
  );
};

export default CitiesDetail;
