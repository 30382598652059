import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAirport, setAirportCountry } from '../../../../redux/reducers/airport';
import { Api } from '../../../../services/Api';
import Loader from '../../../Loader';
import { COMPONENT_VARIANT, EVENT_AIRPORT, EVENT_COUNTRY } from '../../../../constants/const';
import TableSelectDetail from '../../../DetailComponent/TableSelectDetail';

const AirportSelectCountry = (props) => {
  const stateElement = useSelector((state) => state.airport);
  const [selectedElement, setSelectedElement] = useState(stateElement.country);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!stateElement.id || props.id !== stateElement.id) && props.id !== 'new')
      dispatch(getAirport(parseInt(props.id)));
  }, [props.id]);

  useEffect(() => {
    if (stateElement.country && selectedElement === null) setSelectedElement(stateElement.country);
  }, [stateElement]);

  const saveData = (selectElement) => {
    dispatch(setAirportCountry(selectElement));
  };

  const onSelect = (element) => {
    setSelectedElement(element);
  };

  const onDelete = () => {
    setSelectedElement(null);
  };
  return (
    <Loader loading={!stateElement.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <TableSelectDetail
        title={'Country'}
        description={'Select country companies to add to this airport and click Save.'}
        titleCard={'otherNames'}
        saveData={saveData}
        request={Api.getCountryPage}
        event={EVENT_COUNTRY}
        navigateEvent={EVENT_AIRPORT}
        selectedElement={selectedElement}
        onSelect={onSelect}
        onDelete={onDelete}
        isMultiLanguage
        isOnlySelect
      />
    </Loader>
  );
};

export default AirportSelectCountry;
