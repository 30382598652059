import { Api } from '../../services/Api';
import { appInitialState } from './initialStates';

const SET_CITY_PAGE = 'SET_CITY_PAGE';
const SET_PROFILE = 'SET_PROFILE';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_MAP_CATEGORIES = 'SET_MAP_CATEGORIES';
const SET_RESPONSIBLE = 'SET_RESPONSIBLE';

const initialState = appInitialState;

export const getCitesPage = (page) => {
  return (dispatch) => Api.getCitesPage(page).then((res) => dispatch(setCitesPage(res)));
};
export const getLanguage = () => {
  return (dispatch) => Api.getLanguages().then((res) => dispatch(setLanguage(res)));
};
export const getResponsible = () => {
  return (dispatch) =>
    Api.getResponsible().then((res) => {
      const responsible = res.results.map((value) => value.deviceId);
      dispatch(setResponsible(responsible));
    });
};
export const getMapCategories = () => {
  return (dispatch) =>
    Api.getMapCategories().then((res) => dispatch(setMapCategories(res.results)));
};
export const getProfile = (login, password) => {
  return (dispatch) => Api.getProfile(login, password).then((res) => dispatch(setProfile(res)));
};

export const setResponsible = (responsible) => {
  return { type: SET_RESPONSIBLE, payload: responsible };
};
export const setMapCategories = (categories) => {
  return { type: SET_MAP_CATEGORIES, payload: categories };
};
export const setCitesPage = (city) => {
  return { type: SET_CITY_PAGE, payload: city };
};
export const setLanguage = (language) => {
  return { type: SET_LANGUAGE, payload: language };
};
export const setProfile = (profile) => {
  return { type: SET_PROFILE, payload: profile };
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_CATEGORIES:
      return { ...state, mapCategories: action.payload };
    case SET_CITY_PAGE:
      return { ...state, citesPage: action.payload };
    case SET_LANGUAGE:
      return { ...state, language: action.payload };
    case SET_PROFILE:
      return { ...state, profile: action.payload };
    case SET_RESPONSIBLE:
      return { ...state, responsible: action.payload };
    default:
      return state;
  }
};

export default app;
