const AirportMaps = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3179 2.46744L6.00681 0.3119C5.896 0.255656 5.76616 0.235685 5.63611 0.263263C5.62837 0.264899 5.62066 0.266699 5.61298 0.268663L1.11602 1.3929C0.865609 1.45551 0.689941 1.6805 0.689941 1.93861V12.0636C0.689941 12.2368 0.769744 12.4004 0.906272 12.507C1.0428 12.6136 1.22083 12.6513 1.38887 12.6093L5.687 11.5348L9.99821 13.6904C10.1084 13.7463 10.2373 13.7663 10.3666 13.7394C10.3752 13.7376 10.3837 13.7357 10.3922 13.7335L14.8889 12.6093C15.1393 12.5467 15.3149 12.3217 15.3149 12.0636V1.93861C15.3149 1.7654 15.2351 1.60184 15.0986 1.49524C14.9621 1.38865 14.7841 1.35089 14.616 1.3929L10.3179 2.46744ZM10.8149 3.5028V12.4682L14.1899 11.6244V2.65905L10.8149 3.5028ZM5.18994 1.53405V10.4994L1.81494 11.3432V2.3778L5.18994 1.53405ZM6.31494 1.72375V10.591L9.68994 12.2785V3.41125L6.31494 1.72375Z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default AirportMaps;
