const Airlines = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_590_13939)">
        <rect width="18" height="18" fill="white" fillOpacity="0.01" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.39429 14.3649C-0.673142 15.4324 0.778564 16.4571 0.778564 16.4571H11.8149L17.5882 2.51953L18.0024 1.51953H16.92H13.02C11.8224 1.51953 10.688 2.05588 9.92703 2.9806C7.30335 6.16864 1.22773 13.5315 0.39429 14.3649ZM9.1549 15.4571H11.1468L16.5058 2.51953H16.3999L9.1549 15.4571ZM7.88335 15.4571H1.16171C1.11779 15.4074 1.07804 15.3552 1.04777 15.3038C1.01304 15.2448 1.00492 15.2116 1.00303 15.2005C1.01041 15.1821 1.03403 15.1394 1.1014 15.072C1.56014 14.6133 3.33016 12.5109 5.25783 10.1956C7.21072 7.84997 9.38697 5.2105 10.6992 3.61605C11.2705 2.9218 12.1218 2.51953 13.02 2.51953H15.2264L7.88335 15.4571ZM1.00016 15.211C1.0005 15.211 1.00146 15.2083 1.00206 15.203C1.0001 15.2084 0.999811 15.2111 1.00016 15.211ZM1.36188 15.6448C1.36306 15.6457 1.36318 15.6458 1.36212 15.645L1.36188 15.6448Z"
          fill="#0D6EFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_590_13939">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Airlines;
