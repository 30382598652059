import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { DESCRIPTION_PAGE, EVENT_AIRPORT_MAP } from '../../../../constants/const';
import { Api } from '../../../../services/Api';

const AirportMapTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={DESCRIPTION_PAGE.airportMap}
        event={EVENT_AIRPORT_MAP}
        request={Api.getAirportMapPage}
        isImage
        isDetail
        disabledCreate
      />
    </div>
  );
};

export default AirportMapTabs;
