import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import './style.scss';
import classNames from 'classnames';
import { FILTER_FIELD } from '../../../constants/searchElement';
import { COMPONENT_VARIANT, BASE_ITEMS_PAGE } from '../../../constants/const';
import BlockDetail from '../BlockDetail';
import BreadcrumbNavigate from '../../Breadcrumbs';
import Loader from '../../Loader';
import TableSelect from '../../../assets/image/tableSelect.svg';
import TableUnSelect from '../../../assets/image/tableUnSelect.svg';
import PaginationTable from '../../Pagination';
import { useNavi } from '../../../services/hooks/useNavi';
import SortFilterNawBar from '../../NavBar/SortFilterNawBar';
import TableSelectModalEvents from './TableSelectModalEvents';

const TableSelectDetail = (props) => {
  const [tabElement, setTabElement] = useState(null);

  const [isLoad, setIsLoad] = useState(false);
  const [maxCard, setMaxCards] = useState(0);

  const [pageSearch, setPageSearch] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [pageElementCount, setPageElementCount] = useState(BASE_ITEMS_PAGE);

  const [activeFilter, setActiveFilter] = useState(FILTER_FIELD[props.event]);
  const [activeFilterBoolean, setActiveFilterBoolean] = useState(null);
  const [activeSort, setActiveSort] = useState(null);

  const navigate = useNavi();

  const setFilerField = (field, value) => {
    const newFiler = { ...activeFilter };
    newFiler[field] = value;
    setActiveFilter(newFiler);
  };
  const clearSearchParams = () => {
    setActiveFilter(FILTER_FIELD[props.event]);
    setActiveFilterBoolean(null);
    setActiveSort(null);
    setPageSearch('');
    getElementTable(1, null, true);
  };
  const setSortField = (field) => {
    setActiveSort(field);
  };
  const getElementTable = (page, searchValue = null, clearSearchParams = false) => {
    setIsLoad(true);
    setActivePage(page);
    const searchRequest = searchValue ?? pageSearch;
    if (searchValue) setPageSearch(searchValue);
    if (clearSearchParams) {
      props.request(page, pageElementCount, '', FILTER_FIELD[props.event], '', null).then((res) => {
        setTabElement(res.results);
        setMaxCards(res.count);
        setIsLoad(false);
      });
    } else {
      props
        .request(
          page,
          pageElementCount,
          searchRequest,
          activeFilter,
          activeSort,
          activeFilterBoolean
        )
        .then((res) => {
          setTabElement(res.results);
          setMaxCards(res.count);
          setIsLoad(false);
        });
    }
  };
  useEffect(() => {
    if (!tabElement) {
      getElementTable(1);
    }
  }, []);

  return (
    <div className="table-detail-choice">
      <BlockDetail>
        <div className="nav-bar-content">
          <BreadcrumbNavigate name={'Add ' + props.title} />
          <SortFilterNawBar
            name={props.title}
            event={props.event}
            setFilerField={setFilerField}
            setSortField={setSortField}
            setFilterBoolean={setActiveFilterBoolean}
            setPageSearch={setPageSearch}
            searchPage={pageSearch}
            activeFilter={activeFilter}
            activeSort={activeSort}
            activeFilterBoolean={activeFilterBoolean}
            clearSearchParams={clearSearchParams}
            getData={getElementTable}
          />
        </div>
      </BlockDetail>
      <div className="table-context">
        <Loader loading={isLoad} variant={COMPONENT_VARIANT.dark} withMargin={100}>
          <BlockDetail>
            <div className="table-detail-context">
              <div className="add-header">
                <div className="header">{props.title}</div>
                <Button
                  variant={'outline-' + COMPONENT_VARIANT.primary}
                  onClick={() => {
                    props.saveData(props.selectedElement);
                    navigate.goBack(1);
                  }}>
                  Save
                </Button>
                <TableSelectModalEvents
                  name={'Add New ' + props.title}
                  header={'Add New ' + props.title}
                  event={props.event}
                  id={'new'}
                />
              </div>
              <div className="add-description">{props.description}</div>
              <div className="table-choice">
                {tabElement?.map((element, index) => {
                  let findSelectedElement = null;
                  if (props.isOnlySelect && props.selectedElement?.id === element.id)
                    findSelectedElement = props.selectedElement;
                  if (!props.isOnlySelect)
                    findSelectedElement = props.selectedElement.find(
                      (val) => val.id === element.id
                    );
                  return (
                    <div
                      className={classNames({
                        'table-context': true,
                        'table-gray': index % 2 === 0
                      })}
                      key={index}>
                      <div
                        className="name-context"
                        onClick={() => {
                          if (findSelectedElement) props.onDelete(element);
                          else props.onSelect(element);
                        }}>
                        <div className="name-icon">
                          {findSelectedElement ? (
                            <img src={TableSelect} alt="del" />
                          ) : (
                            <img src={TableUnSelect} alt="ic" />
                          )}
                        </div>
                        <div className="name-title">
                          {props.isMultiLanguage
                            ? element[props.titleCard].default ?? element[props.titleCard].en 
                            : element[props.titleCard]}
                        </div>
                      </div>
                      <div className="button-context">
                        <div className="selected-button">
                          {findSelectedElement ? (
                            <Button
                              variant={COMPONENT_VARIANT.link}
                              className="button-delete"
                              onClick={() => {
                                props.onDelete(element);
                              }}>
                              Remove
                            </Button>
                          ) : (
                            <Button
                              variant={COMPONENT_VARIANT.link}
                              className="button-select"
                              onClick={() => {
                                props.onSelect(element);
                              }}>
                              Select
                            </Button>
                          )}
                        </div>
                        <div className="divider"></div>
                        <div className="detail-button">
                          <TableSelectModalEvents
                            name={'Details'}
                            header={props.title + ' detail'}
                            className={'modal-button'}
                            event={props.event}
                            id={element.id}
                            buttonVariant={COMPONENT_VARIANT.link}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </BlockDetail>
        </Loader>
        <PaginationTable
          itemPage={pageElementCount}
          setItemPage={setPageElementCount}
          allItemCount={maxCard}
          request={getElementTable}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>
    </div>
  );
};

export default TableSelectDetail;
