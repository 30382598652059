import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../../../services/Api';
import {
  COMPONENT_VARIANT,
  DESCRIPTION_PAGE,
  EVENT_TAXIS,
  TABLE_EVENT_AIRPORTS,
  UPDATE_DATA_CHANGE
} from '../../../../constants/const';
import {
  getTaxiAirport,
  getTaxi,
  setTaxiAppStoreUrl,
  setTaxiGoogleStoreURL,
  setTaxiLoaded,
  setTaxiOrder,
  setTaxiTitle,
  setTaxiUrlSchema,
  setTaxiWebUrl,
  setTaxisAirport,
  clearTaxi,
  setTaxisRemoveAirport,
  setTaxisAddAirport,
  setSquareImageFile
} from '../../../../redux/reducers/taxis';
import InputDetail from '../../../DetailComponent/InputDetail';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import './style.scss';
import { useNavi } from '../../../../services/hooks/useNavi';
import { ROUTE_AIRPORT, ROUTE_TAXIS } from '../../../../constants/routes';
import TableChoiceDetail from '../../../DetailComponent/TableChoiceDetail';
import ImageLoadDetail from '../../../ImageComponent';

const TaxisDetail = (props) => {
  const taxis = useSelector((state) => state.taxis);
  const { pushRoute } = useNavi();
  const [dataChanged, setDataChanged] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setDataChanged(false);
    dispatch(setTaxiLoaded(false));
    if (props.id === 'new') {
      dispatch(clearTaxi());
      dispatch(setTaxiLoaded(true));
    }
    if ((!taxis.id || parseInt(props.id) !== taxis.id) && props.id !== 'new') {
      dispatch(getTaxi(props.id));
      if (taxis.airportTaxi.length === 0) dispatch(getTaxiAirport(parseInt(props.id)));
    } else {
      dispatch(setTaxiLoaded(true));
    }
  }, [props.id]);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(taxis.title)) return false;
    return true;
  };

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [validateTitle, setValidateTitle] = useState(false);
  const validate = () => {
    setValidateTitle(false);

    let flag = true;
    if (!taxis.title) {
      setValidateTitle(true);
      flag = false;
    }

    return flag;
  };
  const updateTaxis = () => {
    let multipart = { ...taxis };
    const formData = MultipartWrapper.Taxis(multipart);
    if (validate() && !isLoading) {
      setIsLoading(true);
      if (!taxis.id)
        Api.createTaxi(formData).then((res) => {
          if (res.id) pushRoute('/' + EVENT_TAXIS + '/' + res.id, res.id, res.title);
          setDataChanged(false);
          setResponse(res);
          setIsLoading(false);
        });
      else {
        Api.updateTaxi(taxis.id, formData).then((res) => {
          setResponse(res);
          setDataChanged(false);
          setIsLoading(false);
        });
      }
    }
    if (taxis.addAirportTaxi.length > 0) {
      Api.updateTaxiAirportId(taxis.id, getIdAirportTaxi(taxis.addAirportTaxi));
    }
    if (taxis.removeAirportTaxi.length > 0) {
      Api.deleteTaxiAirportId(taxis.id, getIdAirportTaxi(taxis.removeAirportTaxi));
    }
    dispatch(setTaxisAirport(getFullMassAirportTaxi()));
    dispatch(setTaxisAddAirport([]));
    dispatch(setTaxisRemoveAirport([]));
  };

  const getIdAirportTaxi = (masAirport) => {
    return masAirport.map((value) => value.id);
  };

  const getFullMassAirportTaxi = () => {
    const returnAirport = [...taxis.addAirportTaxi];
    taxis.airportTaxi.map((value) => {
      const findRemoveAirport = taxis.removeAirportTaxi.find((el) => el.id === value.id);
      if (!findRemoveAirport) returnAirport.push(value);
    });
    return returnAirport;
  };

  const checkAddAirport = (selectedElement, type) => {
    const findAirportTaxi = taxis.airportTaxi.find((value) => value.id === selectedElement.id);
    if (type === UPDATE_DATA_CHANGE.update) {
      if (!findAirportTaxi) {
        const newAddAirport = [...taxis.addAirportTaxi];
        newAddAirport.push(selectedElement);
        dispatch(setTaxisAddAirport(newAddAirport));
      }
    } else {
      const findRemoveAddTaxi = taxis.addAirportTaxi.find(
        (value) => value.id === selectedElement.id
      );
      if (findRemoveAddTaxi) {
        const newAddAirport = taxis.addAirportTaxi.filter(
          (value) => value.id !== selectedElement.id
        );
        dispatch(setTaxisAddAirport(newAddAirport));
      }
      if (findAirportTaxi) {
        const newMassRemove = [...taxis.removeAirportTaxi];
        newMassRemove.push(selectedElement);
        dispatch(setTaxisRemoveAirport(newMassRemove));
      }
    }
  };

  return (
    <Loader
      loading={!taxis.loaded}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="taxi-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={taxis.title} />
          </BlockDetail>
        )}
        <div className="taxi-detail-content">
          <HeaderDetail
            name={taxis.title}
            isValidate={checkValidate()}
            requestUpdate={updateTaxis}
            requestDelete={() => {
              Api.deleteTaxi(taxis.id);
            }}
            loading={isLoading}
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="taxi-detail-data-context">
              <div className="taxi-detail-data">
                <InputDetail
                  head="title"
                  placeholder={'taxi name'}
                  validate={validateTitle}
                  defaultValue={taxis.title}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setTaxiTitle(value));
                  }}
                  isNecessarily
                  isInput
                />
                <InputDetail
                  head="Order"
                  defaultValue={taxis.order}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setTaxiOrder(value));
                  }}
                  isDisabled
                  isInput
                />
                <InputDetail
                  head="Web Url"
                  placeholder={'example.com'}
                  defaultValue={taxis.webUrl}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setTaxiWebUrl(value));
                  }}
                  isWebsite
                  isInput
                />
                <InputDetail
                  head="App Store Url"
                  placeholder={'example.com'}
                  defaultValue={taxis.appStoreUrl}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setTaxiAppStoreUrl(value));
                  }}
                  isWebsite
                  isInput
                />
                <InputDetail
                  head="Google Store Url"
                  placeholder={'example.com'}
                  defaultValue={taxis.googleStoreUrl}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setTaxiGoogleStoreURL(value));
                  }}
                  isWebsite
                  isInput
                />
                <InputDetail
                  head="Url Schema"
                  placeholder={'example.com'}
                  defaultValue={taxis.urlSchema}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setTaxiUrlSchema(value));
                  }}
                  isInput
                />
              </div>
              <div className="taxi-image-context">
                <ImageLoadDetail
                  header={"Taxi logo"}
                  defaultImage={taxis.squareImage}
                  imageDetail={taxis.squareImageFile}
                  setImageDetail={(value) => {
                    setDataChanged(true);
                    dispatch(setSquareImageFile(value));
                  }}
                  isNecessarily
                />
              </div>
            </div>
          </BlockDetail>
          <BlockDetail>
            <TableChoiceDetail
              name={DESCRIPTION_PAGE.airport}
              description={'Linked Airports'}
              buttonContext={'Add Airport'}
              noChangeDescription={
                taxis.id
                  ? 'No selected airport'
                  : 'To add airports, you need to save the taxi service'
              }
              title={'otherNames'}
              detail={getFullMassAirportTaxi()}
              onChangeData={(value, type) => {
                checkAddAirport(value, type);
              }}
              onNavigate={() => {
                pushRoute(
                  ROUTE_TAXIS + '/' + TABLE_EVENT_AIRPORTS + '/' + taxis.id,
                  taxis.id,
                  'Add airport'
                );
              }}
              onClickDetail={(value) => {
                pushRoute(ROUTE_AIRPORT + '/' + value.id, value.id, value.iataCode, dataChanged);
              }}
              isLocalization
            />
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default TaxisDetail;
