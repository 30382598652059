import React from 'react';
import { useParams } from 'react-router-dom';
import RentalDetail from '../../Components/DetailPage/Detail/RentalDetail';

const DetailRentalPage = () => {
  const { id } = useParams();

  return <RentalDetail detailId={id} />;
};

export default DetailRentalPage;
