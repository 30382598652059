import React from 'react';
import PushEvents from '../../../PushEvents';

const PushEventsTabs = () => {
  return (
    <div className="tabs-control">
      <PushEvents />
    </div>
  );
};

export default PushEventsTabs;
