import React, { useEffect, useState } from 'react';
import Logo from '../../assets/image/Logotype.svg';
import { Button, CloseButton, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  COMPONENT_VARIANT,
  DIVIDER_ELEMENT,
  EVENT_PUSH,
  LANGUAGE_DEFAULT,
  STORAGE_LANGUAGE,
  STORAGE_USER_NAME,
  TABLET_SCREEN_SIZE
} from '../../constants/const';

import { clearStorage, logOutStorage } from '../../services/storage';
import './index.scss';
import classNames from 'classnames';
import OpenButton from '../svg/OpenButton';
import Localization from '../svg/Localization';
import BlockDetail from '../DetailComponent/BlockDetail';
import { TAB_CONTROLLER_ELEMENT } from '../../constants/tabControllerElements';
import LogOut from '../svg/LogOut';
import { ROUTE_AUTHORIZE } from '../../constants/routes';
import { nameTo2Chars } from '../../services/nameTo2Chars';
import { useSelector } from 'react-redux';

const Tabs = (props) => {
  const getActiveLanguage = () => {
    const language = localStorage.getItem(STORAGE_LANGUAGE);
    if (!language) {
      localStorage.setItem(STORAGE_LANGUAGE, LANGUAGE_DEFAULT);
      return LANGUAGE_DEFAULT;
    }
    return language;
  };
  const [isClose, setIsClose] = useState(false);
  const [activeLanguage, setLanguage] = useState(getActiveLanguage());
  const language = useSelector((state) => state.app.language);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(window.location.pathname.replace('/', ''));
  const setActiveLanguage = (value) => {
    setLanguage(value);
    localStorage.setItem(STORAGE_LANGUAGE, value);
  };

  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth;
      if (width < TABLET_SCREEN_SIZE) setIsClose(true);
      else setIsClose(false);
    };
    onResize();

    const match = window.matchMedia(`(max-width: ${TABLET_SCREEN_SIZE}px)`);
    match.onchange = onResize;
    return () => {
      match.onchange = () => {};
    };
  }, []);

  return (
    <div className={'tab-controller'}>
      <div className={classNames({ 'tab-context': true, close: isClose })}>
        <div className={'tab-elements-data'}>
          {isClose ? (
            <div className="logotype-context" style={{ justifyContent: 'center' }}>
              <button
                className="open-button"
                onClick={() => {
                  setIsClose(false);
                }}>
                <OpenButton></OpenButton>
              </button>
            </div>
          ) : (
            <div className="logotype-context">
              <img src={Logo} className="logo"></img>
              <CloseButton
                className="close-button"
                onClick={() => {
                  setIsClose(true);
                }}
              />
            </div>
          )}
          <div className={'tab-element'}>
            <BlockDetail>
              {TAB_CONTROLLER_ELEMENT.map((value, index) => {
                if (value.eventKey === DIVIDER_ELEMENT)
                  return <div key={index} className="divider"></div>;
                return (
                  <Button
                    key={index}
                    variant={activeTab === value.eventKey ? 'primary' : 'outline-primary'}
                    className={classNames({
                      'element-context': true,
                      'active-link': activeTab === value.eventKey,
                      'center-context': isClose
                    })}
                    onClick={() => {
                      setActiveTab(value.eventKey);
                      if (value.eventKey === EVENT_PUSH) navigate('/' + value.eventKey);
                      else navigate('/' + value.eventKey + '?page=1');
                      clearStorage();
                    }}>
                    {value.icon}
                    {!isClose && value.title}
                  </Button>
                );
              })}
            </BlockDetail>
            <Dropdown>
              <Dropdown.Toggle variant="outline-primary" className="element-context">
                <Localization />
                {!isClose && 'Localization: ' + activeLanguage}
              </Dropdown.Toggle>
              <Dropdown.Menu className={classNames({ 'localization-dropdown-menu': isClose })}>
                {language?.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setActiveLanguage(value.code);
                      }}
                      disabled={activeLanguage === value.code}>
                      {value.code}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div
          className={classNames({
            'log-out': true,
            'center-context': isClose
          })}>
          <div className="language-description">
            {nameTo2Chars(localStorage.getItem(STORAGE_USER_NAME))}
          </div>
          {!isClose && (
            <Button
              className="log-out-button"
              variant={'outline-' + COMPONENT_VARIANT.primary}
              onClick={() => {
                logOutStorage();
                navigate(ROUTE_AUTHORIZE);
                window.location.reload();
              }}>
              <LogOut></LogOut>
            </Button>
          )}
        </div>
      </div>
      <div className={isClose ? 'fake-tabs-context-close' : 'fake-tabs-context'}></div>
      <div className="context">{props.children}</div>
    </div>
  );
};

export default Tabs;
