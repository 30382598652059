import React from 'react';
import { useParams } from 'react-router-dom';

import AirportMapDetail from '../../Components/DetailPage/Detail/AirportMapDetail';

const DetailAirportMapPage = () => {
  const { id } = useParams();

  return <AirportMapDetail id={id} />;
};

export default DetailAirportMapPage;
