const BackArrow = () => {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1246 3.99909C13.1246 3.88306 13.0785 3.77178 12.9965 3.68973C12.9144 3.60768 12.8032 3.56159 12.6871 3.56159H2.36825L5.12187 0.808838C5.16255 0.768161 5.19482 0.71987 5.21683 0.666723C5.23884 0.613576 5.25017 0.556614 5.25017 0.499088C5.25017 0.441562 5.23884 0.384599 5.21683 0.331452C5.19482 0.278305 5.16255 0.230015 5.12187 0.189338C5.0812 0.148661 5.03291 0.116394 4.97976 0.09438C4.92661 0.0723658 4.86965 0.0610352 4.81212 0.0610352C4.7546 0.0610352 4.69763 0.0723658 4.64449 0.09438C4.59134 0.116394 4.54305 0.148661 4.50237 0.189338L1.00237 3.68934C0.96163 3.72998 0.929305 3.77826 0.907249 3.83141C0.885193 3.88456 0.87384 3.94154 0.87384 3.99909C0.87384 4.05663 0.885193 4.11361 0.907249 4.16677C0.929305 4.21992 0.96163 4.2682 1.00237 4.30884L4.50237 7.80884C4.54305 7.84951 4.59134 7.88178 4.64449 7.9038C4.69763 7.92581 4.7546 7.93714 4.81212 7.93714C4.86965 7.93714 4.92661 7.92581 4.97976 7.9038C5.03291 7.88178 5.0812 7.84951 5.12187 7.80884C5.16255 7.76816 5.19482 7.71987 5.21683 7.66672C5.23884 7.61358 5.25017 7.55661 5.25017 7.49909C5.25017 7.44156 5.23884 7.3846 5.21683 7.33145C5.19482 7.27831 5.16255 7.23001 5.12187 7.18934L2.36825 4.43659H12.6871C12.8032 4.43659 12.9144 4.39049 12.9965 4.30845C13.0785 4.2264 13.1246 4.11512 13.1246 3.99909Z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default BackArrow;
