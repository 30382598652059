const Transport = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00244 0.875977C2.68744 0.875977 0.00244141 1.25098 0.00244141 3.87598V11.376C0.00244141 12.036 0.294941 12.6285 0.752441 13.041V14.376C0.752441 14.7885 1.08994 15.126 1.50244 15.126H2.25244C2.66494 15.126 3.00244 14.7885 3.00244 14.376V13.626H9.00244V14.376C9.00244 14.7885 9.33994 15.126 9.75244 15.126H10.5024C10.9149 15.126 11.2524 14.7885 11.2524 14.376V13.041C11.7099 12.6285 12.0024 12.036 12.0024 11.376V3.87598C12.0024 1.25098 9.31744 0.875977 6.00244 0.875977ZM10.2474 3.11848H1.75744C2.16994 2.72098 3.23494 2.37598 6.00244 2.37598C8.76994 2.37598 9.83494 2.72098 10.2474 3.11848ZM10.5024 4.61848V6.87598H1.50244V4.61848H10.5024ZM10.2474 11.9235L10.0299 12.126H1.97494L1.75744 11.9235C1.65994 11.841 1.50244 11.6535 1.50244 11.376V8.37598H10.5024V11.376C10.5024 11.6535 10.3449 11.841 10.2474 11.9235Z"
        fill="#0D6EFD"
      />
      <path
        d="M3.37744 11.376C3.99876 11.376 4.50244 10.8723 4.50244 10.251C4.50244 9.62966 3.99876 9.12598 3.37744 9.12598C2.75612 9.12598 2.25244 9.62966 2.25244 10.251C2.25244 10.8723 2.75612 11.376 3.37744 11.376Z"
        fill="#0D6EFD"
      />
      <path
        d="M8.62744 11.376C9.24876 11.376 9.75244 10.8723 9.75244 10.251C9.75244 9.62966 9.24876 9.12598 8.62744 9.12598C8.00612 9.12598 7.50244 9.62966 7.50244 10.251C7.50244 10.8723 8.00612 11.376 8.62744 11.376Z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default Transport;
