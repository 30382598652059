import React, { useEffect } from 'react';
import AirportMapTabs from '../Components/DetailPage/Tab/AirportMapTabs';
import { useNavi } from '../services/hooks/useNavi';

const AirportsMapsPage = () => {
  const { clearNavigate } = useNavi()
  useEffect(() => {
    clearNavigate()
  }, []);

  return (
    <div className={'main-page'}>
          <AirportMapTabs />
    </div>
  );
};

export default AirportsMapsPage;
