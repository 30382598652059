import React from 'react';
import { Button, Table } from 'react-bootstrap';
import './style.scss';
import {
  COMPONENT_VARIANT,
  MAPS_TYPE_ORDERING,
  UPDATE_DATA_CHANGE
} from '../../../constants/const';

const TableChoiceDetail = (props) => {
  return (
    <div className="table-choice-detail">
      <div className="header-context">
        <div className="header-table">{props.name}</div>
        <Button
          className="table-button"
          variant={COMPONENT_VARIANT.primary}
          onClick={() => {
            props.onNavigate();
          }}>
          {props.buttonContext}
        </Button>
      </div>
      <div className="table-description">
        {props.description && <div className="table-modal-description">{props.description}</div>}
      </div>
      <div className="table-context">
        {props.detail?.length === 0 ? (
          <div className="no-change-table">{props.noChangeDescription}</div>
        ) : (
          <Table striped bordered hover variant="light" className="table">
            <tbody>
              {props.detail?.map((element, index) => (
                <tr key={index}>
                  <td>
                    {props.isLocalization ? element[props.title].default : element[props.title]}
                  </td>
                  <td>
                    <Button
                      variant="link"
                      className="button-detail"
                      onClick={() => {
                        props.onClickDetail(element);
                      }}>
                      Detail
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="link"
                      className="button-delete"
                      onClick={() => {
                        props.onChangeData(element, UPDATE_DATA_CHANGE.delete);
                      }}>
                      Delete
                    </Button>
                  </td>
                  {props.isSelextPosition && props.onChangeOrdering && (
                    <td>
                      <div className="button-order-content">
                        <Button
                          className="button-ordering"
                          variant={COMPONENT_VARIANT.link}
                          disabled={index === 0}
                          onClick={() => {
                            props.onChangeOrdering(element.id, MAPS_TYPE_ORDERING.up);
                          }}>
                          ↑
                        </Button>
                        <Button
                          className="button-ordering"
                          variant={COMPONENT_VARIANT.link}
                          disabled={index === props.detail.length - 1}
                          onClick={() => {
                            props.onChangeOrdering(element.id, MAPS_TYPE_ORDERING.down);
                          }}>
                          ↓
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default TableChoiceDetail;
