import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import BackArrow from '../svg/Back';
import { useParams } from 'react-router-dom';
import { useNavi } from '../../services/hooks/useNavi';
import './style.scss';

const BreadcrumbNavigate = (props) => {
  const { allRoutes, goBack, setRoute } = useNavi();
  const [currentRoute, setCurrentRoute] = useState([]);
  const currentPatch = window.location.pathname;
  const { id } = useParams();

  useState(() => {
    let newAllRoute = [];
    const findRoute = allRoutes.find((value) => value.route === currentPatch);
    const backEvent = currentPatch.split('/')[1];
    if (findRoute) {
      if (allRoutes.length === 1)
        newAllRoute.push({ route: '/' + backEvent, id: null, name: backEvent });
      for (let index = 0; index < allRoutes.length; index++) {
        const element = allRoutes[index];
        if (element.route === currentPatch) {
          newAllRoute.push(element);
          break;
        } else newAllRoute.push(element);
      }
    } else {
      if (allRoutes.length <= 1)
        newAllRoute.push({ route: '/' + backEvent, id: null, name: backEvent });
      else {
        newAllRoute = [...allRoutes];
      }
      const name = props.name ?? currentPatch.split('/')[1];
      newAllRoute.push({ route: currentPatch, id: id, name: name });
    }
    setRoute(newAllRoute);
    setCurrentRoute(newAllRoute);
  }, []);

  const getBackNavigate = () => {
    return (
      <div
        className="breadcrumb-back"
        onClick={() => {
          goBack(1);
        }}>
        <BackArrow className="breadcrumb-image"></BackArrow>
      </div>
    );
  };
  const getNavigate = () => {
    let route = allRoutes;
    if (allRoutes.length === 0) route = currentRoute;
    return route.map((route, index) => (
      <Breadcrumb.Item
        key={route.id + '' + index}
        className="breadcrumb-items"
        //href={activeNavigation[index].navigate}
        onClick={() => {
          goBack(allRoutes.length - index - 1);
        }}>
        {route.name}
      </Breadcrumb.Item>
    ));
  };

  return (
    <div className="breadcrumb-context">
      {getBackNavigate()}
      <Breadcrumb>{getNavigate()}</Breadcrumb>
    </div>
  );
};

export default BreadcrumbNavigate;
