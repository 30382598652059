import React, { useEffect, useState } from 'react';
import {
  COMPONENT_VARIANT,
  DESCRIPTION_PAGE,
  EVENT_TIPS,
  TIPS_STATUS,
  TIPS_STATUS_SHOW,
  TIPS_TAB_ELEMENT
} from '../../constants/const';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './style.scss';
import Loader from '../Loader';
import NavBar from '../NavBar';
import { Q_PARAM_SEARCH } from '../../constants/searchElement';
import BlockDetail from '../DetailComponent/BlockDetail';
import classNames from 'classnames';
import { Api } from '../../services/Api';
import { convertDateTimeFormatYar } from '../../services/convertDateTimeFormat';
import ModalDetail from '../DetailComponent/ModalDetail';
import Like from '../svg/Like';
import Dislike from '../svg/Dislike';
import ModalConfirm from '../ModalConfirm';
import TipsDetailModal from '../DetailPage/Detail/TipsDetailModal';

const TipsPageComponent = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [pageSearch, setPageSearch] = useState(queryParams.get(Q_PARAM_SEARCH.search));
  const [tabActiveElement, setTabActiveElement] = useState(TIPS_TAB_ELEMENT.Requests);

  const [allTips, setAllTips] = useState(null);
  const [reviewedTips, setReviewedTips] = useState(null);

  const clearSearchParams = () => {
    setPageSearch('');
    getData(null);
  };

  const getAllTips = () => {
    Api.getAllTips().then((res) => {
      setAllTips(res.payload);
      const filterTips = res.payload.filter((i) => i.status === 'in_review');
      setReviewedTips(filterTips);
    });
  };

  useEffect(() => {
    if (!allTips) {
      getAllTips();
    }
  }, []);

  const getData = (search) => {
    if (tabActiveElement === TIPS_TAB_ELEMENT.Requests) return search;
    if (tabActiveElement === TIPS_TAB_ELEMENT.AllTips) return search;
    return search;
  };

  const getTipsTabElement = (tabElement) => {
    if (tabElement === null) return '';
    return tabElement?.map((value, index) => {
      return (
        <div
          key={index}
          className={classNames({
            'table-items-context': true,
            'table-color-gray': index % 2 == 0
          })}>
          <div className="table-items t-date">
            <div className="table-item-date">{convertDateTimeFormatYar(value.createdAt)}</div>
          </div>
          <div className="table-items t-iata">
            <div className="table-item-iata">{value.iata}</div>
          </div>
          <div className="table-items t-text">
            <div className="table-item-text">{value.text}</div>
            <div className="table-item-prewImage">
              {value.thumbnails?.map((element, key) => {
                return <img className={'table-item-image'} key={key} src={element} alt="img" />;
              })}
            </div>
            <div className="table-item-raiting">
              <div className="like">
                <Like />
                {value.rating.upvotes}
              </div>
              <div className="dislike">
                <Dislike />
                {value.rating.downvotes}
              </div>
            </div>
          </div>
          <div className="table-items t-category">{value.category}</div>
          <div
            className={classNames({
              'table-items t-status': true,
              'status-approved': value.status === TIPS_STATUS.approved,
              'status-in-review': value.status === TIPS_STATUS.in_review,
              'status-rejected': value.status === TIPS_STATUS.rejected
            })}>
            {TIPS_STATUS_SHOW[value.status]}
          </div>
          <div className="table-items t-action">
            <ModalConfirm
              className="table-items-button button-delete"
              variant={COMPONENT_VARIANT.link}
              name={'delete'}
              header={'Want to delete?'}
              onClick={() => {
                Api.deleteTips(value.id).then(() => {
                  getAllTips();
                });
              }}>
              Are you sure you want to delete this item?
            </ModalConfirm>
          </div>
          <div className="table-items t-action">
            <ModalDetail
              name={'Detail'}
              header={
                <div className="tips-modal-detail-header">
                  <div className="tips-modal-head">Tip review</div>
                  <div className="tips-modal-date">{convertDateTimeFormatYar(value.createdAt)}</div>
                </div>
              }
              className={'table-items-button'}
              buttonVariant={COMPONENT_VARIANT.link}>
              <TipsDetailModal id={value.id} photos={value.thumbnails} isModal isToReview />
            </ModalDetail>
          </div>
        </div>
      );
    });
  };

  const getReviewTipsTabElement = (tabElement) => {
    if (tabElement === null) return '';
    return tabElement?.map((value, index) => {
      return (
        <div
          key={index}
          className={classNames({
            'table-items-context': true,
            'table-color-gray': index % 2 == 0
          })}>
          <div className="table-items t-date">{convertDateTimeFormatYar(value.createdAt)}</div>
          <div className="table-items t-iata">{value.iata}</div>
          <div className="table-items t-text">
            <div className="table-item-text">{value.text}</div>
            <div className="table-item-prewImage">
              {value.thumbnails?.map((element, key) => {
                return <img className={'table-item-image'} key={key} src={element} alt="img" />;
              })}
            </div>
          </div>
          <div className="table-items t-category">{value.category}</div>
          <div className="table-items t-action">
            <ModalDetail
              name={'Detail'}
              header={
                <div className="tips-modal-detail-header">
                  <div className="tips-modal-head">Tip review</div>
                  <div className="tips-modal-date">{convertDateTimeFormatYar(value.createdAt)}</div>
                </div>
              }
              className={'table-items-button'}
              buttonVariant={COMPONENT_VARIANT.link}>
              <TipsDetailModal id={value.id} photos={value.thumbnails} isModal isApproved />
            </ModalDetail>
          </div>
        </div>
      );
    });
  };
  return (
    <Loader loading={!allTips} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <div className="nav-bar-context">
        <NavBar
          name={DESCRIPTION_PAGE.tips}
          event={EVENT_TIPS}
          setPageSearch={setPageSearch}
          searchPage={pageSearch}
          clearSearchParams={clearSearchParams}
          getData={getData}
        />
      </div>
      <div className="tips-page-context">
        <BlockDetail>
          <div className="tips-page">
            <Tabs
              defaultActiveKey={TIPS_TAB_ELEMENT.Requests}
              transition={false}
              id=""
              className="mb-3">
              <Tab
                eventKey={TIPS_TAB_ELEMENT.Requests}
                title={TIPS_TAB_ELEMENT.Requests}
                onClick={() => {
                  setTabActiveElement(TIPS_TAB_ELEMENT.Requests);
                }}>
                <div className="table-push-event">
                  <div className="table-push-thead">
                    <div className="table-push-thead-header t-date">Date</div>
                    <div className="table-push-thead-header t-iata">IATA</div>
                    <div className="table-push-thead-header t-text">Tip Text</div>
                    <div className="table-push-thead-header t-category">Category</div>
                    <div className="table-push-thead-header t-action">Actions</div>
                  </div>
                  <div className="table-push-tbody">{getReviewTipsTabElement(reviewedTips)}</div>
                </div>
              </Tab>
              <Tab
                eventKey={TIPS_TAB_ELEMENT.AllTips}
                title={TIPS_TAB_ELEMENT.AllTips}
                onClick={() => {
                  setTabActiveElement(TIPS_TAB_ELEMENT.AllTips);
                  getAllTips();
                }}>
                <div>
                  <div className="table-push-event">
                    <div className="table-push-thead">
                      <div className="table-push-thead-header t-date">Date</div>
                      <div className="table-push-thead-header t-iata">IATA</div>
                      <div className="table-push-thead-header t-text">Tip Text</div>
                      <div className="table-push-thead-header t-category">Category</div>
                      <div className="table-push-thead-header t-status">Status</div>
                      <div className="table-push-thead-header t-action">Actions</div>
                      <div className="table-push-thead-header t-action">Detail</div>
                    </div>
                    <div className="table-push-tbody">{getTipsTabElement(allTips)}</div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </BlockDetail>
      </div>
    </Loader>
  );
};

export default TipsPageComponent;
