import React from 'react';
import { useParams } from 'react-router-dom';

import AirportDetail from '../../Components/DetailPage/Detail/AirportDetail';

const DetailAirportPage = () => {
  const { id } = useParams();

  return <AirportDetail id={id} />;
};

export default DetailAirportPage;
