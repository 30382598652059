import React from 'react';
import TipsPageComponent from '../../../TipsPageComponent';

const TipsTabs = () => {
  return (
    <div className="tabs-control">
      <TipsPageComponent />
    </div>
  );
};

export default TipsTabs;
