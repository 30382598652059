const UserLogo = () => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.59966 2.99917C3.59966 2.22597 4.22646 1.59917 4.99966 1.59917C5.77286 1.59917 6.39966 2.22597 6.39966 2.99917C6.39966 3.77237 5.77286 4.39917 4.99966 4.39917C4.22646 4.39917 3.59966 3.77237 3.59966 2.99917ZM4.99966 0.39917C3.56372 0.39917 2.39966 1.56323 2.39966 2.99917C2.39966 4.43511 3.56372 5.59917 4.99966 5.59917C6.4356 5.59917 7.59966 4.43511 7.59966 2.99917C7.59966 1.56323 6.4356 0.39917 4.99966 0.39917ZM2.99966 6.39917C2.3101 6.39917 1.64877 6.6731 1.16118 7.16069C0.673586 7.64829 0.399658 8.30961 0.399658 8.99917V9.99917C0.399658 10.3305 0.668287 10.5992 0.999658 10.5992C1.33103 10.5992 1.59966 10.3305 1.59966 9.99917V8.99917C1.59966 8.62787 1.74716 8.27177 2.00971 8.00922C2.27226 7.74667 2.62836 7.59917 2.99966 7.59917H6.99966C7.37096 7.59917 7.72706 7.74667 7.98961 8.00922C8.25216 8.27177 8.39966 8.62787 8.39966 8.99917V9.99917C8.39966 10.3305 8.66829 10.5992 8.99966 10.5992C9.33103 10.5992 9.59966 10.3305 9.59966 9.99917V8.99917C9.59966 8.30961 9.32573 7.64829 8.83814 7.16069C8.35054 6.6731 7.68922 6.39917 6.99966 6.39917H2.99966Z"
        fill="#212529"
      />
    </svg>
  );
};

export default UserLogo;
