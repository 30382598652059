import React, { useState } from 'react';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { useSelector } from 'react-redux';
import { COMPONENT_VARIANT, STORAGE_LANGUAGE } from '../../../constants/const';
import DropDownButton from '../../DropDownButton';
import './style.scss';

const InputMarkdown = (props) => {
  const language = useSelector((state) => state.app.language);
  const activeLanguageStorage = localStorage.getItem(STORAGE_LANGUAGE);
  const [activeLanguage, setActiveLanguage] = useState(activeLanguageStorage);

  const getInitValue = () => {
    if (language) {
      if (props.isLanguage) return props.defaultValue[activeLanguage] ?? '';
      return props.defaultValue;
    }
    return '';
  };

  const [value, setValue] = useState(getInitValue());

  const getMasLanguage = () => {
    if (language) {
      return language.map((value) => value.code);
    }
    return [];
  };
  const onBlur = () => {
    console.log(value);
    if (props.isLanguage) {
      let tmp = { ...props.defaultValue };
      tmp[activeLanguage] = value;
      props.setField(tmp);
    } else {
      props.setField(value);
    }
  };

  return (
    <div className="input-markdown" data-color-mode="light">
      {props.isLanguage && (
        <DropDownButton
          className={'markdown-dropdown-language'}
          activeElement={'Localization: ' + activeLanguage}
          setActiveElement={(element) => {
            setValue(props.defaultValue[element]);
            setActiveLanguage(element);
          }}
          element={getMasLanguage()}
          variant={COMPONENT_VARIANT.primary}></DropDownButton>
      )}
      <MarkdownEditor
        value={value}
        height="600px"
        onChange={(value) => {
          setValue(value);
        }}
        onBlur={onBlur}
      />
    </div>
  );
};

export default InputMarkdown;
