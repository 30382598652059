import { Api } from '../../services/Api';
import { airportInitialState } from './initialStates';
import { separateUrl } from '../../services/separateUrl';

const CLEAR_AIRPORT = 'AIRPORT/CLEAR';
const SET_AIRPORT_ALL_FIELD = 'AIRPORT/SET_ALL_FIELD';
const SET_AIRPORT_LOADED = 'AIRPORT/SET_LOADED';
const SET_AIRPORT_ID = 'AIRPORT/SET_ID';
const SET_AIRPORT_CITY = 'AIRPORT/SET_CITY';
const SET_AIRPORT_COUNTRY = 'AIRPORT/SET_COUNTRY';
const SET_AIRPORT_TAXIS = 'AIRPORT/SET_TAXIS';
const SET_AIRPORT_MAPS = 'AIRPORT/SET_MAPS';
const SET_AIRPORT_DESCRIPTION = 'AIRPORT/SET_DESCRIPTION';
const SET_AIRPORT_IATA_CODE = 'AIRPORT/SET_IATA_CODE';
const SET_AIRPORT_LAT = 'AIRPORT/SET_LAT';
const SET_AIRPORT_LON = 'AIRPORT/SET_LON';
const SET_AIRPORT_PHONE = 'AIRPORT/SET_PHONE';
const SET_AIRPORT_INFORMATION = 'AIRPORT/SET_INFORMATION';
const SET_AIRPORT_TIMEZONE = 'AIRPORT/SET_TIMEZONE';
const SET_AIRPORT_WEBSITE = 'AIRPORT/SET_WEBSITE';
const SET_AIRPORT_MAP_URL = 'AIRPORT/SET_MAP_URL';
const SET_AIRPORT_DESCRIPTION_IMAGE_URL = 'AIRPORT/SET_DESCRIPTION_IMAGE_URL';
const SET_AIRPORT_DESCRIPTION_IMAGE_FILE = 'AIRPORT/SET_DESCRIPTION_IMAGE_FILE';
const SET_AIRPORT_TRAIN = 'AIRPORT/SET_TRAIN';
const SET_AIRPORT_METRO = 'AIRPORT/SET_METRO';
const SET_AIRPORT_BUS = 'AIRPORT/SET_BUS';
const SET_AIRPORT_SHUTTLE = 'AIRPORT/SET_SHUTTLE';
const SET_AIRPORT_PARKING = 'AIRPORT/SET_PARKING';
const SET_AIRPORT_TRAM = 'AIRPORT/SET_TRAM';
const SET_AIRPORT_OTHER = 'AIRPORT/SET_OTHER';
const SET_AIRPORT_SEARCH_FIELD = 'AIRPORT/SET_SEARCH_FIELD';
const SET_AIRPORT_POPULARITY = 'AIRPORT/SET_POPULARITY';
const SET_AIRPORT_OTHER_NAMES = 'AIRPORT/SET_OTHER_NAMES';
const SET_AIRPORT_INSTAGRAM = 'AIRPORT/SET_INSTAGRAM';
const SET_AIRPORT_TWITTER = 'AIRPORT/SET_TWITTER';
const SET_AIRPORT_FACEBOOK = 'AIRPORT/SET_FACEBOOK';
const SET_AIRPORT_CATEGORIES = 'AIRPORT/SET_CATEGORIES';
const SET_AIRPORT_UPDATED = 'AIRPORT/SET_UPDATED';
const SET_AIRPORT_LAST_CHECKED = 'AIRPORT/SET_LAST_CHECKED';

const initialState = airportInitialState;

export const getAirport = (id) => {
  return (dispatch) =>
    Api.getAirportId(id).then((res) => {
      const newData = { ...res, loaded: true };
      newData.maps.map((value) => {
        const filterMapsCategory = newData.maps.filter((i) => i.category == value.category);
        if (filterMapsCategory.length > 1) {
          const filterMaps = filterMapsCategory.filter((i) => i.ordering === 1);
          if (filterMaps.length > 1) {
            let ordering = 1;
            newData.maps.map((val) => {
              if (val.category === value.category && val.ordering === 1) {
                val.ordering = ordering;
                ordering++;
              }
            });
          }
        }
      });
      newData.taxis = newData.taxis.sort((a, b) => (a.order > b.order ? 1 : -1));
      newData.website = separateUrl(res.website);
      newData.mapUrl = separateUrl(res.mapUrl);
      newData.train = separateUrl(res.train);
      newData.metro = separateUrl(res.metro);
      newData.bus = separateUrl(res.bus);
      newData.shuttle = separateUrl(res.shuttle);
      newData.parking = separateUrl(res.parking);
      newData.tram = separateUrl(res.tram);
      newData.other = separateUrl(res.other);
      newData.instagram = separateUrl(res.instagram);
      newData.twitter = separateUrl(res.twitter);
      newData.facebook = separateUrl(res.facebook);
      dispatch(setAirportAllField(newData));
    });
};
export const clearAirport = () => {
  return { type: CLEAR_AIRPORT };
};
export const setAirportLoaded = (condition) => {
  return { type: SET_AIRPORT_LOADED, payload: condition };
};
export const setAirportAllField = (data) => {
  return { type: SET_AIRPORT_ALL_FIELD, payload: data };
};
export const setAirportId = (id) => {
  return { type: SET_AIRPORT_ID, payload: id };
};
export const setAirportCity = (city) => {
  return { type: SET_AIRPORT_CITY, payload: city };
};
export const setAirportCountry = (country) => {
  return { type: SET_AIRPORT_COUNTRY, payload: country };
};
export const setAirportTaxis = (taxis) => {
  return { type: SET_AIRPORT_TAXIS, payload: taxis };
};

export const setAirportCategories = (categories) => {
  return { type: SET_AIRPORT_CATEGORIES, payload: categories };
};
export const setAirportMaps = (maps) => {
  return { type: SET_AIRPORT_MAPS, payload: maps };
};
export const setAirportDescription = (description) => {
  return { type: SET_AIRPORT_DESCRIPTION, payload: description };
};
export const setAirportIataCode = (iataCode) => {
  return { type: SET_AIRPORT_IATA_CODE, payload: iataCode };
};
export const setAirportLat = (lat) => {
  return { type: SET_AIRPORT_LAT, payload: lat };
};
export const setAirportLon = (lon) => {
  return { type: SET_AIRPORT_LON, payload: lon };
};
export const setAirportPhone = (phone) => {
  return { type: SET_AIRPORT_PHONE, payload: phone };
};
export const setAirportInformation = (information) => {
  return { type: SET_AIRPORT_INFORMATION, payload: information };
};
export const setAirportTimezone = (timezone) => {
  return { type: SET_AIRPORT_TIMEZONE, payload: timezone };
};
export const setAirportWebsite = (website) => {
  return { type: SET_AIRPORT_WEBSITE, payload: website };
};
export const setAirportMapUrl = (mapUrl) => {
  return { type: SET_AIRPORT_MAP_URL, payload: mapUrl };
};
export const setAirportDescriptionImageUrl = (descriptionImageUrl) => {
  return { type: SET_AIRPORT_DESCRIPTION_IMAGE_URL, payload: descriptionImageUrl };
};
export const setAirportDescriptionImageFile = (descriptionImageUrl) => {
  return { type: SET_AIRPORT_DESCRIPTION_IMAGE_FILE, payload: descriptionImageUrl };
};
export const setAirportTrain = (train) => {
  return { type: SET_AIRPORT_TRAIN, payload: train };
};
export const setAirportMetro = (metro) => {
  return { type: SET_AIRPORT_METRO, payload: metro };
};
export const setAirportBus = (bus) => {
  return { type: SET_AIRPORT_BUS, payload: bus };
};
export const setAirportShuttle = (shuttle) => {
  return { type: SET_AIRPORT_SHUTTLE, payload: shuttle };
};
export const setAirportParking = (parking) => {
  return { type: SET_AIRPORT_PARKING, payload: parking };
};
export const setAirportTram = (tram) => {
  return { type: SET_AIRPORT_TRAM, payload: tram };
};
export const setAirportOther = (other) => {
  return { type: SET_AIRPORT_OTHER, payload: other };
};
export const setAirportSearchField = (search_field) => {
  return { type: SET_AIRPORT_SEARCH_FIELD, payload: search_field };
};
export const setAirportPopularity = (popularity) => {
  return { type: SET_AIRPORT_POPULARITY, payload: popularity };
};
export const setAirportOtherNames = (otherNames) => {
  return { type: SET_AIRPORT_OTHER_NAMES, payload: otherNames };
};
export const setAirportInstagram = (instagram) => {
  return { type: SET_AIRPORT_INSTAGRAM, payload: instagram };
};
export const setAirportTwitter = (twitter) => {
  return { type: SET_AIRPORT_TWITTER, payload: twitter };
};
export const setAirportFacebook = (facebook) => {
  return { type: SET_AIRPORT_FACEBOOK, payload: facebook };
};
export const setAirportUpdated = (updated) => {
  return { type: SET_AIRPORT_UPDATED, payload: updated };
};
export const setAirportLastChecked = (lastChecked) => {
  return { type: SET_AIRPORT_LAST_CHECKED, payload: lastChecked };
};

const airport = (state = initialState, action) => {
  switch (action.type) {
    case SET_AIRPORT_LOADED:
      return { ...state, loaded: action.payload };
    case SET_AIRPORT_ID:
      return { ...state, id: action.payload };
    case SET_AIRPORT_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_AIRPORT_CITY:
      return { ...state, city: action.payload };
    case SET_AIRPORT_COUNTRY:
      return { ...state, country: action.payload };
    case SET_AIRPORT_TAXIS:
      return { ...state, taxis: action.payload };
    case SET_AIRPORT_CATEGORIES:
      return { ...state, categories: action.payload };
    case SET_AIRPORT_MAPS:
      return { ...state, maps: action.payload };
    case SET_AIRPORT_DESCRIPTION:
      return { ...state, description: action.payload };
    case SET_AIRPORT_IATA_CODE:
      return { ...state, iataCode: action.payload };
    case SET_AIRPORT_LAT:
      return { ...state, lat: action.payload };
    case SET_AIRPORT_LON:
      return { ...state, lon: action.payload };
    case SET_AIRPORT_PHONE:
      return { ...state, phone: action.payload };
    case SET_AIRPORT_INFORMATION:
      return { ...state, information: action.payload };
    case SET_AIRPORT_TIMEZONE:
      return { ...state, timezone: action.payload };
    case SET_AIRPORT_WEBSITE:
      return { ...state, website: action.payload };
    case SET_AIRPORT_MAP_URL:
      return { ...state, mapUrl: action.payload };
    case SET_AIRPORT_DESCRIPTION_IMAGE_URL:
      return { ...state, descriptionImageUrl: action.payload };
    case SET_AIRPORT_DESCRIPTION_IMAGE_FILE:
      return { ...state, descriptionImageFile: action.payload };
    case SET_AIRPORT_TRAIN:
      return { ...state, train: action.payload };
    case SET_AIRPORT_METRO:
      return { ...state, metro: action.payload };
    case SET_AIRPORT_BUS:
      return { ...state, bus: action.payload };
    case SET_AIRPORT_SHUTTLE:
      return { ...state, shuttle: action.payload };
    case SET_AIRPORT_PARKING:
      return { ...state, parking: action.payload };
    case SET_AIRPORT_TRAM:
      return { ...state, tram: action.payload };
    case SET_AIRPORT_OTHER:
      return { ...state, other: action.payload };
    case SET_AIRPORT_SEARCH_FIELD:
      return { ...state, search_field: action.payload };
    case SET_AIRPORT_POPULARITY:
      return { ...state, popularity: action.payload };
    case SET_AIRPORT_OTHER_NAMES:
      return { ...state, otherNames: action.payload };
    case SET_AIRPORT_FACEBOOK:
      return { ...state, facebook: action.payload };
    case SET_AIRPORT_INSTAGRAM:
      return { ...state, instagram: action.payload };
    case SET_AIRPORT_TWITTER:
      return { ...state, twitter: action.payload };
    case SET_AIRPORT_UPDATED:
      return { ...state, updated: action.payload };
    case SET_AIRPORT_LAST_CHECKED:
      return { ...state, lastChecked: action.payload };
    case CLEAR_AIRPORT:
      return { ...initialState };
    default:
      return state;
  }
};

export default airport;
