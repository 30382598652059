import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../../../services/Api';
import Loader from '../../../Loader';
import {
  COMPONENT_VARIANT,
  EVENT_AIRPORT,
  EVENT_TAXIS,
  UPDATE_DATA_CHANGE
} from '../../../../constants/const';
import {
  getTaxi,
  getTaxiAirport,
  setTaxisAddAirport,
  setTaxisRemoveAirport
} from '../../../../redux/reducers/taxis';
import TableSelectDetail from '../../../DetailComponent/TableSelectDetail';

const TaxiSelectAirport = (props) => {
  const stateElement = useSelector((state) => state.taxis);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!stateElement.id || props.id !== stateElement.id) && props.id !== 'new')
      dispatch(getTaxi(parseInt(props.id)));
    if (stateElement.airportTaxi.length === 0) dispatch(getTaxiAirport(parseInt(props.id)));
  }, [props.id]);

  const onSelect = (element) => {
    checkAddAirport(element, UPDATE_DATA_CHANGE.update);
  };

  const onDelete = (element) => {
    checkAddAirport(element, UPDATE_DATA_CHANGE.delete);
  };

  const getFullMassAirportTaxi = () => {
    const returnAirport = [...stateElement.addAirportTaxi];
    stateElement.airportTaxi.map((value) => {
      const findRemoveAirport = stateElement.removeAirportTaxi.find((el) => el.id === value.id);
      if (!findRemoveAirport) returnAirport.push(value);
    });
    return returnAirport;
  };

  const checkAddAirport = (selectedElement, type) => {
    const findAirportTaxi = stateElement.airportTaxi.find(
      (value) => value.id === selectedElement.id
    );
    if (type === UPDATE_DATA_CHANGE.update) {
      const findRemoveTaxi = stateElement.removeAirportTaxi.find(
        (value) => value.id === selectedElement.id
      );
      if (!findAirportTaxi) {
        const newAddAirport = [...stateElement.addAirportTaxi];
        newAddAirport.push(selectedElement);
        dispatch(setTaxisAddAirport(newAddAirport));
      }
      if (findRemoveTaxi) {
        const newMassRemove = stateElement.removeAirportTaxi.filter(
          (value) => value.id !== selectedElement.id
        );
        dispatch(setTaxisRemoveAirport(newMassRemove));
      }
    } else {
      const findRemoveAddTaxi = stateElement.addAirportTaxi.find(
        (value) => value.id === selectedElement.id
      );
      if (findRemoveAddTaxi) {
        const newAddAirport = stateElement.addAirportTaxi.filter(
          (value) => value.id !== selectedElement.id
        );
        dispatch(setTaxisAddAirport(newAddAirport));
      }
      if (findAirportTaxi) {
        const newMassRemove = [...stateElement.removeAirportTaxi];
        newMassRemove.push(selectedElement);
        dispatch(setTaxisRemoveAirport(newMassRemove));
      }
    }
  };

  return (
    <Loader loading={!stateElement.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <TableSelectDetail
        title={'Airports'}
        description={'Select Airports companies to add to this airport and click Save.'}
        titleCard={'otherNames'}
        saveData={() => {}}
        request={Api.getAirportPage}
        event={EVENT_AIRPORT}
        navigateEvent={EVENT_TAXIS}
        selectedElement={getFullMassAirportTaxi()}
        onSelect={onSelect}
        onDelete={onDelete}
        isMultiLanguage
      />
    </Loader>
  );
};

export default TaxiSelectAirport;
