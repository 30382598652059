import React, { useState } from 'react';
import ModalWindow from '../../ModalWindow';
import PaginationTable from '../../Pagination';
import { Button, Card } from 'react-bootstrap';
import NavBar from '../../NavBar';
import {
  COMPONENT_VARIANT,
  BASE_ITEMS_PAGE,
  TYPE_SELECT_DATA,
  UPDATE_DATA_CHANGE
} from '../../../constants/const';
import { FILTER_FIELD } from '../../../constants/searchElement';
import Loader from '../../Loader';
import { FIELD_INFO_CARD, FIELD_TYPE_CARD } from '../../../constants/getFieldInfo';

const ModalChose = (props) => {
  const [items, setItems] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [maxCard, setMaxCards] = useState(0);

  const [show, setShow] = useState(false);

  const [pageSearch, setPageSearch] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [pageElementCount, setPageElementCount] = useState(BASE_ITEMS_PAGE);

  const [activeFilter, setActiveFilter] = useState(FILTER_FIELD[props.event]);
  const [activeFilterBoolean, setActiveFilterBoolean] = useState(null);
  const [activeSort, setActiveSort] = useState(null);

  const setFilerField = (field, value) => {
    const newFiler = { ...activeFilter };
    newFiler[field] = value;
    setActiveFilter(newFiler);
  };
  const clearSearchParams = () => {
    setActiveFilter(FILTER_FIELD[props.event]);
    setActiveFilterBoolean(null);
    setActiveSort(null);
    setPageSearch('');
    getData(1, null, true);
  };
  const setSortField = (field) => {
    setActiveSort(field);
  };

  const getData = (page, searchValue = null, clearSearchParams = false) => {
    setIsLoad(true);
    setActivePage(page);
    const searchRequest = searchValue ?? pageSearch;
    if (searchValue) setPageSearch(searchValue);
    if (clearSearchParams) {
      props.request(page, pageElementCount, '', FILTER_FIELD[props.event], '', null).then((res) => {
        setItems(res.results);
        setMaxCards(res.count);
        setIsLoad(false);
      });
    } else {
      props
        .request(
          page,
          pageElementCount,
          searchRequest,
          activeFilter,
          activeSort,
          activeFilterBoolean
        )
        .then((res) => {
          setItems(res.results);
          setMaxCards(res.count);
          setIsLoad(false);
        });
    }
  };
  const isSelectedById = (id) => {
    return props.selectedItems?.find((i) => i.id === id);
  };

  return (
    <ModalWindow
      name={props.name}
      show={show}
      setShow={setShow}
      buttonVariant={props.buttonVariant}
      getData={getData}
      isLoading={items === null}
      isDisabled={props.isDisabled}
      header={
        <NavBar
          name={props.name}
          event={props.event}
          setFilerField={setFilerField}
          setSortField={setSortField}
          setFilterBoolean={setActiveFilterBoolean}
          setPageSearch={setPageSearch}
          searchPage={pageSearch}
          activeFilter={activeFilter}
          activeSort={activeSort}
          activeFilterBoolean={activeFilterBoolean}
          clearSearchParams={clearSearchParams}
          getData={getData}
          isModal
        />
      }>
      <Loader loading={items === null || isLoad} variant={COMPONENT_VARIANT.dark}>
        <div className="modal-context">
          <div className="context">
            {items?.map((value, index) => {
              const title = FIELD_INFO_CARD[props.event](value, FIELD_TYPE_CARD.title);
              const description = FIELD_INFO_CARD[props.event](value, FIELD_TYPE_CARD.description);
              if (title)
                return (
                  <Card className="cards-modal" key={index}>
                    <Card.Body>
                      <Card.Title>{title}</Card.Title>
                      <Card.Text>{description}</Card.Text>
                      <Button
                        variant={
                          isSelectedById(value.id)
                            ? COMPONENT_VARIANT.danger
                            : COMPONENT_VARIANT.primary
                        }
                        onClick={() => {
                          if (props.isSingleSelect) setShow(false);
                          isSelectedById(value.id)
                            ? props.deleteValue(value, UPDATE_DATA_CHANGE.delete)
                            : props.setValue(value, UPDATE_DATA_CHANGE.update);
                        }}>
                        {isSelectedById(value.id)
                          ? TYPE_SELECT_DATA.delete
                          : TYPE_SELECT_DATA.select}
                      </Button>
                    </Card.Body>
                  </Card>
                );
            })}
          </div>
          <PaginationTable
            itemPage={pageElementCount}
            setItemPage={setPageElementCount}
            allItemCount={maxCard}
            request={getData}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </div>
      </Loader>
    </ModalWindow>
  );
};

export default ModalChose;
