import React from 'react';
import { useParams } from 'react-router-dom';
import RentalSelectAirport from '../../Components/DetailPage/DetailTable/RentalSelectAirport';

const SelectAirportInRentalPage = () => {
  const { id } = useParams();

  return <RentalSelectAirport id={id} />;
};

export default SelectAirportInRentalPage;
