import { Api } from '../../services/Api';
import { taxisInitialState } from './initialStates';
import { separateUrl } from '../../services/separateUrl';

const CLEAR_TAXI = 'TAXI/CLEAR';
const SET_TAXI_LOADED = 'TAXI/SET_LOADED';
const SET_TAXI_ALL_FIELD = 'TAXI/SET_ALL_FIELD';
const SET_TAXI_ID = 'TAXI/SET_ID';
const SET_TAXI_ORDER = 'TAXI/SET_ORDER';
const SET_TAXI_TITLE = 'TAXI/SET_TITLE';
const SET_TAXI_WEB_URL = 'TAXI/SET_WEB_URL';
const SET_TAXI_APP_STORE_URL = 'TAXI/SET_APP_STORE_URL';
const SET_TAXI_GOOGLE_STORE_URL = 'TAXI/SET_GOOGLE_STORE_URL';
const SET_TAXI_URL_SCHEMA = 'TAXI/SET_URL_SCHEMA';
const SET_TAXI_AIRPORT = 'TAXI/SET_AIRPORT';
const SET_TAXI_ADD_AIRPORT = 'TAXI/SET_ADD_AIRPORT';
const SET_TAXI_REMOVE_AIRPORT = 'TAXI/SET_REMOVE_AIRPORT';
const SET_TAXI_IMAGE = 'TAXI/SET_IMAGE';
const SET_TAXI_IMAGE_FILE = 'TAXI/SET_IMAGE_FILE';

const initialState = taxisInitialState;

export const getTaxi = (id) => {
  return (dispatch) =>
    Api.getTaxiId(id).then((res) => {
      const newData = { ...res, loaded: true };
      newData.webUrl = separateUrl(res.webUrl);
      newData.appStoreUrl = separateUrl(res.appStoreUrl);
      newData.googleStoreUrl = separateUrl(res.googleStoreUrl);
      if (res.tittle) newData.title = res.tittle;
      dispatch(setTaxiAllField(newData));
    });
};

export const getTaxiAirport = (id) => {
  return (dispatch) =>
    Api.getTaxiAirportId(id).then((data) => {
      dispatch(setTaxisAirport(data));
    });
};

export const clearTaxi = () => {
  return { type: CLEAR_TAXI, payload: true };
};
export const setTaxiLoaded = (condition) => {
  return { type: SET_TAXI_LOADED, payload: condition };
};
export const setTaxiAllField = (data) => {
  return { type: SET_TAXI_ALL_FIELD, payload: data };
};
export const setTaxiId = (id) => {
  return { type: SET_TAXI_ID, payload: id };
};
export const setTaxiOrder = (order) => {
  return { type: SET_TAXI_ORDER, payload: order };
};
export const setTaxiTitle = (title) => {
  return { type: SET_TAXI_TITLE, payload: title };
};
export const setTaxiWebUrl = (webUrl) => {
  return { type: SET_TAXI_WEB_URL, payload: webUrl };
};
export const setTaxiAppStoreUrl = (appStoreUrl) => {
  return { type: SET_TAXI_APP_STORE_URL, payload: appStoreUrl };
};
export const setTaxiGoogleStoreURL = (googleStoreUrl) => {
  return { type: SET_TAXI_GOOGLE_STORE_URL, payload: googleStoreUrl };
};
export const setTaxiUrlSchema = (urlSchema) => {
  return { type: SET_TAXI_URL_SCHEMA, payload: urlSchema };
};
export const setTaxisAirport = (data) => {
  return { type: SET_TAXI_AIRPORT, payload: data };
};
export const setTaxisAddAirport = (data) => {
  return { type: SET_TAXI_ADD_AIRPORT, payload: data };
};
export const setTaxisRemoveAirport = (data) => {
  return { type: SET_TAXI_REMOVE_AIRPORT, payload: data };
};
export const setSquareImage = (data) => {
  return { type: SET_TAXI_IMAGE, payload: data };
};
export const setSquareImageFile = (data) => {
  return { type: SET_TAXI_IMAGE_FILE, payload: data };
};

const taxis = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAXI_LOADED:
      return { ...state, loaded: action.payload };
    case SET_TAXI_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_TAXI_ID:
      return { ...state, id: action.payload };
    case SET_TAXI_ORDER:
      return { ...state, order: action.payload };
    case SET_TAXI_TITLE:
      return { ...state, title: action.payload };
    case SET_TAXI_WEB_URL:
      return { ...state, webUrl: action.payload };
    case SET_TAXI_APP_STORE_URL:
      return { ...state, appStoreUrl: action.payload };
    case SET_TAXI_GOOGLE_STORE_URL:
      return { ...state, googleStoreUrl: action.payload };
    case SET_TAXI_URL_SCHEMA:
      return { ...state, urlSchema: action.payload };
    case SET_TAXI_AIRPORT:
      return { ...state, airportTaxi: action.payload };
    case SET_TAXI_ADD_AIRPORT:
      return { ...state, addAirportTaxi: action.payload };
    case SET_TAXI_REMOVE_AIRPORT:
      return { ...state, removeAirportTaxi: action.payload };
    case SET_TAXI_IMAGE:
      return { ...state, squareImage: action.payload };
    case SET_TAXI_IMAGE_FILE:
      return { ...state, squareImageFile: action.payload };
    case CLEAR_TAXI:
      return { ...initialState };
    default:
      return state;
  }
};

export default taxis;
