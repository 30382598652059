import React from 'react';
import { useParams } from 'react-router-dom';
import AirportSelectCity from '../../Components/DetailPage/DetailTable/AirportSelectCity';

const SelectCityInAirportPage = () => {
  const { id } = useParams();

  return <AirportSelectCity id={id} />;
};

export default SelectCityInAirportPage;
