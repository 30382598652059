import React from 'react';
import { useParams } from 'react-router-dom';
import RemindersDetail from '../../Components/DetailPage/Detail/RemindersDetail';

const DetailRemindersPage = () => {
  const { id } = useParams();

  return <RemindersDetail detailId={id} />;
};

export default DetailRemindersPage;
