import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import {
  clearCountry,
  getCountry,
  setCountryCode,
  setCountryCurrency,
  setCountryCurrencyInfo,
  setCountryFlag,
  setCountryLoaded,
  setCountryOtherNames,
  setCountrySearchField
} from '../../../../redux/reducers/country';
import InputDetail from '../../../DetailComponent/InputDetail';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import { COMPONENT_VARIANT, EVENT_COUNTRY } from '../../../../constants/const';
import Loader from '../../../Loader';
import { Api } from '../../../../services/Api';
import { getCurrency } from '../../../../redux/reducers/currency';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';

const CountryDetail = (props) => {
  const [country, currency] = useSelector((state) => [state.country, state.currency]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCountryLoaded(false));
    if (props.id === 'new') {
      dispatch(clearCountry());
      dispatch(setCountryLoaded(true));
    }
    if ((!country.id || parseInt(props.id) !== country.id) && props.id !== 'new')
      dispatch(getCountry(parseInt(props.id)));
    else {
      dispatch(setCountryLoaded(true));
    }
  }, [props.id]);

  useEffect(() => {
    if (country?.currencyInfo) dispatch(getCurrency(country?.currencyInfo));
  }, [country]);
  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(country.countryCode)) return false;
    return true;
  };

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [validateCode, setValidateCode] = useState(false);
  const validate = () => {
    setValidateCode(false);

    let flag = true;
    if (!country.countryCode) {
      setValidateCode(true);
      flag = false;
    }

    return flag;
  };

  const updateCountry = () => {
    let multipart = { ...country };
    const formData = MultipartWrapper.Country(multipart);
    if (validate() && !isLoading) {
      setIsLoading(true);
      if (!country.id)
        Api.createCountry(formData).then((res) => {
          if (res.id) navigate('/' + EVENT_COUNTRY + '/' + res.id);
          console.log(res);
          setResponse(res);
          setIsLoading(false);
        });
      else {
        Api.updateCountry(country.id, formData).then((res) => {
          setResponse(res);
          setIsLoading(false);
        });
      }
    }
  };

  return (
    <Loader
      loading={!country.loaded}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="country-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={country.countryCode} />
          </BlockDetail>
        )}
        <div className="country-detail-context">
          <HeaderDetail
            name={country.countryCode}
            isValidate={checkValidate()}
            requestUpdate={updateCountry}
            requestDelete={() => {
              Api.deleteCountry(country.id);
            }}
            loading={isLoading}
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <InputDetail
              head="Name"
              placeholder={'Poland'}
              defaultValue={country.otherNames}
              setField={(value) => {
                dispatch(setCountryOtherNames(value));
              }}
              isNecessarily
              isLanguage
              isInput
            />
            <InputDetail
              head="Country Code"
              placeholder={'POL'}
              validate={validateCode}
              defaultValue={country.countryCode}
              setField={(value) => {
                dispatch(setCountryCode(value));
              }}
              isNecessarily
              isInput
            />
            <InputDetail
              head="flag"
              placeholder={'🇵🇱'}
              defaultValue={country.flag}
              setField={(value) => {
                dispatch(setCountryFlag(value));
              }}
              isNecessarily
              isInput
            />
            <InputDetail
              head="Currency"
              defaultValue={country.currency}
              setField={(value) => {
                dispatch(setCountryCurrency(value));
              }}
              isInput
            />
            <InputDetail
              head="Search field"
              defaultValue={country.search_field}
              setField={(value) => {
                dispatch(setCountrySearchField(value));
              }}
              isMultiline
              isInput
              isDisabled
            />
            <InputDetail
              head="Currency Info"
              defaultValue={country.currencyInfo}
              setField={(value) => {
                dispatch(setCountryCurrencyInfo(value));
              }}
              isInput
              isDisabled
            />
            <br />
            {currency?.price && (
              <div>{currency?.price?.toFixed(2) + ' ' + currency?.currency + ' = 1 USD'}</div>
            )}
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default CountryDetail;
