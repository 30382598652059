const Taxis = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1924 2.50848C12.0424 2.06598 11.6224 1.75098 11.1274 1.75098H9.25244V0.250977H4.75244V1.75098H2.87744C2.38244 1.75098 1.96994 2.06598 1.81244 2.50848L0.252441 7.00098V13.001C0.252441 13.4135 0.589941 13.751 1.00244 13.751H1.75244C2.16494 13.751 2.50244 13.4135 2.50244 13.001V12.251H11.5024V13.001C11.5024 13.4135 11.8399 13.751 12.2524 13.751H13.0024C13.4149 13.751 13.7524 13.4135 13.7524 13.001V7.00098L12.1924 2.50848ZM3.13994 3.25098H10.8574L11.6374 5.50098H2.35994L3.13994 3.25098ZM11.5024 10.751H12.2524V7.25598L12.1699 7.00098H1.84244L1.75244 7.25598V10.751H2.50244H11.5024ZM3.62744 10.001C4.24876 10.001 4.75244 9.4973 4.75244 8.87598C4.75244 8.25466 4.24876 7.75098 3.62744 7.75098C3.00612 7.75098 2.50244 8.25466 2.50244 8.87598C2.50244 9.4973 3.00612 10.001 3.62744 10.001ZM11.5024 8.87598C11.5024 9.4973 10.9988 10.001 10.3774 10.001C9.75612 10.001 9.25244 9.4973 9.25244 8.87598C9.25244 8.25466 9.75612 7.75098 10.3774 7.75098C10.9988 7.75098 11.5024 8.25466 11.5024 8.87598Z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default Taxis;
