import React from 'react';
import { useParams } from 'react-router-dom';
import PushEventDetail from '../../Components/DetailPage/Detail/PushEventDetail';

const DetailPushEventPage = () => {
  const { id } = useParams();

  return <PushEventDetail detailId={id} />;
};

export default DetailPushEventPage;
