import { Api } from '../../services/Api';
import { separateUrl } from '../../services/separateUrl';
import { rentalInitialState } from './initialStates';

const CLEAR_RENTAL = 'RENTAL/CLEAR';
const SET_RENTAL_LOADED = 'RENTAL/SET_LOADED';
const SET_RENTAL_ALL_FIELD = 'RENTAL/SET_ALL_FIELD';
const SET_RENTAL_ID = 'RENTAL/SET_ID';
const SET_RENTAL_ORDER = 'RENTAL/SET_ORDER';
const SET_RENTAL_TITLE = 'RENTAL/SET_TITLE';
const SET_RENTAL_URL_SCHEMA = 'RENTAL/SET_URL_SCHEMA';
const SET_RENTAL_SQUARE_IMAGE = 'RENTAL/SET_SQUARE_IMAGE';
const SET_RENTAL_SQUARE_IMAGE_FILE = 'RENTAL/SET_SQUARE_IMAGE_FILE';
const SET_RENTAL_APP_STORE_URL = 'RENTAL/SET_APP_STORE_URL';
const SET_RENTAL_GOOGLE_STORE_URL = 'RENTAL/SET_GOOGLE_STORE_URL';
const SET_RENTAL_WEB_URL = 'RENTAL/SET_WEB_URL';
const SET_RENTAL_AIRPORT = 'RENTAL/SET_AIRPORT';
const SET_RENTAL_ADD_AIRPORT = 'RENTAL/SET_ADD_AIRPORT';
const SET_RENTAL_REMOVE_AIRPORT = 'RENTAL/SET_REMOVE_AIRPORT';

const initialState = rentalInitialState;

export const getRental = (id) => {
  return (dispatch) => {
    Api.getRentalId(id).then((res) => {
      const newData = { ...res, loaded: true };
      newData.webUrl = separateUrl(res.webUrl);
      newData.appStoreUrl = separateUrl(res.appStoreUrl);
      newData.googleStoreUrl = separateUrl(res.googleStoreUrl);
      dispatch(setRentalAllField(newData));
    });
  };
};

export const getRentalAirport = (id) => {
  return (dispatch) =>
    Api.getRentalAirportId(id).then((res) => {
      dispatch(setRentalAirport(res));
    });
};

export const clearRental = () => {
  return { type: CLEAR_RENTAL, payload: true };
};
export const setRentalLoaded = (condition) => {
  return { type: SET_RENTAL_LOADED, payload: condition };
};
export const setRentalAllField = (data) => {
  return { type: SET_RENTAL_ALL_FIELD, payload: data };
};
export const setRentalId = (id) => {
  return { type: SET_RENTAL_ID, payload: id };
};
export const setRentalOrder = (order) => {
  return { type: SET_RENTAL_ORDER, payload: order };
};
export const setRentalTitle = (title) => {
  return { type: SET_RENTAL_TITLE, payload: title };
};
export const setRentalUrlSchema = (urlSchema) => {
  return { type: SET_RENTAL_URL_SCHEMA, payload: urlSchema };
};
export const setRentalSquareImage = (squareImage) => {
  return { type: SET_RENTAL_SQUARE_IMAGE, payload: squareImage };
};
export const setRentalSquareImageFile = (squareImageFile) => {
  return { type: SET_RENTAL_SQUARE_IMAGE_FILE, payload: squareImageFile };
};
export const setRentalAppStoreUrl = (appStoreUrl) => {
  return { type: SET_RENTAL_APP_STORE_URL, payload: appStoreUrl };
};
export const setRentalGoogleStoreUrl = (googleStoreUrl) => {
  return { type: SET_RENTAL_GOOGLE_STORE_URL, payload: googleStoreUrl };
};
export const setRentalWebUrl = (webUrl) => {
  return { type: SET_RENTAL_WEB_URL, payload: webUrl };
};
export const setRentalAirport = (airport) => {
  return { type: SET_RENTAL_AIRPORT, payload: airport };
};
export const setRentalAddAirport = (data) => {
  return { type: SET_RENTAL_ADD_AIRPORT, payload: data };
};
export const setRentalRemoveAirport = (data) => {
  return { type: SET_RENTAL_REMOVE_AIRPORT, payload: data };
};

const rental = (state = initialState, action) => {
  switch (action.type) {
    case SET_RENTAL_LOADED:
      return { ...state, loaded: action.payload };
    case SET_RENTAL_ID:
      return { ...state, id: action.payload };
    case SET_RENTAL_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_RENTAL_ORDER:
      return { ...state, order: action.payload };
    case SET_RENTAL_TITLE:
      return { ...state, title: action.payload };
    case SET_RENTAL_URL_SCHEMA:
      return { ...state, urlSchema: action.payload };
    case SET_RENTAL_SQUARE_IMAGE:
      return { ...state, squareImage: action.payload };
    case SET_RENTAL_SQUARE_IMAGE_FILE:
      return { ...state, squareImageFile: action.payload };
    case SET_RENTAL_APP_STORE_URL:
      return { ...state, appStoreUrl: action.payload };
    case SET_RENTAL_GOOGLE_STORE_URL:
      return { ...state, googleStoreUrl: action.payload };
    case SET_RENTAL_WEB_URL:
      return { ...state, webUrl: action.payload };
    case SET_RENTAL_AIRPORT:
      return { ...state, airport: action.payload };
    case SET_RENTAL_ADD_AIRPORT:
      return { ...state, addAirport: action.payload };
    case SET_RENTAL_REMOVE_AIRPORT:
      return { ...state, removeAirport: action.payload };
    case CLEAR_RENTAL:
      return { ...initialState };
    default:
      return state;
  }
};

export default rental;
