import React from 'react';
import { Button } from 'react-bootstrap';
import './style.scss';
import ModalConfirm from '../../ModalConfirm';
import { Alert } from 'react-bootstrap';
import {
  COMPONENT_VARIANT,
  DELAY_TIME_LONG_AGO,
  DELAY_TIME_RECENTLY
} from '../../../constants/const';
import UserLogo from '../../svg/UserLogo';
import { convertDateTimeFormat, getDelayDay } from '../../../services/convertDateTimeFormat';
import Loader from '../../Loader';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Checked from '../../svg/Checked';

const HeaderDetail = (props) => {
  const saveButtonText = () => {
    if (props.loading) return <Loader loading={true} small />;
    return 'Save';
  };
  const getMarchChecked = () => {
    if (props.isDisabledChecked) return;
    if (props.isValidate)
      return (
        <Button
          variant={COMPONENT_VARIANT.secondary}
          onClick={() => {
            if (props.requestUpdateChecked) props.requestUpdateChecked();
          }}
          className={classNames({
            checked: true,
            'success-check': true
          })}>
          <Checked />
          {props.isUpdateChecked ? <Loader loading={true} small /> : <span>Mark as checked</span>}
        </Button>
      );
    else
      return (
        <Button
          variant={COMPONENT_VARIANT.dark}
          onClick={() => {
            if (props.requestUpdateChecked) props.requestUpdateChecked();
          }}
          className="checked mark-check">
          <Checked />
          <span>Mark as checked</span>
        </Button>
      );
  };

  const colorInfo = getDelayDay(props.lastChecked);
  const navigate = useNavigate();
  return (
    <div className="header-detail">
      <div className="header-data-content">
        <div className="header">{props.name}</div>
        <div className="button-context">
          <Button
            variant="outline-primary"
            className="save-button"
            onClick={() => {
              props.requestUpdate();
            }}>
            {saveButtonText()}
          </Button>

          {!props.isDisabledDelete && (
            <ModalConfirm
              variant={COMPONENT_VARIANT.danger}
              name={'Delete'}
              header={'Want to delete?'}
              className={'delete-button'}
              onClick={() => {
                props.requestDelete();
                if (window.history.state && window.history.state.idx > 0) {
                  navigate(-1);
                } else {
                  navigate('/', { replace: true });
                }
              }}>
              Are you sure you want to delete this item?
            </ModalConfirm>
          )}
          {getMarchChecked()}
          {props.alert && (
            <Alert variant={'success'} className="alert-last-checked">
              {props.alert}
            </Alert>
          )}
        </div>
      </div>
      <div className="header-user-content">
        {props.lastChecked && (
          <div
            className={classNames({
              'user-last-update': true,
              'user-last-green': colorInfo <= DELAY_TIME_RECENTLY,
              'user-last-yellow':
                colorInfo > DELAY_TIME_RECENTLY && colorInfo <= DELAY_TIME_LONG_AGO,
              'user-last-red': colorInfo > DELAY_TIME_LONG_AGO
            })}>
            Last checked on {convertDateTimeFormat(props.lastChecked)}
          </div>
        )}
        {props.updated && (
          <div className="user-last-update">
            Last edited on {convertDateTimeFormat(props.updated)}
          </div>
        )}
        {props.responsible && (
          <div className="user-name">
            <UserLogo />
            {props.responsible}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderDetail;
