import { Api } from '../../services/Api';
import { weatherStateInitialState } from './initialStates';

const CLEAR_WEATHER_STATE = 'WEATHER_STATE/CLEAR';
const SET_WEATHER_STATE_LOADED = 'WEATHER_STATE/SET_LOADED';
const SET_WEATHER_STATE_ALL_FIELD = 'WEATHER_STATE/SET_ALL_FIELD';
const SET_WEATHER_STATE_ID = 'WEATHER_STATE/SET_ID';
const SET_WEATHER_STATE_NAME = 'WEATHER_STATE/SET_ORDER';
const SET_WEATHER_STATE_LANGUAGE = 'WEATHER_STATE/SET_TITLE';

const initialState = weatherStateInitialState;

export const getWeatherState = () => {
  return (dispatch) =>
    Api.getWeatherStatePage().then((res) => {
      const newData = { ...res, loaded: true };
      dispatch(setWeatherStateAllField(newData));
    });
};

export const clearWeatherState = () => {
  return { type: CLEAR_WEATHER_STATE, payload: true };
};
export const setWeatherStateLoaded = (condition) => {
  return { type: SET_WEATHER_STATE_LOADED, payload: condition };
};
export const setWeatherStateAllField = (data) => {
  return { type: SET_WEATHER_STATE_ALL_FIELD, payload: data };
};
export const setWeatherStateId = (id) => {
  return { type: SET_WEATHER_STATE_ID, payload: id };
};
export const setWeatherStateName = (order) => {
  return { type: SET_WEATHER_STATE_NAME, payload: order };
};
export const setWeatherStateLanguage = (title) => {
  return { type: SET_WEATHER_STATE_LANGUAGE, payload: title };
};

const weatherState = (state = initialState, action) => {
  switch (action.type) {
    case SET_WEATHER_STATE_LOADED:
      return { ...state, loaded: action.payload };
    case SET_WEATHER_STATE_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_WEATHER_STATE_ID:
      return { ...state, id: action.payload };
    case SET_WEATHER_STATE_NAME:
      return { ...state, name: action.payload };
    case SET_WEATHER_STATE_LANGUAGE:
      return { ...state, languages: action.payload };
    case CLEAR_WEATHER_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default weatherState;
