import { Api } from '../../services/Api';
import { pushEventInitialState } from './initialStates';

const CLEAR_PUSH_EVENT = 'PUSH_EVENT/CLEAR';
const SET_PUSH_EVENT_ID = 'PUSH_EVENT/SET_ID';
const SET_PUSH_EVENT_ALL_FIELD = 'PUSH_EVENT/SET_ALL_FIELD';
const SET_PUSH_EVENT_LOADED = 'PUSH_EVENT/SET_LOADED';
const SET_PUSH_EVENT_EVENT = 'PUSH_EVENT/SET_EVENT';
const SET_PUSH_EVENT_NAME_RU = 'PUSH_EVENT/SET_NAME_RU';
const SET_PUSH_EVENT_LOCALIZATION = 'PUSH_EVENT/SET_LOCALIZATION';
const SET_PUSH_EVENT_NEW_LOCALIZATION = 'PUSH_EVENT/SET_NEW_LOCALIZATION';

const initialState = pushEventInitialState;

export const getPushEvent = (id) => {
  return (dispatch) =>
    Api.getPushEventId(id).then((res) => {
      const newData = { ...res, loaded: true };
      dispatch(setPushEventAllField(newData));
    });
};
export const clearPushEvent = () => {
  return { type: CLEAR_PUSH_EVENT, payload: true };
};
export const setPushEventLoaded = () => {
  return { type: SET_PUSH_EVENT_LOADED, payload: true };
};
export const setPushEventAllField = (data) => {
  return { type: SET_PUSH_EVENT_ALL_FIELD, payload: data };
};
export const setPushEventId = (id) => {
  return { type: SET_PUSH_EVENT_ID, payload: id };
};
export const setPushEventEvent = (event) => {
  return { type: SET_PUSH_EVENT_EVENT, payload: event };
};
export const setPushEventLocalization = (localization) => {
  return { type: SET_PUSH_EVENT_LOCALIZATION, payload: localization };
};
export const setPushEventNewLocalization = (localization) => {
  return { type: SET_PUSH_EVENT_NEW_LOCALIZATION, payload: localization };
};
export const setPushEventNameRU = (nameRU) => {
  return { type: SET_PUSH_EVENT_NAME_RU, payload: nameRU };
};

const currency = (state = initialState, action) => {
  switch (action.type) {
    case SET_PUSH_EVENT_LOADED:
      return { ...state, loaded: action.payload };
    case SET_PUSH_EVENT_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_PUSH_EVENT_ID:
      return { ...state, id: action.payload };
    case SET_PUSH_EVENT_EVENT:
      return { ...state, event: action.payload };
    case SET_PUSH_EVENT_NAME_RU:
      return { ...state, nameRU: action.payload };
    case SET_PUSH_EVENT_LOCALIZATION:
      return { ...state, localization: action.payload };
    case SET_PUSH_EVENT_NEW_LOCALIZATION:
      return { ...state, newLocalization: action.payload };
    case CLEAR_PUSH_EVENT:
      return { ...initialState };
    default:
      return state;
  }
};

export default currency;
