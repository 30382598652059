import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import NavBarSearch from '../../../assets/image/NavBarSearch.svg';
import { Image } from 'react-bootstrap';
import { COMPONENT_VARIANT } from '../../../constants/const';
import './style.scss';
import { FILTER_BOOLEAN, FILTER_FIELD, SORT_FIELD } from '../../../constants/searchElement';
import SortNawBar from './SortNawBar';
import FilterBooleanNawBar from './FilterBooleanNawBar';
import FilterNawBar from './FilterNawBar';
const SortFilterNawBar = (props) => {
  const isSortFilter =
    !!SORT_FIELD[props.event] || !!FILTER_FIELD[props.event] || !!FILTER_BOOLEAN[props.event];

  const [searchLocal, setSearchLocal] = useState(props.searchPage ?? '');

  const [isFilterShow, setIsFilterShow] = useState(false);
  const [isSortShow, setIsSortShow] = useState(false);
  const filterRef = useRef(null);
  const sortRef = useRef(null);

  const getIsActiveSortFilter = () => {
    let isActiveSearch = props.activeSort || props.activeFilterBoolean;
    if (!isActiveSearch && props.activeFilter)
      Object.keys(props.activeFilter).map((value) => {
        if (props.activeFilter[value]) isActiveSearch = true;
      });
    return isActiveSearch;
  };

  return (
    <div className="sort-filter-context">
      <div className="search-context">
        <div className="search-container">
          <Form.Control
            placeholder={'Search ' + props.name.toLowerCase() + ' ...'}
            className="search-input"
            aria-label="Search"
            onChange={(e) => {
              setSearchLocal(e.target.value);
              props.setPageSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.getData(1, searchLocal);
              }
            }}
            value={searchLocal}
          />
          <Button
            className="search-button"
            variant="primary"
            onClick={() => {
              props.getData(1, searchLocal);
            }}>
            <Image src={NavBarSearch}></Image>
          </Button>
        </div>
        {isSortFilter && (
          <Nav.Link className="sort-filter-search">
            <Button
              ref={sortRef}
              variant={COMPONENT_VARIANT.link}
              className="search-button"
              disabled={!SORT_FIELD[props.event]}
              onClick={() => {
                setIsSortShow(!isSortShow);
                setIsFilterShow(false);
              }}>
              Sort
            </Button>
            <Overlay target={sortRef.current} show={isSortShow} placement="bottom">
              <Popover id={`popover-positioned-sort`}>
                <Popover.Body>
                  <div className="search-overlay-context">
                    <div className="filter-context">
                      <SortNawBar
                        event={props.event}
                        setValue={props.setSortField}
                        activeValue={props.activeSort}
                      />
                    </div>
                    <Button
                      className="button-search"
                      variant={COMPONENT_VARIANT.primary}
                      onClick={() => {
                        props.getData(1);
                      }}>
                      <div>Search</div>
                      <Image src={NavBarSearch}></Image>
                    </Button>
                  </div>
                </Popover.Body>
              </Popover>
            </Overlay>
            <Button
              ref={filterRef}
              variant={COMPONENT_VARIANT.link}
              className="search-button"
              disabled={!FILTER_FIELD[props.event] && !FILTER_BOOLEAN[props.event]}
              onClick={() => {
                setIsFilterShow(!isFilterShow);
                setIsSortShow(false);
              }}>
              Filter
            </Button>
            <Overlay target={filterRef.current} show={isFilterShow} placement="bottom">
              <Popover id={`popover-positioned-filter`}>
                <Popover.Body>
                  <div className="search-overlay-context">
                    <div className="filter-context">
                      <FilterBooleanNawBar
                        event={props.event}
                        setValue={props.setFilterBoolean}
                        activeValue={props.activeFilterBoolean}
                      />
                      <FilterNawBar
                        event={props.event}
                        setValue={props.setFilerField}
                        activeValue={props.activeFilter}
                        isModal={props.isModal}
                      />
                    </div>
                    <Button
                      className="button-search"
                      variant={COMPONENT_VARIANT.primary}
                      onClick={() => {
                        props.getData(1);
                      }}>
                      <div>Search</div>
                      <Image src={NavBarSearch}></Image>
                    </Button>
                  </div>
                </Popover.Body>
              </Popover>
            </Overlay>

            {getIsActiveSortFilter() && (
              <Button
                variant={'outline-' + COMPONENT_VARIANT.danger}
                className="button-clear-search-params"
                onClick={() => {
                  props.clearSearchParams();
                  setIsFilterShow(false);
                  setIsSortShow(false);
                }}>
                X
              </Button>
            )}
          </Nav.Link>
        )}
      </div>
    </div>
  );
};

export default SortFilterNawBar;
