import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../../../services/Api';
import {
  COMPONENT_VARIANT,
  DEFAULT_LOCALIZATION_PUSH_EVENT,
  FULL_LOCALIZATION_NAME
} from '../../../../constants/const';
import InputDetail from '../../../DetailComponent/InputDetail';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import './style.scss';
import {
  getPushEvent,
  setPushEventEvent,
  setPushEventLoaded,
  setPushEventLocalization,
  setPushEventNameRU,
  setPushEventNewLocalization
} from '../../../../redux/reducers/pushEvent';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';

const PushEventDetail = (props) => {
  const pushEvent = useSelector((state) => state.pushEvent);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [deleteLanguage, setDeleteLanguage] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.detailId === 'new') {
      dispatch(true);
    }
    if (!pushEvent.id && props.detailId !== 'new') dispatch(getPushEvent(parseInt(props.detailId)));
    else {
      dispatch(setPushEventLoaded(true));
    }
  }, []);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(pushEvent.event)) return false;
    if (checkFill(pushEvent.nameRU)) return false;
    return true;
  };
  const updatePushEvents = () => {
    let multipart = { ...pushEvent };
    const formData = MultipartWrapper.PushEvent(multipart);
    if (checkValidate() && !isLoading) {
      setIsLoading(true);
      Api.updatePushEventId(pushEvent.id, formData).then((res) => {
        setResponse(res);
        setIsLoading(false);
      });
      pushEvent.newLocalization.map((value) => {
        Api.createLanguagePushEventId(pushEvent.id, value.language, value.text).then(() => {
          const newLocalization = [...pushEvent.localization];
          newLocalization.push(value);
          dispatch(setPushEventLocalization(newLocalization));
        });
      });
      deleteLanguage.map((value) => {
        Api.deleteLanguagePushEventId(pushEvent.id, value.language).then(() => {
          let newLocalization = [...pushEvent.localization];
          newLocalization = newLocalization.filter((val) => val.language !== value.language);
          dispatch(setPushEventLocalization(newLocalization));
        });
      });
    }
  };

  const updatePushEventLocalization = (language, value) => {
    const newLocalization = [...pushEvent.localization];
    const currentLocalization = newLocalization.find((el) => el.language === language);
    if (currentLocalization) {
      if (value === '') {
        const newDeleteLocalization = [...deleteLanguage];
        newDeleteLocalization.push({ language: language });
        setDeleteLanguage(newDeleteLocalization);
      } else {
        const findLocalization = deleteLanguage.find((val) => val.language === language);
        if (findLocalization) {
          const newDeleteLocalization = deleteLanguage.filter((val) => val.language !== language);
          setDeleteLanguage(newDeleteLocalization);
        }
        newLocalization.map((val) => {
          if (val.language === language) {
            val.text = value;
          }
        });
        dispatch(setPushEventLocalization(newLocalization));
      }
    } else {
      const findLocalization = pushEvent.newLocalization.find((val) => val.language === language);
      if (!findLocalization && !value) return;
      if (findLocalization) {
        let newLocal = [...pushEvent.newLocalization];
        if (value === '') {
          newLocal = newLocal.filter((val) => {
            val.language !== language;
          });
          dispatch(setPushEventNewLocalization(newLocal));
        } else {
          newLocal.map((val) => {
            if (val.language === language) {
              val.text = value;
            }
          });
          dispatch(setPushEventNewLocalization(newLocal));
        }
      } else {
        const newLocal = [...pushEvent.newLocalization];
        newLocal.push({ language: language, text: value });
        dispatch(setPushEventNewLocalization(newLocal));
      }
    }
  };

  return (
    <Loader
      loading={!pushEvent.loaded}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="push-event-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={pushEvent.event} />
          </BlockDetail>
        )}
        <div className="push-event-detail-content">
          <HeaderDetail
            name={pushEvent.event}
            isValidate={checkValidate()}
            requestUpdate={updatePushEvents}
            requestDelete={() => {
              Api.deletePushEventId(pushEvent.id);
            }}
            loading={isLoading}
            isDisabledChecked
            isDisabledDelete
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="push-event-header">
              Select taxi companies to add to this airport and click Save.
            </div>
            <InputDetail
              head="Event"
              defaultValue={pushEvent.event}
              setField={(value) => {
                dispatch(setPushEventEvent(value));
              }}
              isInput
              isNecessarily
              isDisabled
            />
            <InputDetail
              head="NameRU"
              defaultValue={pushEvent.nameRU}
              setField={(value) => {
                dispatch(setPushEventNameRU(value));
              }}
              isInput
              isNecessarily
              isDisabled
            />
          </BlockDetail>
          <BlockDetail>
            {pushEvent.loaded &&
              DEFAULT_LOCALIZATION_PUSH_EVENT.map((value, index) => {
                const findCurrentLocal = pushEvent.localization.find(
                  (el) => el.language === value.language
                );
                return (
                  <InputDetail
                    key={index}
                    head={'Translation-' + value.language.toUpperCase()}
                    placeholder={'Push text in ' + FULL_LOCALIZATION_NAME[value.language]}
                    defaultValue={findCurrentLocal?.text}
                    setField={(el) => {
                      updatePushEventLocalization(value.language, el);
                    }}
                    isInput
                    isMultiline
                  />
                );
              })}
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default PushEventDetail;
