import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import './style.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FIELD_INFO_CARD, FIELD_TYPE_CARD } from '../../../../../constants/getFieldInfo';
import { COMPONENT_VARIANT, STORAGE_USER_NAME } from '../../../../../constants/const';
import ModalChose from '../../../../DetailComponent/ModalChoice';
import { FILTER_FIELD_TYPE, FILTER_TYPE } from '../../../../../constants/searchElement';

const InputFilter = (props) => {
  const [value, setValue] = useState(props.activeFilter);
  const [valueSelect, setValueSelect] = useState(null);
  const responsibleValue = localStorage.getItem(STORAGE_USER_NAME);
  const responsible = useSelector((state) => state.app.responsible);

  const getFiledName = (field) => {
    return field.slice(0, 1).toUpperCase() + field.slice(1, field.length);
  };
  const getSelectedElement = (request) => {
    if (props.activeFilter && !valueSelect) {
      request(props.activeFilter).then((value) => {
        setValueSelect(value);
      });
    }
  };
  const getInputBlock = () => {
    const typeFilter = FILTER_FIELD_TYPE[props.event][props.field];
    switch (typeFilter.type) {
      case FILTER_TYPE.string:
        return (
          <div className="input-text">
            <div className="input-naw-bar-header">{props.field}: </div>
            <input
              type="text"
              className="input-naw-bar"
              value={value ?? ''}
              onChange={(e) => {
                setValue(e.target.value !== '' ? e.target.value : null);
              }}
              onBlur={() => {
                props.onBlur(value !== '' ? value : null);
              }}></input>
          </div>
        );
      case FILTER_TYPE.dropDown:
        return (
          <div className="filter-dropdown-content">
            <Dropdown>
              <Dropdown.Toggle
                variant={COMPONENT_VARIANT.link}
                id={'dropdown-' + props.event + '-' + props.field}
                className={classNames({ 'filter-dropdown': true, active: !!value })}>
                {getFiledName(props.field)}: {value ?? 'not select'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {props.field === 'responsible'
                  ? responsible.map((valueMap, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          disabled={value === valueMap}
                          onClick={() => {
                            setValue(valueMap);
                            props.onBlur(valueMap);
                          }}>
                          {responsibleValue === valueMap ? 'Me: ' + valueMap : valueMap}
                        </Dropdown.Item>
                      );
                    })
                  : typeFilter.value.map((valueMap, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          disabled={value === valueMap}
                          onClick={() => {
                            setValue(valueMap);
                            props.onBlur(valueMap);
                          }}>
                          {valueMap}
                        </Dropdown.Item>
                      );
                    })}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant={COMPONENT_VARIANT.link}
              className={classNames({ 'filter-clear-button': true, 'active-clear': !!value })}
              disabled={!value}
              onClick={() => {
                setValue(null);
                props.onBlur(null);
              }}>
              X
            </Button>
          </div>
        );
      case FILTER_TYPE.selectElement:
        getSelectedElement(typeFilter.requestElement);
        if (!props.isModal)
          return (
            <div
              className={classNames({
                'select-input': true,
                'selected-element': value && valueSelect
              })}>
              <ModalChose
                name={
                  getFiledName(props.field) +
                  ': ' +
                  (value && valueSelect
                    ? FIELD_INFO_CARD[typeFilter.event](valueSelect, FIELD_TYPE_CARD.title)
                    : 'not selected')
                }
                buttonVariant={COMPONENT_VARIANT.link}
                event={typeFilter.event}
                request={typeFilter.request}
                setValue={(value) => {
                  setValueSelect(value);
                  setValue(value.id);
                  props.onBlur(value.id);
                }}
                deleteValue={() => {
                  setValue(null);
                }}
                isSingleSelect
              />
              <Button
                variant={COMPONENT_VARIANT.link}
                className={classNames({ 'filter-clear-button': true, 'active-clear': !!value })}
                disabled={!value || !valueSelect}
                onClick={() => {
                  setValueSelect(null);
                  setValue(null);
                  props.onBlur(null);
                }}>
                X
              </Button>
            </div>
          );
    }
  };
  return <div className="input-naw-bar-content">{getInputBlock()}</div>;
};

export default InputFilter;
