import React, { useEffect, useState } from 'react';
import PaginationTable from '../Pagination';
import Cards from '../Cards';
import NavBar from '../NavBar';
import { FILTER_FIELD, Q_PARAM_SEARCH } from '../../constants/searchElement';
import {
  BASE_ITEMS_PAGE,
  COMPONENT_VARIANT,
  STORAGE_BASE_SEARCH_PARAMS
} from '../../constants/const';
import { useSearchParams } from 'react-router-dom';

import './style.scss';
import { saveSearchParams } from '../../services/storage';
import Loader from '../Loader';

const PaginationPage = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [, setSearchParams] = useSearchParams('');
  const queryPage = queryParams.get(Q_PARAM_SEARCH.page);
  const [items, setItems] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [pageSearch, setPageSearch] = useState(queryParams.get(Q_PARAM_SEARCH.search));
  const [activePage, setActivePage] = useState(queryPage !== null ? parseInt(queryPage) : 1);
  const [maxCard, setMaxCards] = useState(0);
  const [pageElementCount, setPageElementCount] = useState(BASE_ITEMS_PAGE);

  const [activeFilter, setActiveFilter] = useState(FILTER_FIELD[props.event]);
  const [activeFilterBoolean, setActiveFilterBoolean] = useState(null);
  const [activeSort, setActiveSort] = useState(null);

  const setFilerField = (field, value) => {
    const newFiler = { ...activeFilter };
    newFiler[field] = value;
    setActiveFilter(newFiler);
  };
  const clearSearchParams = () => {
    setActiveFilter(FILTER_FIELD[props.event]);
    setActiveFilterBoolean(null);
    setActiveSort(null);
    setPageSearch('');
    saveSearchParams(props.event, 1, '', '', FILTER_FIELD[props.event], null);
    getData(1, null, true);
  };
  const setSortField = (field) => {
    setActiveSort(field);
  };

  const getData = (page, searchValue = null, clearSearchParams = false) => {
    setIsLoad(true);
    setActivePage(page);
    const searchRequest = searchValue ?? pageSearch;
    if (searchValue) setPageSearch(searchValue);
    if (clearSearchParams) {
      props.request(page, pageElementCount, '', FILTER_FIELD[props.event], '', null).then((res) => {
        setItems(res.results);
        setMaxCards(res.count);
        setIsLoad(false);
      });
    } else {
      props
        .request(
          page,
          pageElementCount,
          searchRequest,
          activeFilter,
          activeSort,
          activeFilterBoolean
        )
        .then((res) => {
          setItems(res.results);
          setMaxCards(res.count);
          setIsLoad(false);
        });
      setSearchParams({ page: page });
      saveSearchParams(
        props.event,
        page,
        searchRequest,
        activeSort,
        activeFilter,
        activeFilterBoolean
      );
    }
  };

  useEffect(() => {
    if (items === null) {
      const storagePath = STORAGE_BASE_SEARCH_PARAMS + props.event;
      let params = localStorage.getItem(storagePath);
      if (params) {
        params = JSON.parse(params);
        if (params.page) setActivePage(params.page);
        if (params.searchData) setPageSearch(params.searchData);
        if (params.sort) setActiveSort(params.sort);
        if (params.filter) setActiveFilter(params.filter);
        if (params.filterBoolean) setActiveFilterBoolean(params.filterBoolean);
        props
          .request(
            params.page,
            BASE_ITEMS_PAGE,
            params.searchData,
            params.filter,
            params.sort,
            params.filterBoolean
          )
          .then((res) => {
            setItems(res.results);
            setMaxCards(res.count);
            setIsLoad(false);
          });
        setSearchParams({ page: params.page });
      } else getData(1);
    }
  }, []);

  return (
    <Loader loading={items === null} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <div className="pagination-page">
        <div className="navbar-info">
          <NavBar
            name={props.name}
            event={props.event}
            setFilerField={setFilerField}
            setSortField={setSortField}
            setFilterBoolean={setActiveFilterBoolean}
            setPageSearch={setPageSearch}
            searchPage={pageSearch}
            activeFilter={activeFilter}
            activeSort={activeSort}
            activeFilterBoolean={activeFilterBoolean}
            clearSearchParams={clearSearchParams}
            getData={getData}
            disabledCreate={props.disabledCreate}
          />
        </div>
        <div className="pagination-context">
          <Loader loading={isLoad} variant={COMPONENT_VARIANT.dark} withMargin={100}>
            <Cards
              items={items}
              event={props.event}
              isImage={props.isImage}
              isDetail={props.isDetail}
              isImageFill={props.isImageFill}
            />
          </Loader>
          <div className="pagination">
            <PaginationTable
              itemPage={pageElementCount}
              setItemPage={setPageElementCount}
              activePage={activePage}
              setActivePage={setActivePage}
              allItemCount={maxCard}
              request={getData}
            />
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default PaginationPage;
