import React from 'react';
import './style.scss';
import InputFilter from './InputFilter';
import { FILTER_FIELD } from '../../../../constants/searchElement';

const FilterNawBar = (props) => {
  const filterField = FILTER_FIELD[props.event];
  return (
    filterField && (
      <div className="filter">
        <div className="filter-content">
          {Object.keys(filterField).map((field, index) => {
            return (
              <div key={index} className="filter-input">
                <InputFilter
                  field={field}
                  activeFilter={props.activeValue[field]}
                  event={props.event}
                  onBlur={(value) => {
                    props.setValue(field, value);
                  }}
                  isModal={props.isModal}
                />
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default FilterNawBar;
