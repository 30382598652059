const Rental = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1923 0.758477C12.0423 0.315977 11.6223 0.000976562 11.1273 0.000976562H2.87732C2.38232 0.000976562 1.96982 0.315977 1.81232 0.758477L0.252319 5.25098V11.251C0.252319 11.6635 0.589819 12.001 1.00232 12.001H1.75232C2.16482 12.001 2.50232 11.6635 2.50232 11.251V10.501H11.5023V11.251C11.5023 11.6635 11.8398 12.001 12.2523 12.001H13.0023C13.4148 12.001 13.7523 11.6635 13.7523 11.251V5.25098L12.1923 0.758477ZM3.13982 1.50098H10.8573L11.6673 3.83348H2.32982L3.13982 1.50098ZM12.2523 9.00098H1.75232V5.25098H12.2523V9.00098Z"
        fill="#0D6EFD"
      />
      <path
        d="M3.62732 8.25098C4.24864 8.25098 4.75232 7.7473 4.75232 7.12598C4.75232 6.50466 4.24864 6.00098 3.62732 6.00098C3.006 6.00098 2.50232 6.50466 2.50232 7.12598C2.50232 7.7473 3.006 8.25098 3.62732 8.25098Z"
        fill="#0D6EFD"
      />
      <path
        d="M10.3773 8.25098C10.9986 8.25098 11.5023 7.7473 11.5023 7.12598C11.5023 6.50466 10.9986 6.00098 10.3773 6.00098C9.756 6.00098 9.25232 6.50466 9.25232 7.12598C9.25232 7.7473 9.756 8.25098 10.3773 8.25098Z"
        fill="#0D6EFD"
      />
    </svg>
  );
};

export default Rental;
