import React from 'react';
import { EVENT_COUNTRY } from '../../../../constants/const';
import { Api } from '../../../../services/Api';
import WeatherState from '../../../WeatherState';

const WeatherStateTabs = () => {
  return (
    <div className="tabs-control">
      <WeatherState
        name={'Country'}
        event={EVENT_COUNTRY}
        request={Api.getCountryPage}
        isDetail
      ></WeatherState>
    </div>
  );
};

export default WeatherStateTabs;
