import React, { useEffect } from 'react';
import TaxiTabs from '../Components/DetailPage/Tab/TaxisTabs';
import { useNavi } from '../services/hooks/useNavi';

const TaxisPage = () => {
  const { clearNavigate } = useNavi()
  useEffect(() => {
    clearNavigate()
  }, []);

  return (
    <div className={'main-page'}>
          <TaxiTabs />
    </div>
  );
};

export default TaxisPage;
