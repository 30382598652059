import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { LOADER_ANIMATED_VARIANT } from '../../constants/const';
import { useSelector } from 'react-redux';
import './style.scss';

const Loader = (props) => {
  const app = useSelector((state) => state.app);
  const getCheckLoader = () => {
    if (props.isAuthorization) return props.loading;
    else return props.loading || !app.language || !app.responsible;
  };
  return getCheckLoader() ? (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: props.withMargin + 'px'
      }}>
      <Spinner
        size={props.small && 'sm'}
        animation={props.animationType ?? LOADER_ANIMATED_VARIANT.border}
        variant={props.variant}
        className={
          props.className ? props.className + ' spinner-loader' : 'spinner-loader'
        }></Spinner>
    </div>
  ) : (
    props.children
  );
};

export default Loader;
