import React, { useEffect } from 'react';
import { useNavi } from '../services/hooks/useNavi';
import TipsTabs from '../Components/DetailPage/Tab/TipsTabs';

const TipsPage = () => {
  const { clearNavigate } = useNavi();
  useEffect(() => {
    clearNavigate();
  }, []);

  return (
    <div className={'main-page'}>
      <TipsTabs />
    </div>
  );
};

export default TipsPage;
