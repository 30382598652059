import React from 'react';
import { useParams } from 'react-router-dom';
import PublicTransportDetail from '../../Components/DetailPage/Detail/PublicTransportDetail';

const DetailPublicTransportPage = () => {
  const { id } = useParams();

  return <PublicTransportDetail detailId={id} />;
};

export default DetailPublicTransportPage;
