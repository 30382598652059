import React from 'react';
import { useParams } from 'react-router-dom';
import AirportSelectCountry from '../../Components/DetailPage/DetailTable/AirportSelectCountry';

const SelectCountryInAirportPage = () => {
  const { id } = useParams();
  return <AirportSelectCountry id={id} />;
};

export default SelectCountryInAirportPage;
