import React, { useEffect } from 'react';
import PushEventsTabs from '../Components/DetailPage/Tab/PushEventsTabs';
import { useNavi } from '../services/hooks/useNavi';

const PushEventsPage = () => {
  const { clearNavigate } = useNavi();
  useEffect(() => {
    clearNavigate();
  }, []);

  return (
    <div className={'main-page'}>
      <PushEventsTabs />
    </div>
  );
};

export default PushEventsPage;
