import React from 'react';
import { Alert } from 'react-bootstrap';
import './style.scss';

const ResponseAlert = (props) => {
  const openNewTab = (html) => {

    let child = window.open("about:blank","myChild");
    child.document.write(html);
  }

  if(!props.response) return null
  return props.response?.id ? ( <Alert variant={'success'}>
    Success!
  </Alert>): (
    <Alert variant={'danger'}>
      {props.response?.message}
      <br/>
      {props.response?.response?.status === 500 ?
        <div style={{
          textDecoration: 'underline',
          cursor:'pointer'
        }}
             onClick={()=>openNewTab(props.response?.response?.data)}>Click for full log</div>
        :
        JSON.stringify(props.response?.response.data)}

    </Alert>
  );
};

export default ResponseAlert;
