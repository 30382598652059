import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { DESCRIPTION_PAGE, EVENT_AIRLINES } from '../../../../constants/const';
import { Api } from '../../../../services/Api';

const AirlinesTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={DESCRIPTION_PAGE.airlines}
        event={EVENT_AIRLINES}
        request={Api.getAirlinesPage}
        isImage
        isDetail
        isImageFill
        disabledCreate
      />
    </div>
  );
};

export default AirlinesTabs;
