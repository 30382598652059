import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Create from '../../assets/image/Create.svg';
import './style.scss';
import { useNavi } from '../../services/hooks/useNavi';
import SortFilterNawBar from './SortFilterNawBar';
const NavBar = (props) => {
  const { pushRoute } = useNavi();

  return (
    <Navbar expand="lg" className="navbar-component">
      <Container className="navbar-container" fluid>
        <div className={'title-wrapper'}>
          <Navbar.Text className="header">
            <span>{props.name}</span>
          </Navbar.Text>
          {!props.isDisabledCreate && (
            <button
              className="add-button"
              onClick={() => {
                pushRoute('/' + props.event + '/new', 'new', 'new');
              }}>
              <img src={Create} className="add-button-image" alt={'add'}></img>
            </button>
          )}
        </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" style={{ display: 'flex' }}></Navbar.Collapse>
        <SortFilterNawBar
          name={props.name}
          event={props.event}
          setFilerField={props.setFilerField}
          setSortField={props.setSortField}
          setFilterBoolean={props.setFilterBoolean}
          setPageSearch={props.setPageSearch}
          searchPage={props.searchPage}
          activeFilter={props.activeFilter}
          activeSort={props.activeSort}
          activeFilterBoolean={props.activeFilterBoolean}
          clearSearchParams={props.clearSearchParams}
          getData={props.getData}
        />
      </Container>
    </Navbar>
  );
};

export default NavBar;
