import React from 'react';
import Marker from '../../../assets/image/MarkerGoogleMap.svg';
import './style.scss'

const GoogleMapsMarker = ({ text }) => {
  return (
    <div className="pin">
      <img src={Marker} className="pin-marker" alt={'pin'}/>
      <p className="pin-text">{text}</p>
    </div>
  );
};

export default GoogleMapsMarker;
