import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import './style.scss';
import { Button, Modal } from 'react-bootstrap';
import GoogleMapsMarker from './GoogleMapsMarker';
import { COMPONENT_VARIANT, GOOGLE_MAPS_KEY } from '../../constants/const';

const GoogleMaps = (props) => {
  const [showModal, setShowModal] = useState(false);
  const defaultCenter = {
    lat: parseFloat(props.lat ?? 0),
    lng: parseFloat(props.lon ?? 0)
  };
  const [location, setLocation] = useState({
    address: props.name,
    lat: parseFloat(props.lat ?? 0),
    lng: parseFloat(props.lon ?? 0)
  });
  return (
    <div className="google-maps">
      <Button
        variant={COMPONENT_VARIANT.success}
        className='google-maps-button'
        onClick={() => {
          setShowModal(true);
        }}>
        Google Maps
      </Button>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Google Maps</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="location">lat: {location.lat}</div>
          <div className="location">lon: {location.lng}</div>
          <div className="google-map-location">
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
              defaultCenter={defaultCenter}
              defaultZoom={15}
              onClick={(e) => {
                setLocation({ address: props.name, lat: e.lat.toFixed(6), lng: e.lng.toFixed(6) });
              }}>
              <GoogleMapsMarker lat={location.lat} lng={location.lng} text={location.address} />
            </GoogleMapReact>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
              props.setLat(location.lat);
              props.setLon(location.lng);
            }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GoogleMaps;
