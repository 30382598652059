import React, { useRef, useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import UnsplashReact, { InsertIntoApplicationUploader } from 'unsplash-react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import UploadImage from '../../assets/image/UploadImage.png';
import './style.scss';
import Upload from '../svg/Upload';
import Unsplash from '../svg/Unsplash';
import classNames from 'classnames';
import { COMPONENT_VARIANT, UNSPLASH_API_KEY } from '../../constants/const';
import { downloadFileURl } from '../../services/Api';

const ImageLoadDetail = (props) => {
  const refLoadImage = useRef();
  const [showModalImage, setShowModalImage] = useState(false);

  const getImageComponent = (styleName) => {
    let imageType = '';
    let src = '';
    if (props.imageDetail) {
      imageType = props.imageDetail.type.substring(
        props.imageDetail.type.length - 3,
        props.imageDetail.type.length
      );
      src = URL.createObjectURL(props.imageDetail);
    } else {
      imageType = props.defaultImage.substring(
        props.defaultImage.length - 3,
        props.defaultImage.length
      );
      src = props.defaultImage;
    }
    if (imageType === 'pdf')
      return (
        <iframe
          src={src}
          className={styleName}
          type="application/pdf"
          onClick={() => {
            if (props.isUrl) return;
            refLoadImage.current.click();
          }}></iframe>
      );
    else
      return (
        <Image
          src={src}
          className={styleName}
          onClick={() => {
            if (props.isUrl) return;
            refLoadImage.current.click();
          }}
          thumbnail
          fluid
        />
      );
  };
  const onClickUnsplash = (response) => {
    if (props.isUrl) {
      props.setImageDetail(response);
    } else {
      downloadFileURl(response).then((res) => {
        const fileType = res.data.type.split('/')[1];
        const fileName = res.config.url.split('/')[3].split('?')[0];
        const fileImage = new File([res.data], fileName + '.' + fileType);
        props.setImageDetail(fileImage);
      });
    }
    setShowModalImage(false);
  };

  const getImageContext = (styleName = 'image') => {
    if (!props.defaultImage && !props.imageDetail)
      return (
        <Image
          className={styleName}
          src={UploadImage}
          onClick={() => {
            if (props.isUrl) return;
            refLoadImage.current.click();
          }}
          fluid
          thumbnail
        />
      );
    else return getImageComponent(styleName);
  };

  return (
    <div
      className={classNames({
        'image-context': true,
        'incorrect-border': props.validate
      })}>
      <div className="image-photo-description">
        <div className="head">{props.header}</div>
        {props.isNecessarily && <div className="necessarily">*</div>}
      </div>
      <div className="button-context">
        {!props.isUrl && (
          <Button
            variant="dark"
            className="button-upload"
            onClick={() => {
              refLoadImage.current.click();
            }}>
            <Upload /> Upload
          </Button>
        )}
        {props.isUnsplash && (
          <Button
            variant="outline-dark"
            className="button-unsplash"
            onClick={() => {
              setShowModalImage(true);
            }}>
            <Unsplash />
            Unsplash
          </Button>
        )}
        {props.isDelete && (
          <Button
            variant={COMPONENT_VARIANT.danger}
            className="delete-button-image"
            onClick={() => {
              props.deleteImage();
            }}>
            X
          </Button>
        )}
        <Modal
          show={showModalImage}
          onHide={() => {
            setShowModalImage(false);
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Unsplash image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="unsplash-api" id="unsplash-image-modal">
              {!props.isUrl && (
                <Tab eventKey="selectedImage" title="Upload image">
                  <div className="tab-selected-image">
                    <Button
                      variant="dark"
                      className="button-upload"
                      disabled={props.isUrl}
                      onClick={() => {
                        refLoadImage.current.click();
                      }}>
                      <Upload />
                      Upload
                    </Button>
                    {getImageContext('modal-image')}
                  </div>
                </Tab>
              )}
              <Tab eventKey="unsplash-api" title="Unsplash" className="tab-unsplash-api">
                <UnsplashReact
                  applicationName="unsplash_react_image"
                  accessKey={UNSPLASH_API_KEY}
                  Uploader={InsertIntoApplicationUploader}
                  onFinishedUploading={onClickUnsplash}
                  columns={3}
                  photoRatio={3 / 2}
                  preferredSize={{ width: 1024, height: 768 }}
                />
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModalImage(false);
              }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <input
          ref={refLoadImage}
          type="file"
          name="myImage"
          style={{ display: 'none' }}
          accept="image/jpeg,image/gif,image/png,image/webp,application/pdf,image/x-eps"
          onChange={(event) => {
            console.log(event.target.files[0]);
            if (event.target.files[0]) props.setImageDetail(event.target.files[0]);
          }}
        />
      </div>
      {getImageContext()}
    </div>
  );
};

export default ImageLoadDetail;
